import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination, Badge } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';




function AntibioticosTable({ antibioticos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroCaso, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const antibioticosFiltradas = filtroCaso
        ? antibioticos.filter(antibiotico => antibiotico.nombre_antibiotico.toLowerCase().includes(filtroCaso.toLowerCase()))
        : antibioticos;

    const paginatedTransactions = antibioticosFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_antibioticos')
                .delete()
                .eq('id_antibiotico', id);

            if (error) {
                throw new Error('No se pudo borrar el Caso');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Caso borrada',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar el Caso',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((antibiotico) => (
        <tr key={antibiotico.id_antibiotico}>
            <td>{antibiotico.id_antibiotico}</td>
            <td>{antibiotico.nombre_antibiotico}</td>

            <td>
                <Group justify="flex-start">
                {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_antibioticos &&
                        permisos.catalogos.modulos.cat_antibioticos.acciones &&
                        permisos.catalogos.modulos.cat_antibioticos.acciones.modificar_antibiotico && (
                            <i
                                key={`edit-${antibiotico.id_antibiotico}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(antibiotico);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Caso seleccionada:", antibiotico);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_antibioticos &&
                        permisos.catalogos.modulos.cat_antibioticos.acciones &&
                        permisos.catalogos.modulos.cat_antibioticos.acciones.eliminar_antibiotico && (

                            <i
                                key={`delete-${antibiotico.id_antibiotico}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar esta Caso?')) {
                                        deleteTransaction(antibiotico.id_antibiotico);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre antibiotico',
    ];

    const csvData = antibioticos.map((antibiotico) => [
        antibiotico.id_antibiotico,
        antibiotico.nombre_antibiotico,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre Antibiótico</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(antibioticos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'antibioticos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default AntibioticosTable;