import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function ConclusionForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();

    //valores iniciales
    const [user, setUser] = useState({
        nombre_conclusion: "",
        id_conclusion: "" // Inicializar id_conclusion como una cadena vacía

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_conclusion: transactionData.nombre_conclusion,
                id_conclusion: transactionData.id_conclusion

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateConclusion = async () => {
        try {
            // Primero, obtenemos el nombre_conclusion actual del conclusion que se está editando
            const { data: conclusionActual, error: conclusionError } = await supabase
                .from('cat_conclusiones')
                .select('nombre_conclusion')
                .eq('id_conclusion', user.id_conclusion)
                .single();

            if (conclusionError) {
                throw conclusionError;
            }

            // Verificamos si el nombre_conclusion se ha modificado
            if (user.nombre_conclusion !== conclusionActual.nombre_conclusion) {
                // Si el nombre_conclusion ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_conclusiones')
                    .select('nombre_conclusion')
                    .eq('nombre_conclusion', user.nombre_conclusion)
                    .limit(1);

                if (error) {
                    throw error;
                }

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre del conclusion ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización y sin cerrar el modal
                }
            }

            // Procedemos con la actualización del conclusion
            const { error: updateError } = await supabase
                .from('cat_conclusiones')
                .update(user)
                .eq('id_conclusion', user.id_conclusion);

            if (updateError) {
                throw updateError;
            }

            // Notificación de éxito
            showNotification({
                title: 'Conclusion actualizado',
                message: 'El conclusion se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false); // Cerrar el modal solo si la actualización es exitosa
            getData();

        } catch (error) {
            console.error('Error al actualizar el conclusion:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el conclusion.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }

    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {

                const { data, error } = await supabase
                    .from('cat_conclusiones')
                    .select('nombre_conclusion')
                    .eq('nombre_conclusion', user.nombre_conclusion)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de conclusion ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_conclusiones')
                        .insert({
                            nombre_conclusion: user.nombre_conclusion
                        });
                    showNotification({
                        title: 'Conclusion agregada',
                        message: 'La Conclusion ha sido agregada con éxito',
                        color: 'green',
                    });

                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();

                }


            } else if (formMode === 'edit') {
                await handleUpdateConclusion();
            }
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar Conclusion:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Conclusion.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <TextInput
                            disabled
                            name="id_conclusion"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_conclusion}

                        />

                        <TextInput
                            name="nombre_conclusion"
                            label="Nombre Conclusion"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_conclusion} // Asignar el valor del estado al campo
                            placeholder='Nombre Conclusion'

                        />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Conclusion" : "Editar Conclusion"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ConclusionForm;