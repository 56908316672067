import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/HomePage';

import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from './PublicRoute';

import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';

import CatalogoPacientes from '../pages/CatalogoPacientes';
import CatalogoSintomas from '../pages/CatalogoSintomas';
import CatalogoEnfermeros from '../pages/CatalogoEnfermeros';
import CatalogoUnidades from '../pages/CatalogoUnidades';
import CatalogoCasos from '../pages/CatalogoCasos';
import CatalogoEtapas from '../pages/CatalogoEtapas';
import CatalogoReporteBarras from '../pages/CatalogoReporteBarras';
import CatalogoTiposcultivos from '../pages/CatalogoTiposCultivos';
import CatalogoAntibioticos from '../pages/CatalogoAntibioticos';
import CatalogosTiposav from '../pages/CatalogoTiposav';
import CatalogoSesiones from '../pages/CatalogoSesiones';
import CatalogoSensibilidades from '../pages/CatalogoSensibilidades';
import CatalogoRespuestas from '../pages/CatalogoResultados';
import CatalogoProcedencias from '../pages/CatalogoProcedencias';
import CatalogoSalas from '../pages/CatalogoSalas';
import CatalogoTurnos from '../pages/CatalogosTurnos';
import CatalogoSignos from '../pages/CatalogoSignos';
import CatalogoParches from '../pages/CatalogoParches';
import CatalogoUsos from '../pages/CatalogoUsos';
import CatalogoSecreciones from '../pages/CatalogoSecreciones';
import CatalogoReporteTabla1 from '../pages/CatalogoReporteTabla1';
import CatalogoResultados from '../pages/CatalogoResultados';
import CatalogoReporteTabla2 from '../pages/CatalogoReporteTabla2';
import CatalogoReporteTabla3 from '../pages/CatalogoReporteTabla3';
import CatalogoReporteTabla6 from '../pages/CatalogoReporteTabla6';
import CatalogoMaquinas from '../pages/CatalogoMaquinas';
import CatalogoReporteTabla9 from '../pages/CatalogoReporteTabla9';
import CatalogoReporteTabla12 from '../pages/CatalogoReporteTabla12';
import CatalogoConclusiones from '../pages/CatalogoConclusiones';
import CatalogoReporteNotas from '../pages/CatalogoReporteNotas';


const MainRouter = () => {

    const { user } = useAuth();
    const [permisos, setPermisos] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            const fetchUserPermissions = async () => {
                try {
                    dispatch(ShowLoading());
                    const { data: profile, error } = await supabase
                        .from('profiles')
                        .select('acciones')
                        .eq('id', user.id)
                        .single();

                    if (error) {
                        //console.error("Error fetching user profile:", error.message);
                        throw error;
                    }

                    if (profile) {
                        const userPermissions = profile.acciones || {};
                        setPermisos(userPermissions);
                        //console.log("Permisos:", userPermissions);
                    }

                } catch (error) {
                    //console.error("Error fetching user permissions:", error.message);

                } finally {
                    dispatch(HideLoading());
                }
            };

            fetchUserPermissions();
        } else {
            //console.log("no hay usuario")
            dispatch(HideLoading());

        }
    }, [user, dispatch]);


    return (
        <div>
            <main>
                <Routes>
                    <Route path='/home' element={<ProtectedRoute><HomePage /></ProtectedRoute>} />


                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_pacientes &&
                        permisos.catalogos.modulos.cat_pacientes.activo === true && (
                            <Route path='/cat_pacientes' element={<ProtectedRoute><CatalogoPacientes user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_sintomas_intradialisis &&
                        permisos.catalogos.modulos.cat_sintomas_intradialisis.activo === true && (
                            <Route path='/cat_sintomas' element={<ProtectedRoute><CatalogoSintomas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_enfermeros &&
                        permisos.catalogos.modulos.cat_enfermeros.activo === true && (
                            <Route path='/cat_enfermeros' element={<ProtectedRoute><CatalogoEnfermeros user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_unidades &&
                        permisos.catalogos.modulos.cat_unidades.activo === true && (
                            <Route path='/cat_unidades' element={<ProtectedRoute><CatalogoUnidades user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_etapas_casos &&
                        permisos.catalogos.modulos.cat_etapas_casos.activo === true && (
                            <Route path='/cat_etapas_casos' element={<ProtectedRoute><CatalogoEtapas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tiposcultivos &&
                        permisos.catalogos.modulos.cat_tiposcultivos.activo === true && (
                            <Route path='/cat_tiposcultivos' element={<ProtectedRoute><CatalogoTiposcultivos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_antibioticos &&
                        permisos.catalogos.modulos.cat_antibioticos.activo === true && (
                            <Route path='/cat_antibioticos' element={<ProtectedRoute><CatalogoAntibioticos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_tiposav &&
                        permisos.catalogos.modulos.cat_tiposav.activo === true && (
                            <Route path='/cat_tiposav' element={<ProtectedRoute><CatalogosTiposav user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_turnos &&
                        permisos.catalogos.modulos.cat_turnos.activo === true && (
                            <Route path='/cat_turnos' element={<ProtectedRoute><CatalogoTurnos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_sesiones &&
                        permisos.catalogos.modulos.cat_sesiones.activo === true && (
                            <Route path='/cat_sesiones' element={<ProtectedRoute><CatalogoSesiones user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_sensibilidades &&
                        permisos.catalogos.modulos.cat_sensibilidades.activo === true && (
                            <Route path='/cat_sensibilidades' element={<ProtectedRoute><CatalogoSensibilidades user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_resultados_cultivos &&
                        permisos.catalogos.modulos.cat_resultados_cultivos.activo === true && (
                            <Route path='/cat_resultados_cultivos' element={<ProtectedRoute><CatalogoResultados user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_procedencias &&
                        permisos.catalogos.modulos.cat_procedencias.activo === true && (
                            <Route path='/cat_procedencias' element={<ProtectedRoute><CatalogoProcedencias user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_salas &&
                        permisos.catalogos.modulos.cat_salas.activo === true && (
                            <Route path='/cat_salas' element={<ProtectedRoute><CatalogoSalas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_turnos &&
                        permisos.catalogos.modulos.cat_turnos.activo === true && (
                            <Route path='/cat_turnos' element={<ProtectedRoute><CatalogoTurnos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_signos &&
                        permisos.catalogos.modulos.cat_signos.activo === true && (
                            <Route path='/cat_signos' element={<ProtectedRoute><CatalogoSignos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_parches &&
                        permisos.catalogos.modulos.cat_parches.activo === true && (
                            <Route path='/cat_parches' element={<ProtectedRoute><CatalogoParches user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_usos &&
                        permisos.catalogos.modulos.cat_usos.activo === true && (
                            <Route path='/cat_usos' element={<ProtectedRoute><CatalogoUsos user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_secreciones &&
                        permisos.catalogos.modulos.cat_secreciones.activo === true && (
                            <Route path='/cat_secreciones' element={<ProtectedRoute><CatalogoSecreciones user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_maquinas &&
                        permisos.catalogos.modulos.cat_maquinas.activo === true && (
                            <Route path='/cat_maquinas' element={<ProtectedRoute><CatalogoMaquinas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_conclusiones &&
                        permisos.catalogos.modulos.cat_conclusiones.activo === true && (
                            <Route path='/cat_conclusiones' element={<ProtectedRoute><CatalogoConclusiones user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos && permisos.casos && permisos.casos.activo === true && (
                        <Route
                            path='/casos'
                            element={
                                <ProtectedRoute>
                                    <CatalogoCasos
                                        user={user}
                                        permisos={permisos}
                                        puedeEliminarNota={permisos.notas && permisos.notas.acciones.eliminar_nota}
                                        puedeModificarNota={permisos.notas && permisos.notas.acciones.modificar_nota}
                                        puedeRegistrarNota={permisos.notas && permisos.notas.acciones.registrar_nota}
                                        puedeEliminarTratamiento={permisos.tratamientos && permisos.tratamientos.acciones.eliminar_tratamiento}
                                        puedeModificarTratamiento={permisos.tratamientos && permisos.tratamientos.acciones.modificar_tratamiento}
                                        puedeRegistrarTratamiento={permisos.tratamientos && permisos.tratamientos.acciones.registrar_tratamiento}
                                        puedeEliminarCultivo={permisos.cultivos && permisos.cultivos.acciones.eliminar_cultivo}
                                        puedeModificarCultivo={permisos.cultivos && permisos.cultivos.acciones.modificar_cultivo}
                                        puedeRegistrarCultivo={permisos.cultivos && permisos.cultivos.acciones.registrar_cultivo}


                                    />
                                </ProtectedRoute>
                            }
                        />
                    )}


                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_casos' element={<ProtectedRoute><CatalogoReporteBarras user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla1' element={<ProtectedRoute><CatalogoReporteTabla1 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla2' element={<ProtectedRoute><CatalogoReporteTabla2 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla3' element={<ProtectedRoute><CatalogoReporteTabla3 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla6' element={<ProtectedRoute><CatalogoReporteTabla6 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla9' element={<ProtectedRoute><CatalogoReporteTabla9 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_tabla12' element={<ProtectedRoute><CatalogoReporteTabla12 user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {permisos &&
                        permisos.reportes &&
                        permisos.reportes.activo === true && (
                            <Route path='/reporte_notas' element={<ProtectedRoute><CatalogoReporteNotas user={user} permisos={permisos} /></ProtectedRoute>} />
                        )}

                    {/* Ruta /login, solo mostrar si el usuario no está autenticado */}
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />

                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

/* antes las rutas eran:
<Route path='/cattiposcontratantes' element={<ProtectedRoute>{user ? <CatalogoTiposContratantes /> : <Navigate to="/login" />}</ProtectedRoute>} />
Pero las cambiamos a: 
<Route path='/cattiposcontratantes' element={<ProtectedRoute><CatalogoTiposContratantes /></ProtectedRoute>} />


y antes estaba:
<Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />


Codigo 01 de marzo 2024
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HomePage from '../pages/HomePage';
import CatalogoContratantes from '../pages/CatalogoContratantes';
import CatalogoTiposContratantes from '../pages/CatalogosTiposContratantes';
import CatalogoAseguradoras from '../pages/CatalogoAseguradoras';
import CatalogoDuracionesPagos from '../pages/CatalogoDuracionesPagos';
import Login from "../pages/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import CatalogoTiposSeguros from '../pages/CatalogoTiposSeguros';
import CatalogoPlanes from '../pages/CatalogoPlanes'

const MainRouter = () => {
    const { user } = useAuth();

    return (
        <div>
            <main>
                <Routes>
                    <Route path='/' element={<ProtectedRoute>{user ? <HomePage /> : <Navigate to="/login" />}</ProtectedRoute>} />

                    <Route path='/catcontratantes' element={<ProtectedRoute><CatalogoContratantes /></ProtectedRoute>} />
                    <Route path='/cattiposcontratantes' element={<ProtectedRoute><CatalogoTiposContratantes /></ProtectedRoute>} />
                    <Route path='/cataseguradoras' element={<ProtectedRoute><CatalogoAseguradoras /></ProtectedRoute>} />
                    <Route path='/catduracionpagos' element={<ProtectedRoute><CatalogoDuracionesPagos /></ProtectedRoute>} />
                    <Route path='/cattiposseguros' element={<ProtectedRoute><CatalogoTiposSeguros /></ProtectedRoute>} />
                    <Route path='/catplanes' element={<ProtectedRoute><CatalogoPlanes /></ProtectedRoute>} />

                    Ruta /login, solo mostrar si el usuario no está autenticado
                    <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
                </Routes>
            </main>
        </div>
    );
};

export default MainRouter;

*/

