import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../supabaseClient";

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, error] = useState();


    //const fetchUser anterior y useEffect 27 febrero 2024
    //en el que error: me daba undefined
    const fetchUser = async () => {
        const { data: { user, error } } = await supabase.auth.getUser();
        if (error) {
            console.error("Error al obtener usuario:", error.message);
            throw error;
        }


        //console.log("error:", error)
        //setUser(user);
        //setUser(user);
        //setLoading(false);
        //console.log("Estado del usuario11:", user)

        // Nuevo para poner nombre usuario
        if (user) {
            // Aquí asumimos que el id del usuario es el mismo que el id en la tabla profile
            const { data: profile, error: profileError } = await supabase
                .from('profiles')
                .select('username, avatar_url')
                .eq('id', user.id)
                .single(); // Usamos .single() ya que esperamos un único resultado

            if (profileError) console.error("Error fetching user profile:", profileError.message);

            // Actualizamos el estado del usuario con la información del perfil también
            setUser({ ...user, ...profile });
        }

        setLoading(false);
    };


    useEffect(() => {
        fetchUser();
        console.log("Estado del usuario useeffect:", user)

    }, []); // El arreglo vacío [] indica que este efecto solo se ejecuta una vez (equivalente a componentDidMount)


    const signIn = async ({ email, password }) => {
        try {
            setLoading(true); // Establecer loading a true al iniciar sesión
            const { user, error } = await supabase.auth.signInWithPassword({ email, password });
            if (error) {
                //console.error("Error al iniciar sesión:", error.message);
                throw error;
            }

            // Llamar a fetchUser después de un inicio de sesión exitoso
            fetchUser();
            console.log("Usuario después del login:", user);
        } catch (error) {
            setLoading(false); // por si ingresa mal el correo o contraseña para que intente de nuevo
            //console.error("Error en signIn:", error.message);
            throw error;
        } finally {
            //setLoading(false); // Establecer loading a false después de iniciar sesión, ya no porque en el fetchUser lo hace
        }
    };


    const signOut = async () => {
        try {
            setLoading(true); // Establecer loading a true al cerrar sesión
            const { error } = await supabase.auth.signOut();
            if (error) {
                //console.error("Error al cerrar sesión:", error.message);
                throw error;
            }
            setUser(null);
        } catch (error) {
            //console.error("Error en signOut:", error.message);
            throw error;
        } finally {
            setLoading(false); // Establecer loading a false después de cerrar sesión
        }
    };

 

    const value = {
        user,
        loading,
        signIn,
        signOut,
        fetchUser,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;



/*
Codigo 01 marzo 2024

import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../supabaseClient";

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, error] = useState();


    //const fetchUser anterior y useEffect 27 febrero 2024
    //en el que error: me daba undefined
    const fetchUser = async () => {
        const { data: { user, error } } = await supabase.auth.getUser()
        //if (error) throw error;
        //console.log("error:", error)
        setUser(user);
        console.log("Estado del usuario11:", user)
        setLoading(false);
    };

    
    useEffect(() => {
        console.log("Estado del usuario useeffect:", user)
        fetchUser();
        
    }, []); // El arreglo vacío [] indica que este efecto solo se ejecuta una vez (equivalente a componentDidMount)
    

    const signIn = async ({ email, password }) => {
        try {
            setLoading(true); // Establecer loading a true al iniciar sesión
            const { user, error } = await supabase.auth.signInWithPassword({ email, password });
            if (error) {
                console.error("Error al iniciar sesión:", error.message);
                throw error;
            }

            // Llamar a fetchUser después de un inicio de sesión exitoso
            fetchUser();
            console.log("Usuario después del login:", user);
        } catch (error) {
            console.error("Error en signIn:", error.message);
            throw error;
        } finally {
            setLoading(false); // Establecer loading a false después de iniciar sesión
        }
    };


    const signOut = async () => {
        try {
            setLoading(true); // Establecer loading a true al cerrar sesión
            const { error } = await supabase.auth.signOut();
            if (error) {
                console.error("Error al cerrar sesión:", error.message);
                throw error;
            }
            setUser(null);
        } catch (error) {
            console.error("Error en signOut:", error.message);
            throw error;
        } finally {
            setLoading(false); // Establecer loading a false después de cerrar sesión
        }
    };

    
    //useEffect(() => {
    //    console.log("Usuario actualizado:", user);
    //}, [user]);
    

    const value = {
        user,
        loading,
        signIn,
        signOut,
        fetchUser,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;

*/