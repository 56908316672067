import React, { useState } from 'react';
import { Table, Button, Badge } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { IconDownload } from '@tabler/icons-react';

const Tabla8 = ({ data, filters }) => {
    
    // Función para obtener el total por columna
    const getTotalByColumn = (column) => {
        return data.reduce((acc, item) => acc + (item[column] || 0), 0);
    };

    if (!data || data.length === 0) {
        return (
            <div>
                <Badge color='grey'>Sin información</Badge>
            </div>
        );
    }

    const csvHeaders = [
        'TIPO ACCESO VASCULAR',
        'CLASIFICACION RESULTADO',
        'RESULTADO',
        'TOTAL'
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData = data.map((data) => [
        sanitizeCSV(data.clasificacion_tipoav),
        sanitizeCSV(data.clasificacion_resultado_cultivo),
        sanitizeCSV(data.nombre_resultado_cultivo),
        data.total_cultivos
    ]);

    return (
        <>
        <CsvDownloader
            filename={'Tabla8.csv'}
            separator=","
            wrapColumnChar=""
            datas={csvData}
            columns={csvHeaders}
        >
            <Button
                variant="outline"
                color='green'
                mb={'10px'}
                rightSection={<IconDownload size={20} />}
            >
                Descargar Tabla
            </Button>
        </CsvDownloader>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>TIPO ACCESO VASCULAR</th>
                        <th>CLASIFICACIÓN RESULTADO</th>
                        <th>RESULTADO</th>
                        <th>
                            TOTAL
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={`${item.id_tipoav}-${item.clasificacion_resultado_cultivo}-${item.nombre_resultado_cultivo}-${item.total_cultivos}`}>
                            <td>{item.clasificacion_tipoav}</td>
                            <td>{item.clasificacion_resultado_cultivo}</td>
                            <td>{item.nombre_resultado_cultivo}</td>
                            <td>{item.total_cultivos}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td><strong>Total</strong></td>
                        <td>
                            {getTotalByColumn('total_cultivos')}
                        </td>
                    </tr>
                </tfoot>
            </Table>


        </>
    );
};

export default Tabla8;
