import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";



function SignoForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_signo: "",
        id_signo: "" // Inicializar id_signo como una cadena vacía

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_signo: transactionData.nombre_signo,
                id_signo: transactionData.id_signo

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateSigno = async () => {

        try {
            // Primero, obtenemos el nombre_signo actual del signo que se está editando
            const { data: signoActual, error: signoError } = await supabase
                .from('cat_signos')
                .select('nombre_signo')
                .eq('id_signo', user.id_signo)
                .single();

            if (signoError) {
                throw signoError;
            }

            // Verificamos si el nombre_signo se ha modificado
            if (user.nombre_signo !== signoActual.nombre_signo) {
                // Si el nombre_signo ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_signos')
                    .select('nombre_signo')
                    .eq('nombre_signo', user.nombre_signo)
                    .limit(1);

                if (error) {
                    throw error;
                }

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre del signo ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización y sin cerrar el modal
                }
            }

            // Procedemos con la actualización del signo
            const { error: updateError } = await supabase
                .from('cat_signos')
                .update(user)
                .eq('id_signo', user.id_signo);

            if (updateError) {
                throw updateError;
            }

            // Notificación de éxito
            showNotification({
                title: 'signo actualizado',
                message: 'El signo se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false); // Cerrar el modal solo si la actualización es exitosa
            getData();

        } catch (error) {
            console.error('Error al actualizar el signo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el signo.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {

                const { data, error } = await supabase
                    .from('cat_signos')
                    .select('nombre_signo')
                    .eq('nombre_signo', user.nombre_signo)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre del Signo ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_signos')
                        .insert({
                            nombre_signo: user.nombre_signo,
                        });
                    showNotification({
                        title: 'Signo agregado (a)',
                        message: 'El signo ha sido agregado (a) con éxito',
                        color: 'green',
                    });

                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();

                }


            } else if (formMode === 'edit') {
                await handleUpdateSigno();
            }
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar signo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el Signo.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <TextInput
                            disabled
                            name="id_signo"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_signo}

                        />

                        <TextInput
                            name="nombre_signo"
                            label="Nombre Signo"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_signo} // Asignar el valor del estado al campo
                            placeholder='Nombre Signo'

                        />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Signo" : "Editar Signo"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default SignoForm;