import React, { useState, useEffect, useRef } from 'react';
import { showNotification } from "@mantine/notifications";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';

function BarChartComponent({ casos }) {
  const [data, setData] = useState([]);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (casos && casos.length > 0) {
      processData(casos);
    }
  }, [casos]);

  const processData = (casos) => {
    try {
      const processedData = casos.reduce((acc, caso) => {
        const fecha = caso.fecha_caso_inicio;
        const etapa = caso.cat_etapas_casos.nombre_etapa_caso;

        if (!acc[fecha]) {
          acc[fecha] = {};
        }

        if (!acc[fecha][etapa]) {
          acc[fecha][etapa] = 0;
        }

        acc[fecha][etapa] += 1;

        return acc;
      }, {});

      const data = Object.keys(processedData).map(fecha => ({
        fecha,
        ...processedData[fecha]
      }));

      setData(data);
    } catch (error) {
      console.error('Error al procesar los datos:', error.message);
      showNotification({
        title: 'Error',
        message: 'Hubo un error al procesar los datos.',
        color: 'red',
      });
    }
  };

  const handleFullScreen = () => {
    const elem = chartContainerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const handleFullScreenChange = () => {
    if (document.fullscreenElement === chartContainerRef.current) {
      chartContainerRef.current.classList.add('fullscreen-background');
    } else {
      chartContainerRef.current.classList.remove('fullscreen-background');
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '340px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="fecha"
            tickFormatter={(tick) => {
              const date = new Date(tick);
              date.setDate(date.getDate() + 1);
              return date.toLocaleDateString('es-ES');
            }}
          />
          <YAxis tickFormatter={(tick) => Number.isInteger(tick) ? tick : ''} />
          <Tooltip />
          <Legend />
          {['EN PROCESO', 'CONCLUÍDO'].map(etapa => (
            <Bar
              key={etapa}
              dataKey={etapa}
              fill={etapa === 'CONCLUÍDO' ? '#009400' : '#D5133A'}
            >
              <LabelList
                dataKey={etapa}
                position="insideTop"
                fill="white"
                fontSize={14}
                formatter={(value) => value > 0 ? value : ''}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarChartComponent;