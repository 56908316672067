import React, { useEffect, useState } from 'react';
import { supabase } from "../supabaseClient";

import { Group, Button, Loader, Modal, Card, TextInput, Accordion } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import moment from "moment";
import NotasFiltro from '../components/NotasFiltros';
import TablaNotas from '../components/TablaNotas';


function CatalogoReporteNotas({ user, permisos }) {
    const [notas, setNotas] = useState([])
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual,
        fechaFinal: new Date(),
        nombre_unidad: "",
        nombre_paciente: "",

        nombre_etapa_caso: "",
        nombre_turno: "",
        nombre_sala: "",

        id_nota: "",
        nombre_manejo: "",
        nombre_evolucion: "",
        nombre_cambio: "",
        clasificacion_uso: "",
        nombre_procedencia:"",

    });

    // Para Tabla
    const [loading, setLoading] = useState(false);



    const fetchNotas = async () => {
        try {
            let supabaseQuery = supabase
                .from('notas')
                .select(`
                id_nota,
                fecha_nota,
                nombre_nota,
                usuario_creador,
                id_manejo,
                cat_manejos!inner(
                    id_manejo,
                    nombre_manejo
                ),
                id_evolucion,
                cat_evoluciones!inner(
                    id_evolucion,
                    nombre_evolucion
                ),
                id_cambio,
                cat_cambios!inner(
                    id_cambio,
                    nombre_cambio
                ),
                id_caso,
                casos!inner(
                    id_caso,
                    numero_caso,
                    id_unidad,
                    cat_unidades!inner(
                        id_unidad,
                        nombre_unidad
                    ),
                    id_etapa_caso,
                    cat_etapas_casos!inner (
                        id_etapa_caso,
                        nombre_etapa_caso
                    ),
                    id_paciente,
                    cat_pacientes!inner (
                        id_paciente,
                        nombre_paciente
                    ),
                    id_tipoav,
                    cat_tiposav!inner(
                        id_tipoav,
                        nombre_tipoav,
                        clasificacion_tipoav
                    ),
                    id_procedencia,
                    cat_procedencias!inner(
                        id_procedencia,
                        nombre_procedencia
                    ),
                    id_sala,
                    cat_salas!inner(
                        id_sala,
                        nombre_sala    
                    ),
                    id_turno,
                    cat_turnos!inner(
                        id_turno,
                        nombre_turno
                    ),
                    id_uso,
                    cat_usos!inner(
                        id_uso,
                        nombre_uso,
                        clasificacion_uso
                    )
                )
                
            `)
                .order('fecha_nota', { ascending: true });

            if (filters.nombre_unidad) {
                supabaseQuery = supabaseQuery.eq('casos.cat_unidades.nombre_unidad', filters.nombre_unidad);
            }

            if (filters.nombre_paciente) {
                supabaseQuery = supabaseQuery.eq('casos.cat_pacientes.nombre_paciente', filters.nombre_paciente);
            }

            if (filters.nombre_etapa_caso) {
                supabaseQuery = supabaseQuery.eq('casos.cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
            }

            if (filters.nombre_turno) {
                supabaseQuery = supabaseQuery.eq('casos.cat_turnos.nombre_turno', filters.nombre_turno);
            }

            if (filters.nombre_sala) {
                supabaseQuery = supabaseQuery.eq('casos.cat_salas.nombre_sala', filters.nombre_sala);
            }

            if (filters.clasificacion_tipoav) {
                supabaseQuery = supabaseQuery.eq('casos.cat_tiposav.clasificacion_tipoav', filters.clasificacion_tipoav);
            }

            //procedencia
            if (filters.nombre_procedencia) {
                supabaseQuery = supabaseQuery.eq('casos.cat_procedencias.nombre_procedencia', filters.nombre_procedencia);
            }

            if (filters.fechaInicial) {
                const fechaInicialFormatted = moment(filters.fechaInicial).format('YYYY-MM-DD');
                supabaseQuery = supabaseQuery.gte('fecha_nota', fechaInicialFormatted);
            }

            if (filters.fechaFinal) {
                const fechaFinalFormatted = moment(filters.fechaFinal).format('YYYY-MM-DD');

                supabaseQuery = supabaseQuery.lte('fecha_nota', fechaFinalFormatted);
            }

            if (filters.nombre_manejo) {
                supabaseQuery = supabaseQuery.eq('cat_manejos.nombre_manejo', filters.nombre_manejo);
            }

            if (filters.nombre_evolucion) {
                supabaseQuery = supabaseQuery.eq('cat_evoluciones.nombre_evolucion', filters.nombre_evolucion);
            }

            if (filters.nombre_cambio) {
                supabaseQuery = supabaseQuery.eq('cat_cambios.nombre_cambio', filters.nombre_cambio);
            }

            // filtro clasificacion_uso
            if (filters.clasificacion_uso) {
                supabaseQuery = supabaseQuery.eq('casos.cat_usos.clasificacion_uso', filters.clasificacion_uso);
            }

            // filtro id_nota
            if (filters.id_nota) {
                supabaseQuery = supabaseQuery.eq('id_nota', filters.id_nota);
            }

            // Ejecutar la consulta y obtener los datos
            const { data: notas, error } = await supabaseQuery;

            console.log("Notas obtenidas:", notas); // Agregar este console.log para verificar los contratantes obtenidos


            if (error) {
                throw error;
            }

            return notas;
        } catch (error) {
            console.error('Error al obtener notas:', error.message);
            throw error;
        }

    };


    const getData = async () => {
        try {
            dispatch(ShowLoading());
            //console.log('Estado de carga activado');
            const cargarInformacion = await fetchNotas();
            setNotas(cargarInformacion);
            dispatch(HideLoading());

        }
        catch (error) {
            //console.log(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            })
            dispatch(HideLoading());
        }
    };


    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <NotasFiltro
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                        />
                        <Group justify='flex-start'>
                            <Button
                                variant="gradient"
                                gradient={{ from: 'blue', to: '#00497A', deg: 90 }}
                                leftSection={<IconSearch size={20} />}
                                onClick={getData}
                            >
                                Generar Tabla
                            </Button>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <div
                style={{
                    padding: '10px',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    gap: '20px',
                    flexWrap: 'wrap',
                    width: '100%'
                }}
            >
                <Accordion
                    defaultValue="tabla3"
                    style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
                >
                    <Accordion.Item value="tabla3">
                        <Accordion.Control className="AccordionControl">
                            Cultivos con Resultado
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel">
                            <Card withBorder style={{ background: 'white', padding: '10px' }}>
                                <div style={{ overflowX: "auto", textAlign: "left" }}>
                                    {loading ? (
                                        <>
                                            <Loader size={70} type="dots" />
                                            <p style={{ marginTop: "5px", fontSize: "16px", color: "#555" }}>Obteniendo datos...</p>
                                        </>
                                    ) : (
                                        <TablaNotas data={notas} filters={filters} />
                                    )}
                                </div>
                            </Card>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div >
    );

}


export default CatalogoReporteNotas;