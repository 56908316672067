import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';



function SignosTable({ signos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroSigno, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const signosFiltradas = filtroSigno
        ? signos.filter(signo => signo.nombre_signo.toLowerCase().includes(filtroSigno.toLowerCase()))
        : signos;

    const paginatedTransactions = signosFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_signos')
                .delete()
                .eq('id_signo', id);

            if (error) {
                throw new Error('No se pudo borrar el signo');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Signo borrado',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Signo',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((signo) => (
        <tr key={signo.id_signo}>
            <td>{signo.id_signo}</td>
            <td>{signo.nombre_signo}</td>
            <td>
                <Group justify="flex-start">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_signos &&
                        permisos.catalogos.modulos.cat_signos.acciones &&
                        permisos.catalogos.modulos.cat_signos.acciones.modificar_signo && (
                            <i
                                key={`edit-${signo.id_signo}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(signo);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Signo seleccionada:", signo);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_signos &&
                        permisos.catalogos.modulos.cat_signos.acciones &&
                        permisos.catalogos.modulos.cat_signos.acciones.eliminar_signo && (

                            <i
                                key={`delete-${signo.id_signo}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar esta Signo?')) {
                                        deleteTransaction(signo.id_signo);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre signo',
    ];

    const csvData = signos.map((signo) => [
        signo.id_signo,
        signo.nombre_signo,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre signo</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(signos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'signos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default SignosTable;