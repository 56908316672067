import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";



function MaquinasForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();
    //const user = JSON.parse(localStorage.getItem("user"));

    //valores iniciales
    const [user, setUser] = useState({
        nombre_maquina: "",
        id_maquina: "" // Inicializar id_maquina como una cadena vacía

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_maquina: transactionData.nombre_maquina,
                id_maquina: transactionData.id_maquina

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdate = async () => {

        try {
            // Primero, obtenemos el nombre_maquina actual del maquina que se está editando
            const { data: maquinaActual, error: maquinaError } = await supabase
                .from('cat_maquinas')
                .select('nombre_maquina')
                .eq('id_maquina', user.id_maquina)
                .single();

            if (maquinaError) {
                throw maquinaError;
            }

            // Verificamos si el nombre_maquina se ha modificado
            if (user.nombre_maquina !== maquinaActual.nombre_maquina) {
                // Si el nombre_maquina ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_maquinas')
                    .select('nombre_maquina')
                    .eq('nombre_maquina', user.nombre_maquina)
                    .limit(1);

                if (error) {
                    throw error;
                }

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de la maquina ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización y sin cerrar el modal
                }
            }

            // Procedemos con la actualización del maquina
            const { error: updateError } = await supabase
                .from('cat_maquinas')
                .update(user)
                .eq('id_maquina', user.id_maquina);

            if (updateError) {
                throw updateError;
            }

            // Notificación de éxito
            showNotification({
                title: 'maquina actualizado',
                message: 'La maquina se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false); // Cerrar el modal solo si la actualización es exitosa
            getData();

        } catch (error) {
            console.error('Error al actualizar la maquina:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar la maquina.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {

                const { data, error } = await supabase
                    .from('cat_maquinas')
                    .select('nombre_maquina')
                    .eq('nombre_maquina', user.nombre_maquina)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de la maquina ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_maquinas')
                        .insert({
                            nombre_maquina: user.nombre_maquina,
                        });
                    showNotification({
                        title: 'maquina agregado (a)',
                        message: 'La maquina ha sido agregada con éxito',
                        color: 'green',
                    });

                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();

                }


            } else if (formMode === 'edit') {
                await handleUpdate();
            }
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar maquina:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el maquina.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                            <TextInput
                                disabled
                                name="id_maquina"
                                label="ID"
                                onChange={handleChange}
                                style={{ width: '300px' }}
                                value={user.id_maquina}

                            />

                            <TextInput
                                name="nombre_maquina"
                                label="Nombre Maquina"
                                style={{ width: '300px' }}
                                required
                                onChange={handleChange}
                                value={user.nombre_maquina} // Asignar el valor del estado al campo
                                placeholder='Nombre Maquina'
                               
                            />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Maquina" : "Editar Maquina"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default MaquinasForm;