import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination, Badge } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { IconCheck, IconSquareCheckFilled, IconSettings } from '@tabler/icons-react';
import moment from 'moment';




function CasosTable({ casos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroCaso, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const casosFiltradas = filtroCaso
        ? casos.filter(caso => caso.numero_caso.toLowerCase().includes(filtroCaso.toLowerCase()))
        : casos;

    const paginatedTransactions = casosFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            //console.log("Iniciando eliminación del caso con ID:", id);
            dispatch(ShowLoading());
    
            // Verifica si el caso tiene cultivos, tratamientos o notas relacionadas
            const { data: cultivos, error: errorCultivos } = await supabase
                .from('cultivos')
                .select('id_cultivo')
                .eq('id_caso', id);
    
            const { data: tratamientos, error: errorTratamientos } = await supabase
                .from('tratamientos')
                .select('id_tratamiento')
                .eq('id_caso', id);
    
            const { data: notas, error: errorNotas } = await supabase
                .from('notas')
                .select('id_nota')
                .eq('id_caso', id);
    
            // Verifica si hubo errores en las consultas
            if (errorCultivos || errorTratamientos || errorNotas) {
                throw new Error('Error al verificar dependencias');
            }
    
            //console.log("Cultivos:", cultivos, "Tratamientos:", tratamientos, "Notas:", notas);
    
            // Verifica las longitudes
            if (cultivos.length > 0 || tratamientos.length > 0 || notas.length > 0) {
                let mensaje = 'No se puede eliminar el Caso porque tiene asociado: ';
                if (cultivos.length > 0) mensaje += `\n- ${cultivos.length} cultivo(s)`;
                if (tratamientos.length > 0) mensaje += `\n- ${tratamientos.length} tratamiento(s)`;
                if (notas.length > 0) mensaje += `\n- ${notas.length} nota(s)`;
    
                showNotification({
                    title: 'Error',
                    message: mensaje,
                    color: 'red'
                });
                return; // Se detiene la ejecución si hay dependencias
            }
    
            // Proceder a eliminar el caso
            //console.log("Eliminando el caso con ID:", id);
            const { error } = await supabase
                .from('cat_casos')
                .delete()
                .eq('id_caso', id);
    
            // Maneja el error de eliminación
            if (error) {
                if (error.code === '23503') { // Código de error de clave foránea
                    throw new Error('No se puede eliminar el caso porque aún tiene elementos asociados.');
                } else {
                    throw new Error('No se pudo borrar el Caso: ' + error.message);
                }
            }
    
            // Mensaje de éxito si la eliminación fue exitosa
            showNotification({
                title: 'Caso borrado',
                color: 'green'
            });
    
            getData(); // Refresca los datos
        } catch (error) {
            console.error('Error al eliminar el caso:', error.message);
            showNotification({
                title: 'Error',
                message: error.message || 'No se pudo borrar el Caso',
                color: 'red'
            });
        } finally {
            dispatch(HideLoading());
        }
    };
    






    const getRows = paginatedTransactions.map((caso) => (
        <tr key={caso.id_caso}>
            <td>{caso.id_caso}</td>
            <td>{caso.numero_caso}</td>
            <td>{caso.cat_unidades.nombre_unidad}</td>

            <td>
                {(() => {
                    switch (caso.cat_etapas_casos.nombre_etapa_caso) {
                        case 'EN PROCESO':
                            return <IconSettings size={24} color="orange" />;
                        case 'CONCLUÍDO':
                            return <IconSquareCheckFilled size={24} color="green" />;
                        // Add more cases here for future statuses
                        // case 'Nuevo Status':
                        //     return <IconNuevo size={24} color="newColor" />;
                        default:
                            return <IconCheck size={24} color="gray" />;
                    }
                })()}
            </td>
            <td>
                {caso.fecha_caso_inicio
                    ? moment(caso.fecha_caso_inicio).format('DD/MM/YYYY')
                    : '-'}
            </td>
            <td>
                {caso.fecha_caso_fin
                    ? moment(caso.fecha_caso_fin).format('DD/MM/YYYY')
                    : '-'}
            </td>
            <td>{caso.cat_pacientes.nombre_paciente}</td>
            <td>{caso.cat_enfermeros.nombre_enfermero}</td>


            <td>
                <Group justify="flex-start">
                    {permisos &&
                        permisos.casos &&
                        permisos.casos.acciones &&
                        permisos.casos.acciones.modificar_caso && (
                            <i
                                key={`edit-${caso.id_caso}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(caso);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Caso seleccionada:", caso);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.casos &&
                        permisos.casos.acciones &&
                        permisos.casos.acciones.eliminar_caso && (

                            <i
                                key={`delete-${caso.id_caso}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar esta Caso?')) {
                                        deleteTransaction(caso.id_caso);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre caso',
    ];

    const csvData = casos.map((caso) => [
        caso.id_caso,
        caso.numero_caso,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Número caso</th>
                        <th>Unidad</th>

                        <th>Etapa</th>
                        <th>Fecha inicio</th>
                        <th>Fecha fin</th>



                        <th>Paciente</th>
                        <th>Enfermero</th>


                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(casos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'casos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default CasosTable;