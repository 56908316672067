import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function TurnosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();

    //valores iniciales
    const [user, setUser] = useState({
        nombre_turno: "",
        id_turno: "" // Inicializar id_turno como una cadena vacía

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_turno: transactionData.nombre_turno,
                id_turno: transactionData.id_turno

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateTurno = async () => {
        try {
            // Primero, obtenemos el nombre_turno actual del turno que se está editando
            const { data: turnoActual, error: turnoError } = await supabase
                .from('cat_turnos')
                .select('nombre_turno')
                .eq('id_turno', user.id_turno)
                .single();

            if (turnoError) {
                throw turnoError;
            }

            // Verificamos si el nombre_turno se ha modificado
            if (user.nombre_turno !== turnoActual.nombre_turno) {
                // Si el nombre_turno ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_turnos')
                    .select('nombre_turno')
                    .eq('nombre_turno', user.nombre_turno)
                    .limit(1);

                if (error) {
                    throw error;
                }

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre del turno ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización y sin cerrar el modal
                }
            }

            // Procedemos con la actualización del turno
            const { error: updateError } = await supabase
                .from('cat_turnos')
                .update(user)
                .eq('id_turno', user.id_turno);

            if (updateError) {
                throw updateError;
            }

            // Notificación de éxito
            showNotification({
                title: 'Turno actualizado',
                message: 'El turno se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false); // Cerrar el modal solo si la actualización es exitosa
            getData();

        } catch (error) {
            console.error('Error al actualizar el turno:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el turno.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }

    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {

                const { data, error } = await supabase
                    .from('cat_turnos')
                    .select('nombre_turno')
                    .eq('nombre_turno', user.nombre_turno)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de turno ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_turnos')
                        .insert({
                            nombre_turno: user.nombre_turno
                        });
                    showNotification({
                        title: 'Turno agregada',
                        message: 'La Turno ha sido agregada con éxito',
                        color: 'green',
                    });

                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();

                }


            } else if (formMode === 'edit') {
                await handleUpdateTurno();
            }
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar Turno:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Turno.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <TextInput
                            disabled
                            name="id_turno"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_turno}

                        />

                        <TextInput
                            name="nombre_turno"
                            label="Nombre Turno"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_turno} // Asignar el valor del estado al campo
                            placeholder='Nombre Turno'

                        />



                    </Stack>

                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Turno" : "Editar Turno"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default TurnosForm;