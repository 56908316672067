import React, { useState, useEffect } from 'react';
import { Button, Group, Select, Autocomplete, Flex } from '@mantine/core';

import { DatePickerInput, DateTimePicker } from '@mantine/dates';
import { supabase } from "../supabaseClient";
import { IconCalendar, IconSearch } from '@tabler/icons-react';
import { rem } from '@mantine/core';

function NotasFiltro({ setFilters, filters, onEnterPress }) {
    const [unidades, setUnidades] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [salas, setSalas] = useState([]);
    const [accesos, setAccesos] = useState([]);
    const [idNotas, setIdNotas] = useState([]);
    const [manejos, setManejos] = useState([]);
    const [evoluciones, setEvoluciones] = useState([]);
    const [cambios, setCambios] = useState([]); // para saber los casos que tienen notas con cambio de cateter
    const [procedencias, setProcedencias] = useState([]);
    const [usos, setUsos] = useState([]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnterPress(); // Llama a la función getData pasada como prop
        }
    };

    useEffect(() => {
        const fetchUnidades = async () => {
            try {
                const { data: unidadesData, error: errorData } = await supabase
                    .from('cat_unidades')
                    .select('nombre_unidad');

                if (errorData) {
                    throw errorData;
                }

                const unidadesOptions = unidadesData.map((unidad) => ({
                    label: unidad.nombre_unidad,
                    value: unidad.nombre_unidad
                }));

                setUnidades(unidadesOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchPacientes = async () => {
            try {
                const { data: pacientesData, error: errorData } = await supabase
                    .from('cat_pacientes')
                    .select('nombre_paciente');

                if (errorData) {
                    throw errorData;
                }

                const pacientesOptions = pacientesData.map((paciente) => ({
                    label: paciente.nombre_paciente,
                    value: paciente.nombre_paciente
                }));

                setPacientes(pacientesOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchEtapas = async () => {
            try {
                const { data: etapasData, error: errorData } = await supabase
                    .from('cat_etapas_casos')
                    .select('nombre_etapa_caso');

                if (errorData) {
                    throw errorData;
                }

                const etapasOptions = etapasData.map((etapas) => ({
                    label: etapas.nombre_etapa_caso,
                    value: etapas.nombre_etapa_caso
                }));

                setEtapas(etapasOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchTurnos = async () => {
            try {
                const { data: turnosData, error: errorData } = await supabase
                    .from('cat_turnos')
                    .select('nombre_turno')
                    .order('nombre_turno', { ascending: true } );

                if (errorData) {
                    throw errorData;
                }

                const turnosOptions = turnosData.map((turnos) => ({
                    label: turnos.nombre_turno,
                    value: turnos.nombre_turno
                }));

                setTurnos(turnosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchSalas = async () => {
            try {
                const { data: salasData, error: errorData } = await supabase
                    .from('cat_salas')
                    .select('nombre_sala');

                if (errorData) {
                    throw errorData;
                }

                const salasOptions = salasData.map((salas) => ({
                    label: salas.nombre_sala,
                    value: salas.nombre_sala
                }));

                setSalas(salasOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchAccesos = async () => {
            try {
                const { data: accesosData, error: errorData } = await supabase
                    .from('cat_tiposav')
                    .select('clasificacion_tipoav');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueAccesos = [...new Set(accesosData.map(acceso => acceso.clasificacion_tipoav))];

                // Mapear los valores únicos a las opciones para el estado
                const accesosOptions = uniqueAccesos.map((acceso) => ({
                    label: acceso, // Usa clasificacion_tipoav como label
                    value: acceso  // Usa clasificacion_tipoav como value
                }));

                // Establecer los accesos únicos
                setAccesos(accesosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchIdNotas = async () => {
            try {
                const { data: idsData, error: errorData } = await supabase
                    .from('notas')
                    .select('id_nota')
                    .order('id_nota', { ascending: true });


                if (errorData) {
                    throw errorData;
                }

                const idsOptions = idsData.map((idNotas) => ({
                    label: String(idNotas.id_nota),
                    value: String(idNotas.id_nota)
                }));

                setIdNotas(idsOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchManejos = async () => {
            try {
                const { data: data, error: errorData } = await supabase
                    .from('cat_manejos')
                    .select('nombre_manejo');

                if (errorData) {
                    throw errorData;
                }

                const options = data.map((manejos) => ({
                    label: String(manejos.nombre_manejo),
                    value: String(manejos.nombre_manejo)
                }));

                setManejos(options);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchEvoluciones = async () => {
            try {
                const { data: data, error: errorData } = await supabase
                    .from('cat_evoluciones')
                    .select('nombre_evolucion');

                if (errorData) {
                    throw errorData;
                }

                const options = data.map((evoluciones) => ({
                    label: String(evoluciones.nombre_evolucion),
                    value: String(evoluciones.nombre_evolucion)
                }));

                setEvoluciones(options);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchCambios = async () => {
            try {
                const { data: data, error: errorData } = await supabase
                    .from('cat_cambios')
                    .select('nombre_cambio');

                if (errorData) {
                    throw errorData;
                }

                const options = data.map((cambios) => ({
                    label: String(cambios.nombre_cambio),
                    value: String(cambios.nombre_cambio)
                }));

                setCambios(options);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchProcedencias = async () => {
            try {
                const { data: data, error: errorData } = await supabase
                    .from('cat_procedencias')
                    .select('nombre_procedencia');

                if (errorData) {
                    throw errorData;
                }

                const options = data.map((procedencias) => ({
                    label: String(procedencias.nombre_procedencia),
                    value: String(procedencias.nombre_procedencia)
                }));

                setProcedencias(options);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };
        const fetchUsos = async () => {
            try {
                const { data: usosData, error: errorData } = await supabase
                    .from('cat_usos')
                    .select('clasificacion_uso');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueUsos = [...new Set(usosData.map(uso => uso.clasificacion_uso))];

                // Mapear los valores únicos a las opciones para el estado
                const usosOptions = uniqueUsos.map((uso) => ({
                    label: uso, // Usa clasificacion_tipoav como label
                    value: uso  // Usa clasificacion_tipoav como value
                }));

                // Establecer los accesos únicos
                setUsos(usosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };


        fetchUnidades();
        fetchPacientes();
        fetchEtapas();
        fetchTurnos();
        fetchSalas();
        fetchAccesos();
        fetchIdNotas();
        fetchManejos();
        fetchEvoluciones();
        fetchCambios();
        fetchProcedencias();
        fetchUsos();

    }, []);

    const handleDateChange = (field, date) => {
        setFilters({ ...filters, [field]: date });
    };

    return (
        <div>

            <div onKeyDown={handleKeyDown} className='Filtro'>
                <Autocomplete
                    label="IdNota"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={idNotas}
                    value={filters.idNotas}
                    onChange={(value) => setFilters({ ...filters, id_nota: value })}
                    name="id_nota"
                />
                <Select
                    label="Unidad"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={unidades}
                    value={filters.unidades}
                    onChange={(value) => setFilters({ ...filters, nombre_unidad: value })}
                    name="nombre_unidad"
                />

                <Autocomplete
                    label="Paciente"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={pacientes}
                    value={filters.pacientes}
                    onChange={(value) => setFilters({ ...filters, nombre_paciente: value })}
                    name="nombre_paciente"
                />
                
                <Select
                    label="Etapa"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={etapas}
                    value={filters.etapas}
                    onChange={(value) => setFilters({ ...filters, nombre_etapa_caso: value })}
                    name="nombre_etapa_caso"
                />
                <Select
                    label="Tipo acceso"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={accesos}
                    value={filters.accesos}
                    onChange={(value) => setFilters({ ...filters, clasificacion_tipoav: value })}
                    name="clasificacion_tipoav"
                />
                <Select
                    label="Procedencia"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={procedencias}
                    value={filters.procedencias}
                    onChange={(value) => setFilters({ ...filters, nombre_procedencia: value })}
                    name="nombre_procedencia"
                />
                <Select
                    label="Uso de Filtro"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={usos}
                    value={filters.usos}
                    onChange={(value) => setFilters({ ...filters, clasificacion_uso: value })}
                    name="clasificacion_uso"
                />

            </div>
            <div onKeyDown={handleKeyDown} className='Filtro'>

                <DatePickerInput
                    label="F. inicial"
                    className='caja-150'

                    value={filters.fechaInicial}
                    onChange={(date) => handleDateChange('fechaInicial', date)}
                    clearable
                    locale="es"
                    valueFormat="DD/MM/YYYY"

                />
                <DatePickerInput
                    label="F. final"
                    className='caja-150'
                    value={filters.fechaFinal}
                    onChange={(date) => handleDateChange('fechaFinal', date)}
                    clearable
                    locale="es"
                    valueFormat="DD/MM/YYYY"
                />
                
                <Select
                    label="Turno"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={turnos}
                    value={filters.turnos}
                    onChange={(value) => setFilters({ ...filters, nombre_turno: value })}
                    name="nombre_turno"
                />
                <Select
                    label="Sala"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={salas}
                    value={filters.salas}
                    onChange={(value) => setFilters({ ...filters, nombre_sala: value })}
                    name="nombre_sala"
                />
                <Select
                    label="Manejo"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={manejos}
                    value={filters.manejos}
                    onChange={(value) => setFilters({ ...filters, nombre_manejo: value })}
                    name="nombre_manejo"
                />
                <Select
                    label="Tipo Evolución"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={evoluciones}
                    value={filters.evoluciones}
                    onChange={(value) => setFilters({ ...filters, nombre_evolucion: value })}
                    name="nombre_evolucion"
                />
                <Select
                    label="¿Cambio Catéter?"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={cambios}
                    value={filters.cambios}
                    onChange={(value) => setFilters({ ...filters, nombre_cambio: value })}
                    name="nombre_cambio"
                />


            </div>

        </div>

    );
}

export default NotasFiltro;