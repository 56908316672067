import React, { useEffect, useState } from 'react';
import { Group, Code, ScrollArea, rem, Text, TypographyStylesProvider, Divider } from '@mantine/core';
import {
    IconReportMoney,
    IconClipboardText,
    IconHome,
    IconBook2,
    IconReportAnalytics,
    IconAdjustments,
    IconLock,
} from '@tabler/icons-react';
import { LinksGroup } from './LinksGroup';
import classes from './NavbarNested.module.css';
import { useAuth } from '../context/AuthContext';
import { useDispatch } from 'react-redux';
import { ShowLoading, HideLoading } from '../redux/alertsSlice';
import { supabase } from '../supabaseClient';
import { UserButton } from './UserButton';
import Spinner from './Spinner';

const mockdata = [
    {
        label: 'Inicio',
        icon: IconHome,
        //initiallyOpened: true,
        links: [
            { label: 'Ir a inicio', link: '/home' },
        ],
    }, {
        label: 'Catálogos',
        icon: IconBook2,
        //initiallyOpened: true,
        links: [
            { label: 'Antibióticos', link: '/cat_antibioticos' },
            { label: 'Conclusiones casos', link: '/cat_conclusiones' },

            { label: 'Condiciones parche', link: '/cat_parches' },

            { label: 'Enfermeros', link: '/cat_enfermeros' },
            { label: 'Etapas Casos', link: '/cat_etapas_casos' },
            { label: 'Máquinas', link: '/cat_maquinas' },

            { label: 'Num. Sesiones', link: '/cat_sesiones' },

            { label: 'Pacientes', link: '/cat_pacientes' },

            { label: 'Procedencias', link: '/cat_procedencias' },

            { label: 'Respuestas cultivos', link: '/cat_resultados_cultivos' },
            { label: 'Salas', link: '/cat_salas' },

            { label: 'Secreciones', link: '/cat_secreciones' },

            { label: 'Sensibilidades', link: '/cat_sensibilidades' },
            { label: 'Signos', link: '/cat_signos' },


            { label: 'Sintomas', link: '/cat_sintomas' },

            { label: 'Tipos Acceso Vascular', link: '/cat_tiposav' },

            { label: 'Tipos de Cultivo', link: '/cat_tiposcultivos' },
            { label: 'Turnos', link: '/cat_turnos' },


            { label: 'Unidades', link: '/cat_unidades' },
            { label: 'Usos de filtro', link: '/cat_usos' },







            { label: 'Usuarios', link: '/cat_usuarios' },
        ],
    },
    {
        label: 'Casos',
        icon: IconClipboardText,
        links: [
            { label: 'Ver Casos', link: '/casos' },
        ],
    },
    {
        label: 'Pagos',
        icon: IconReportMoney,
        links: [
            { label: 'Ver pagos', link: '/pagos' },
        ],
    },
    {
        label: 'Reportes',
        icon: IconReportAnalytics,
        links: [
            { label: 'Casos', link: '/reporte_casos' },
            { label: 'Cultivos por Turno y Sala', link: '/reporte_tabla1' },
            { label: 'Positividad por Turno y Sala', link: '/reporte_tabla2' },
            { label: 'Cultivos por Enfermero', link: '/reporte_tabla3' },
            { label: 'Cultivos por Acceso Vascular', link: '/reporte_tabla6' },
            { label: 'Cultivos por Uso Filtro', link: '/reporte_tabla9' },
            { label: 'Cultivos General', link: '/reporte_tabla12' },
            { label: 'Notas Médicas', link: '/reporte_notas' }







            
        ],
    },
];

export function SideNavbar({ user, permisos }) {
    const [loaded, setLoaded] = useState(false); // Estado local para controlar la carga de permisos
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && permisos) {
            // Verificar si los permisos y el usuario están disponibles
            setLoaded(true); // Marcar que los permisos han sido cargados
        }
    }, [user, permisos]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    useEffect(() => {
        // Mostrar indicador de carga mientras se revisan los permisos
        if (!loaded) {
            dispatch(ShowLoading());
        } else {
            // Ocultar indicador de carga una vez que se han revisado los permisos
            dispatch(HideLoading());
        }
    }, [loaded, dispatch]);

    // Renderizar el menú solo después de cargar los permisos y el usuario
    if (!loaded) {
        return null;
        //dispatch(ShowLoading());
    }

   

    const menuItems = mockdata.map((item) => {
        if (item.label === 'Inicio') {
            return <LinksGroup {...item} key={item.label} />;
        }
        if (item.label === 'Catálogos') {
            // Filtrar elementos del catálogo
            const catalogoItems = item.links.filter((link) => {
                // Obtener el nombre del catálogo del enlace
                const catalogo = link.link.split('/')[1];
                // Verificar si el catálogo está presente en los permisos y si está activo
                return !permisos ||
                    !permisos.catalogos ||
                    !permisos.catalogos.modulos ||
                    !permisos.catalogos.modulos[catalogo] ||
                    permisos.catalogos.modulos[catalogo].activo === true;
            });
            //console.log("Catálogo Items:", catalogoItems);

            // Si no hay elementos en el catálogo, no mostrarlo
            if (catalogoItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces del catálogo con los elementos filtrados
            return <LinksGroup {...item} links={catalogoItems} key={item.label} />;
        }
        if (item.label === 'Casos') {
            // Filtrar elementos de pólizas
            const casosItems = item.links.filter((link) => {
                // Verificar si la póliza está presente en los permisos y si está activa
                return permisos &&
                    permisos.casos &&
                    permisos.casos.activo === true;
            });
            //console.log("Pólizas Items:", casosItems);

            // Si no hay elementos en pólizas, no mostrarlo
            if (casosItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces de pólizas con los elementos filtrados
            return <LinksGroup {...item} links={casosItems} key={item.label} />;
        }
        if (item.label === 'Pagos') {
            // Filtrar elementos de pagos
            const pagosItems = item.links.filter((link) => {
                // Verificar si la pagos está presente en los permisos y si está activa
                return permisos &&
                    permisos.pagos &&
                    permisos.pagos.activo === true;
            });
            //console.log("pagos Items:", pagosItems);

            // Si no hay elementos en pólizas, no mostrarlo
            if (pagosItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces de pólizas con los elementos filtrados
            return <LinksGroup {...item} links={pagosItems} key={item.label} />;
        }

        if (item.label === 'Reportes') {
            // Filtrar elementos del catálogo
            const reporteItems = item.links.filter((link) => {
                // Obtener el nombre del catálogo del enlace
                const reporte = link.link.split('/')[1];
                // Verificar si el catálogo está presente en los permisos y si está activo
                return permisos &&
                    permisos.reportes &&
                    permisos.reportes.modulos &&
                    permisos.reportes.modulos[reporte] &&
                    permisos.reportes.modulos[reporte].activo === true;
            });
            //console.log("Reporte Items:", reporteItems);

            // Si no hay elementos en el catálogo, no mostrarlo
            if (reporteItems.length === 0) {
                return null;
            }
            // Retornar el grupo de enlaces del catálogo con los elementos filtrados
            return <LinksGroup {...item} links={reporteItems} key={item.label} />;
        }

        // Si no es el catálogo ni 'Polizas', simplemente retornar el grupo de enlaces
        return <LinksGroup {...item} key={item.label} />;
    });



    return (
        <nav className={classes.navbar}>
            <div className={classes.header}>
                <UserButton></UserButton>
            </div>
            <ScrollArea style={{ maxHeight: 'calc(100vh - 210px)' }} className={classes.links}>
                <div className={classes.linksInner}>{menuItems}</div>
            </ScrollArea>
        </nav>
    );
}

export default SideNavbar;