import React, { useState, useEffect } from 'react';
import { Table, Button, Group, Pagination } from '@mantine/core'
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';



function EnfermerosTable({ enfermeros, setSelectedTransaction, setFormMode, setShowForm, getData, filtroEnfermero, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dispatch = useDispatch();


    // Aplicar filtro por nombre del contratante si se ha proporcionado un filtro
    const enfermerosFiltradas = filtroEnfermero
        ? enfermeros.filter(enfermero => enfermero.nombre_enfermero.toLowerCase().includes(filtroEnfermero.toLowerCase()))
        : enfermeros;

    const paginatedTransactions = enfermerosFiltradas.slice(startIndex, endIndex);

    const deleteTransaction = async (id) => {
        try {
            dispatch(ShowLoading());

            // Aquí realizamos la eliminación usando Supabase
            const { error } = await supabase
                .from('cat_enfermeros')
                .delete()
                .eq('id_enfermero', id);

            if (error) {
                throw new Error('No se pudo borrar la Enfermero');
            }

            dispatch(HideLoading());
            showNotification({
                title: 'Enfermero borrada',
                color: 'green'
            });

            getData(); // Esta función debe obtener nuevamente los datos después de la eliminación
        } catch (error) {
            dispatch(HideLoading());
            showNotification({
                title: 'Error, no se pudo borrar la Enfermero',
                color: 'red'
            });
        }
    };

    const getRows = paginatedTransactions.map((enfermero) => (
        <tr key={enfermero.id_enfermero}>
            <td>{enfermero.id_enfermero}</td>
            <td>{enfermero.nombre_enfermero}</td>
            <td>
                <Group justify="flex-start">
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_enfermeros &&
                        permisos.catalogos.modulos.cat_enfermeros.acciones &&
                        permisos.catalogos.modulos.cat_enfermeros.acciones.modificar_enfermero && (
                            <i
                                key={`edit-${enfermero.id_enfermero}`}
                                className='ri-pencil-line'
                                onClick={() => {
                                    setSelectedTransaction(enfermero);
                                    setFormMode("edit");
                                    setShowForm(true);
                                    //console.log("Enfermero seleccionada:", enfermero);
                                }}
                            ></i>
                        )}
                    {permisos &&
                        permisos.catalogos &&
                        permisos.catalogos.modulos &&
                        permisos.catalogos.modulos.cat_enfermeros &&
                        permisos.catalogos.modulos.cat_enfermeros.acciones &&
                        permisos.catalogos.modulos.cat_enfermeros.acciones.eliminar_enfermero && (

                            <i
                                key={`delete-${enfermero.id_enfermero}`}
                                className='ri-delete-bin-line'
                                onClick={() => {
                                    // Mostrar confirmación al usuario y llamar a deleteTransaction si acepta
                                    if (window.confirm('¿Estás seguro de eliminar esta Enfermero?')) {
                                        deleteTransaction(enfermero.id_enfermero);
                                    }
                                }}
                            ></i>
                        )}

                </Group>
            </td>
        </tr>
    ));

    const csvHeaders = [
        'ID',
        'Nombre enfermero',
    ];

    const csvData = enfermeros.map((enfermero) => [
        enfermero.id_enfermero,
        enfermero.nombre_enfermero,
    ]);

    return (
        <div >

            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead
                    style={{ textAlign: 'left' }}
                >
                    <tr>
                        <th>ID</th>
                        <th>Nombre enfermero</th>
                        <th>Acciones</th>


                    </tr>
                </thead>
                <tbody>{getRows}</tbody>
            </Table>
            <Group justify="flex-end" mb={10}>
                <Pagination
                    total={Math.ceil(enfermeros.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />

                {/* Agregar enlace para descargar CSV con react-csv-downloader */}

                <CsvDownloader
                    filename={'enfermeros.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A' >Descargar Tabla</Button>
                </CsvDownloader>
            </Group>

        </div>
    );
}

export default EnfermerosTable;