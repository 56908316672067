import React, { useState } from 'react';
import { Table, Modal, Button, Badge } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import CsvDownloader from 'react-csv-downloader';
import { IconDownload } from '@tabler/icons-react';



const Tabla7 = ({ data, filters }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const [cultivosData, setCultivosData] = useState([]);
    const [selectedAccesosvascular, setSelectedAccesosvascular] = useState(null); // Para almacenar el tipo de acceso seleccionado
    const [selectedClasificacion, setSelectedClasificacion] = useState(null);

    // Función para obtener el total por columna
    const getTotalByColumn = (column) => {
        return data.reduce((acc, item) => acc + (item[column] || 0), 0);
    };

    // Función para convertir fecha a formato YYYY-MM-DD
    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];  // Convierte la fecha y toma solo la parte de la fecha (sin la hora)
    };

    // Modificar la función para recibir id_tipoav
    const handleFetchCultivos = async (idAccesosvascular, nombreClasficacion) => {
        dispatch(ShowLoading());
        setSelectedAccesosvascular(idAccesosvascular); // Almacena el acceso vascular seleccionado
        setSelectedClasificacion(nombreClasficacion);
        setModalOpened(true);

        // Inicia la consulta
        let query = supabase
            .from('cultivos')
            .select(`    
                id_cultivo,
                numero_cultivo,
                fecha_toma_cultivo,
                id_resultado_cultivo,
                cat_resultados_cultivos!inner(
                    id_resultado_cultivo,
                    nombre_resultado_cultivo,
                    clasificacion_resultado_cultivo
                ),
                id_tipocultivo,
                cat_tiposcultivos!inner (
                    id_tipocultivo,
                    nombre_tipocultivo
                ),
                casos!inner(
                    id_caso,
                    numero_caso,
                    id_turno,
                    cat_turnos!inner (
                        id_turno,
                        nombre_turno
                    ),
                    id_sala,
                    cat_salas!inner (
                        id_sala,
                        nombre_sala
                    ),
                    id_unidad,
                    cat_unidades!inner (
                        id_unidad,
                        nombre_unidad
                    ),
                    id_paciente,
                    cat_pacientes!inner (
                        id_paciente,
                        nombre_paciente
                    ),
                    id_enfermero,
                    cat_enfermeros!inner(
                        id_enfermero,
                        nombre_enfermero
                    ),
                    id_etapa_caso,
                    cat_etapas_casos!inner(
                        id_etapa_caso,
                        nombre_etapa_caso
                    ),
                    id_tipoav,
                    cat_tiposav!inner(
                        id_tipoav,
                        nombre_tipoav,
                        clasificacion_tipoav
                    ),
                    id_uso,
                    cat_usos!inner(
                        id_uso,
                        nombre_uso,
                        clasificacion_uso
                    ),
                    id_maquina,
                    cat_maquinas!inner(
                        id_maquina,
                        nombre_maquina
                        )
                )
            `)
            .eq('casos.cat_tiposav.clasificacion_tipoav', idAccesosvascular) // Filtrar solo por id_tipoav
            .eq('cat_resultados_cultivos.clasificacion_resultado_cultivo', nombreClasficacion); // Filtrar solo por nombreClasificacion


        // Agregar filtros adicionales si existen
        if (filters.nombre_unidad) {
            query = query.eq('casos.cat_unidades.nombre_unidad', filters.nombre_unidad);
        }
        if (filters.nombre_paciente) {
            query = query.eq('casos.cat_pacientes.nombre_paciente', filters.nombre_paciente);
        }
        if (filters.nombre_etapa_caso) {
            query = query.eq('casos.cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
        }

        // Filtro por fechas
        if (filters.fechaInicial) {
            query = query.gte('fecha_toma_cultivo', formatDate(filters.fechaInicial));
        }
        if (filters.fechaFinal) {
            query = query.lte('fecha_toma_cultivo', formatDate(filters.fechaFinal));
        }

        if (filters.clasificacion_tipoav) {
            query = query.eq('casos.cat_tiposav.clasificacion_tipoav', filters.clasificacion_tipoav);
        }

        // Filtro por fechas
        if (filters.fechaInicial) {
            query = query.gte('fecha_toma_cultivo', formatDate(filters.fechaInicial));
        }
        if (filters.fechaFinal) {
            query = query.lte('fecha_toma_cultivo', formatDate(filters.fechaFinal));
        }

        if (filters.clasificacion_resultado_cultivo && filters.clasificacion_resultado_cultivo.length > 0) {
            query = query.in('cat_resultados_cultivos.clasificacion_resultado_cultivo', filters.clasificacion_resultado_cultivo);
        }

        // filtro clasificacion_uso
        if (filters.clasificacion_uso) {
            query = query.eq('casos.cat_usos.clasificacion_uso', filters.clasificacion_uso);
        }

        // filtro nombre_maquina
        if (filters.nombre_maquina) {
            query = query.eq('casos.cat_maquinas.nombre_maquina', filters.nombre_maquina);
        }

        const { data: cultivos, error } = await query; // Ejecuta la consulta

        if (error) {
            console.error('Error al obtener cultivos:', error);
            dispatch(HideLoading());
            return;
        }

        console.log('Cultivos obtenidos:', cultivos);
        setCultivosData(cultivos);
        dispatch(HideLoading());
    };

    const closeModal = () => {
        setModalOpened(false);
        setCultivosData([]);
        setSelectedAccesosvascular(null); // Reinicia el acceso vascular seleccionado
        setSelectedClasificacion(null);

    };

    if (!data || data.length === 0) {
        return (
            <div>
                <Badge color='grey'>Sin información</Badge>
            </div>
        );
    }

    const csvHeaders = [
        'ACCESO VASCULAR',
        'CLASIFICACION RESULTADO',
        'TOTAL',
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    // Genera los datos para el CSV y aplica la función para sanitizar
    const csvData = data.map((data) => [
        sanitizeCSV(data.clasificacion_tipoav),
        sanitizeCSV(data.clasificacion_resultado_cultivo),
        data.total_cultivos, // No sanitizamos números
    ]);

    // para descargar CSV del Modal
    const csvHeaders2 = [
        'TURNO',
        'SALA',
        'UNIDAD',
        'ENFERMERO',
        'PACIENTE',
        'AV',
        'NO. CASO',
        'ID CULTIVO',
        'TIPO',
        'NO. CULTIVO',
        'RESULTADO',
        'CLASIFICACION',
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV2 = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData2 = cultivosData.map((cultivo) => [
        sanitizeCSV2(cultivo.casos.cat_turnos.nombre_turno),    // Turno
        sanitizeCSV2(cultivo.casos.cat_salas.nombre_sala),      // Sala
        sanitizeCSV2(cultivo.casos.cat_unidades.nombre_unidad), // Unidad
        sanitizeCSV2(cultivo.casos.cat_enfermeros.nombre_enfermero), // Enfermero
        sanitizeCSV2(cultivo.casos.cat_pacientes.nombre_paciente),   // Paciente
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),   // AV (Tipo de AV)
        sanitizeCSV2(cultivo.casos.numero_caso),
        sanitizeCSV2(cultivo.id_cultivo),
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),
        sanitizeCSV2(cultivo.numero_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.nombre_resultado_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.clasificacion_resultado_cultivo),
    ]);

    return (
        <>
            <CsvDownloader
                filename={'Tabla7.csv'}
                separator=","
                wrapColumnChar=""
                datas={csvData}
                columns={csvHeaders}
            >
                <Button
                    variant="outline"
                    color='green'
                    mb={'10px'}
                    rightSection={<IconDownload size={20} />}
                >
                    Descargar Tabla
                </Button>
            </CsvDownloader>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse', width: '100%' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th style={{ maxWidth: '50px', width: '50px' }}>
                            ACCESO VASCULAR
                        </th>
                        <th style={{ maxWidth: '20px', width: '20px' }}>
                            CLASIFICACIÓN RESULTADO
                        </th>
                        <th style={{ maxWidth: '20px', width: '20px' }}>
                            TOTAL
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => {
                        return (
                            <tr key={`${item.clasificacion_tipoav}-${item.clasificacion_resultado_cultivo}-${item.total_cultivos}`}>
                                <td>{item.clasificacion_tipoav}</td>
                                <td>
                                    {item.clasificacion_resultado_cultivo === 'POSITIVO' && (
                                        <Badge color="red" variant="filled">
                                            POSITIVO
                                        </Badge>
                                    )}
                                    {item.clasificacion_resultado_cultivo === 'NEGATIVO' && (
                                        <Badge color="green" variant="filled">
                                            NEGATIVO
                                        </Badge>
                                    )}
                                    {item.clasificacion_resultado_cultivo === 'EN PROCESO' && (
                                        <Badge color="orange" variant="filled">
                                            EN PROCESO
                                        </Badge>
                                    )}
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    {item.total_cultivos > 0 ? (
                                        <Button
                                            size="compact-sm"
                                            variant='default'
                                            fullWidth
                                            onClick={() => handleFetchCultivos(item.clasificacion_tipoav, item.clasificacion_resultado_cultivo)}>
                                            {item.total_cultivos}
                                        </Button>
                                    ) : (
                                        <span>{item.total_cultivos}</span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td>
                            <strong>Total</strong>
                        </td>
                        <td>
                            {getTotalByColumn('total_cultivos')}
                        </td>
                    </tr>
                </tfoot>
            </Table>

            <Modal
                opened={modalOpened}
                onClose={closeModal}
                title={`Cultivos para Accesosvascular ${selectedAccesosvascular} y Cultivos con resultado: ${selectedClasificacion}`}
                //size="95%"
                fullScreen
            >
                <CsvDownloader
                    filename={'tabla77.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData2}
                    columns={csvHeaders2}
                >
                    <Button
                        variant="outline"
                        color='green'
                        mb={'10px'}
                        rightSection={<IconDownload size={20} />}
                    >
                        Descargar Tabla
                    </Button>
                </CsvDownloader>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead>
                        <tr>
                            <th>TURNO</th>
                            <th>SALA</th>
                            <th>UNIDAD</th>
                            <th>ENFERMERO</th>
                            <th>PACIENTE</th>
                            <th>AV</th>
                            <th># CASO</th>
                            <th>ID CUL.</th>
                            <th>TIPO</th>
                            <th># CULTIVO</th>
                            <th>RESULTADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cultivosData.map((cultivo, index) => (
                            <tr key={index}>
                                <td>{cultivo.casos.cat_turnos.nombre_turno}</td>
                                <td>{cultivo.casos.cat_salas.nombre_sala}</td>
                                <td>{cultivo.casos.cat_unidades.nombre_unidad}</td>
                                <td>{cultivo.casos.cat_enfermeros.nombre_enfermero}</td>
                                <td>{cultivo.casos.cat_pacientes.nombre_paciente}</td>
                                <td>{cultivo.casos.cat_tiposav.clasificacion_tipoav}</td>
                                <td>{cultivo.casos.numero_caso}</td>
                                <td>{cultivo.id_cultivo}</td>
                                <td>{cultivo.cat_tiposcultivos.nombre_tipocultivo}</td>
                                <td>{cultivo.numero_cultivo}</td>
                                <td>{cultivo.cat_resultados_cultivos.nombre_resultado_cultivo}</td>
                                <td>
                                    <Badge
                                        color={
                                            cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'NEGATIVO'
                                                ? 'green'
                                                : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'POSITIVO'
                                                    ? 'red'
                                                    : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'EN PROCESO'
                                                        ? 'orange'
                                                        : 'gray'
                                        }
                                    >
                                        {cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo || 'Sin clasificación'}
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
        </>
    );
};

export default Tabla7;
