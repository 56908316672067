import React, { useState } from 'react';
import { Table, Modal, Button, Badge, Text, Divider } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import CsvDownloader from 'react-csv-downloader';
import { IconDownload } from '@tabler/icons-react';


const Tabla2 = ({ data, filters }) => {
    const dispatch = useDispatch();

    const [modalOpened, setModalOpened] = useState(false);
    const [cultivosData, setCultivosData] = useState([]);
    const [selectedTurno, setSelectedTurno] = useState(null);
    const [selectedSala, setSelectedSala] = useState(null);

    // Función para convertir fecha a formato YYYY-MM-DD
    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];  // Convierte la fecha y toma solo la parte de la fecha (sin la hora)
    };

    const handleFetchCultivos = async (nombreSala, nombreTurno) => {
        dispatch(ShowLoading());
        setSelectedTurno(nombreTurno);
        setSelectedSala(nombreSala);
        setModalOpened(true);

        // Inicia la consulta
        let query = supabase
            .from('cultivos')
            .select(`
                id_cultivo,
                fecha_toma_cultivo,
                numero_cultivo,
                id_resultado_cultivo,
                cat_resultados_cultivos!inner(
                    id_resultado_cultivo,
                    nombre_resultado_cultivo,
                    clasificacion_resultado_cultivo
                ),
                id_tipocultivo,
                cat_tiposcultivos!inner (
                    id_tipocultivo,
                    nombre_tipocultivo
                ),
                casos!inner(
                    id_caso,
                    numero_caso,
                    id_turno,
                    cat_turnos!inner (
                        id_turno,
                        nombre_turno
                    ),
                    id_sala,
                    cat_salas!inner (
                        id_sala,
                        nombre_sala
                    ),
                    id_unidad,
                    cat_unidades!inner (
                        id_unidad,
                        nombre_unidad
                    ),
                    id_paciente,
                    cat_pacientes!inner (
                        id_paciente,
                        nombre_paciente
                    ),
                    id_enfermero,
                    cat_enfermeros!inner(
                        id_enfermero,
                        nombre_enfermero
                    ),
                    id_etapa_caso,
                    cat_etapas_casos!inner(
                        id_etapa_caso,
                        nombre_etapa_caso
                    ),
                    id_tipoav,
                    cat_tiposav!inner(
                        id_tipoav,
                        nombre_tipoav,
                        clasificacion_tipoav
                    ),
                    id_uso,
                    cat_usos!inner(
                        id_uso,
                        nombre_uso,
                        clasificacion_uso
                    ),
                    id_maquina,
                    cat_maquinas!inner(
                        id_maquina,
                        nombre_maquina
                        )
                )
            `)
            .eq('casos.cat_salas.nombre_sala', nombreSala)
            .eq('casos.cat_turnos.nombre_turno', nombreTurno);

        // filtro de nombre_unidad
        if (filters.nombre_unidad) {
            query = query.eq('casos.cat_unidades.nombre_unidad', filters.nombre_unidad);
        }
        // Si hay un filtro de nombre_paciente, agregar la condición
        if (filters.nombre_paciente) {
            query = query.eq('casos.cat_pacientes.nombre_paciente', filters.nombre_paciente);
        }

        // filtro de enfermero
        if (filters.nombre_enfermero) {
            query = query.eq('casos.cat_enfermeros.nombre_enfermero', filters.nombre_enfermero);
        }

        //filtro nombre_etapa_caso
        if (filters.nombre_etapa_caso) {
            query = query.eq('casos.cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
        }

        //filtro nombre_tipoav
        if (filters.clasificacion_tipoav) {
            query = query.eq('casos.cat_tiposav.clasificacion_tipoav', filters.clasificacion_tipoav);
        }

        // Filtro por fechas
        if (filters.fechaInicial) {
            query = query.gte('fecha_toma_cultivo', formatDate(filters.fechaInicial));
        }
        if (filters.fechaFinal) {
            query = query.lte('fecha_toma_cultivo', formatDate(filters.fechaFinal));
        }

        // Filtro para clasificacion_resultado_cultivo
        if (filters.clasificacion_resultado_cultivo && filters.clasificacion_resultado_cultivo.length > 0) {
            query = query.in('cat_resultados_cultivos.clasificacion_resultado_cultivo', filters.clasificacion_resultado_cultivo);
        }

        // filtro clasificacion_uso
        if (filters.clasificacion_uso) {
            query = query.eq('casos.cat_usos.clasificacion_uso', filters.clasificacion_uso);
        }

        // filtro nombre_maquina
        if (filters.nombre_maquina) {
            query = query.eq('casos.cat_maquinas.nombre_maquina', filters.nombre_maquina);
        }



        const { data: cultivos, error } = await query;  // Ejecuta la consulta

        if (error) {
            console.error('Error al obtener cultivos:', error);
            dispatch(HideLoading());
            return;
        }

        console.log('Cultivos obtenidos:', cultivos);
        setCultivosData(cultivos);
        dispatch(HideLoading());
    };


    const closeModal = () => {
        setModalOpened(false);
        setCultivosData([]);
        setSelectedTurno(null);
        setSelectedSala(null);
    };

    if (!data || data.length === 0) {
        return <div>
            <Badge
                color='grey'
            >
                Sin información
            </Badge>
        </div>;
    }

    const renderSalaCell = (positividad, count, sala, turno) => {
        // Solo mostrar botón si la positividad es mayor a 0 o el conteo es mayor a 0
        if (positividad > 0 || count > 0) {
            // Determinar color del botón según el rango de positividad
            let color = "green"; // Por defecto menor a 32.9%
            if (positividad > 66) {
                color = "red";
            } else if (positividad >= 33 && positividad <= 65.9) {
                color = "yellow";
            }

            return (
                <Button
                    size="compact-sm"
                    fullWidth
                    variant="filled" // Usar un estilo "filled" para el color del botón
                    color={color}
                    onClick={() => handleFetchCultivos(sala, turno)}
                >
                    {positividad.toFixed(2)}%
                </Button>
            );
        }

        // En caso de que no se cumpla la condición, mostrar un guion
        return <span>Sin dato</span>;
    };


    const calculatePromedioPorTurno = (item) => {
        const totalPositividad =
            item.sala1_positividad +
            item.sala2_positividad +
            item.sala3_positividad +
            item.sala4_positividad +
            item.na_positividad;

        //console.log('valor sala1_positividad....', item.sala1_positividad)
        //console.log('valor sala1_count....', item.sala1_count)


        const salasConDatos =
            (item.sala1_positividad >= 0 && item.sala1_count > 0 ? 1 : 0) +
            (item.sala2_positividad >= 0 && item.sala2_count > 0 ? 1 : 0) +
            (item.sala3_positividad >= 0 && item.sala3_count > 0 ? 1 : 0) +
            (item.sala4_positividad >= 0 && item.sala4_count > 0 ? 1 : 0) +
            (item.na_positividad >= 0 && item.na_count > 0 ? 1 : 0);

        return salasConDatos > 0 ? totalPositividad / salasConDatos : 0;
    };

    const calculatePromedioPorSala = () => {
        const salasTotals = {
            sala1: { total: 0, count: 0 },
            sala2: { total: 0, count: 0 },
            sala3: { total: 0, count: 0 },
            sala4: { total: 0, count: 0 },
            na: { total: 0, count: 0 }
        };

        data.forEach(item => {
            ['sala1', 'sala2', 'sala3', 'sala4', 'na'].forEach(sala => {
                if (item[`${sala}_positividad`] >= 0 && item[`${sala}_count`] > 0) { // para que considere los resultados que tienen 0% en positividad y que si tienen cultivos
                    salasTotals[sala].total += item[`${sala}_positividad`];
                    salasTotals[sala].count++;
                }
            });
        });

        return {
            sala1: salasTotals.sala1.count >= 0 ? salasTotals.sala1.total / salasTotals.sala1.count : 'Sin info',
            sala2: salasTotals.sala2.count >= 0 ? salasTotals.sala2.total / salasTotals.sala2.count : 'Sin info',
            sala3: salasTotals.sala3.count >= 0 ? salasTotals.sala3.total / salasTotals.sala3.count : 'Sin info',
            sala4: salasTotals.sala4.count >= 0 ? salasTotals.sala4.total / salasTotals.sala4.count : 'Sin info',
            na: salasTotals.na.count >= 0 ? salasTotals.na.total / salasTotals.na.count : 0
        };
    };

    const promediosPorSala = calculatePromedioPorSala();

    const calculatePromedioGlobal = () => {
        let totalPositividad = 0;
        let totalCount = 0;

        data.forEach(item => {
            ['sala1', 'sala2', 'sala3', 'sala4', 'na'].forEach(sala => {
                if (item[`${sala}_positividad`] >= 0 && item[`${sala}_count`]) {
                    totalPositividad += item[`${sala}_positividad`];
                    totalCount++;
                }
            });
        });

        return totalCount >= 0 ? totalPositividad / totalCount : 0;
    };

    const promedioGlobal = calculatePromedioGlobal();

    const csvHeaders = [
        'TURNO',
        'SALA 1',
        'SALA 2',
        'SALA 3',
        'SALA 4',
        'N/A'
    ];

    // Modificar sanitizeCSV para manejar el conteo
    const sanitizeCSV = (value, count) => {
        if (count <= 0) {
            return 'Sin datos'; // Devuelve "Sin datos" si count es 0 o menor
        }
        if (value === null || value === undefined) {
            return '-'; // Devuelve "-" si es null o undefined
        }
        if (typeof value === 'string') {
            return value.replace(/,/g, ''); // Elimina las comas en textos
        }
        return value; // Retorna el valor original si no es string
    };

    const csvData = data.map((data) => [
        sanitizeCSV(data.nombre_turno, data.nombre_turno),
        sanitizeCSV(data.sala1_positividad, data.sala1_count),
        sanitizeCSV(data.sala2_positividad, data.sala2_count),
        sanitizeCSV(data.sala3_positividad, data.sala3_count),
        sanitizeCSV(data.sala4_positividad, data.sala4_count),
        sanitizeCSV(data.na_positividad, data.na_count),

    ]);

    // para descargar CSV del Modal
    const csvHeaders2 = [
        'TURNO',
        'SALA',
        'UNIDAD',
        'ENFERMERO',
        'PACIENTE',
        'AV',
        'NO. CASO',
        'ID CULTIVO',
        'TIPO',
        'NO. CULTIVO',
        'RESULTADO',
        'CLASIFICACION',
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV2 = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData2 = cultivosData.map((cultivo) => [
        sanitizeCSV2(cultivo.casos.cat_turnos.nombre_turno),    // Turno
        sanitizeCSV2(cultivo.casos.cat_salas.nombre_sala),      // Sala
        sanitizeCSV2(cultivo.casos.cat_unidades.nombre_unidad), // Unidad
        sanitizeCSV2(cultivo.casos.cat_enfermeros.nombre_enfermero), // Enfermero
        sanitizeCSV2(cultivo.casos.cat_pacientes.nombre_paciente),   // Paciente
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),   // AV (Tipo de AV)
        sanitizeCSV2(cultivo.casos.numero_caso),
        sanitizeCSV2(cultivo.id_cultivo),
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),
        sanitizeCSV2(cultivo.numero_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.nombre_resultado_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.clasificacion_resultado_cultivo),
    ]);

    return (
        <>
            <CsvDownloader
                filename={'tabla2.csv'}
                separator=","
                wrapColumnChar=""
                datas={csvData}
                columns={csvHeaders}
            >
                <Button
                    variant="outline"
                    color='green'
                    mb={'10px'}
                    rightSection={<IconDownload size={20} />}
                >
                    Descargar Tabla
                </Button>
            </CsvDownloader>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>TURNO</th>
                        <th>SALA 1</th>
                        <th>SALA 2</th>
                        <th>SALA 3</th>
                        <th>SALA 4</th>
                        <th>N/A</th>
                        <th>
                            <Text size='sm'>
                                PROMEDIO POR TURNO
                            </Text>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        const promedioPorTurno = calculatePromedioPorTurno(item);

                        return (
                            <tr key={index}>
                                <td>{item.nombre_turno}</td>
                                <td>{renderSalaCell(item.sala1_positividad, item.sala1_count, 'SALA 1', item.nombre_turno)}</td>
                                <td>{renderSalaCell(item.sala2_positividad, item.sala2_count, 'SALA 2', item.nombre_turno)}</td>
                                <td>{renderSalaCell(item.sala3_positividad, item.sala3_count, 'SALA 3', item.nombre_turno)}</td>
                                <td>{renderSalaCell(item.sala4_positividad, item.sala4_count, 'SALA 4', item.nombre_turno)}</td>
                                <td>{renderSalaCell(item.na_positividad, item.na_count, 'N/A', item.nombre_turno)}</td>
                                <td>{promedioPorTurno.toFixed(2)}%</td>
                            </tr>
                        );
                    })}
                    <tr style={{ fontWeight: 'bold' }}>
                        <td>
                            <Text size='sm'>
                                PROMEDIO POR SALA
                            </Text>                            </td>
                        <td>{promediosPorSala.sala1.toFixed(2)}%</td>
                        <td>{promediosPorSala.sala2.toFixed(2)}%</td>
                        <td>{promediosPorSala.sala3.toFixed(2)}%</td>
                        <td>{promediosPorSala.sala4.toFixed(2)}%</td>
                        <td>{promediosPorSala.na.toFixed(2)}%</td>
                        <td>
                            <Button
                                size="compact-sm"
                                fullWidth
                                variant="filled" // Usar un estilo "filled" para que el color sea visible
                                color={
                                    promedioGlobal > 66
                                        ? "red"
                                        : promedioGlobal >= 33 && promedioGlobal <= 65.9
                                            ? "yellow"
                                            : "green"
                                }
                            >
                                {promedioGlobal.toFixed(2)}%
                            </Button>
                        </td>

                    </tr>
                </tbody>
            </Table>
            <Text mt={'10px'} size='xs'>
                <strong>Notas:</strong><br />
                El porcentaje de positividad de cultivos por turno y sala se calcula: <br />
                Cultivos positivos / Total de cultivos <br />
                *Asegurarse de tener activado filtro de "Clasificación resultado cultivo" con los valores de "positivo" y "negativo" únicamente.<br />
                <Divider />
                El promedio de positividad por sala se calcula: <br />
                Promedio de positividad en Sala n (Considerando aquellos turnos que tuvieron cultivos)
                <Divider />
                El promedio de positividad por turno se calcula: <br />
                Promedio de positividad en Turno n (Considerando aquellas salas que tuvieron cultivos)
                <Divider />
                El promedio general se calcula como el promedio de aquellas salas y turnos que tuvieron un porcentaje de positividad mayor o igual a 0, y que tuvieron cultivos.
            </Text>



            <Modal
                opened={modalOpened}
                onClose={closeModal}
                title={`Cultivos para ${selectedTurno} en ${selectedSala}`}
                //size="95%"
                fullScreen
            //transitionProps={{ transition: 'fade', duration: 600}}

            >
                <CsvDownloader
                    filename={'tabla22.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData2}
                    columns={csvHeaders2}
                >
                    <Button
                        variant="outline"
                        color='green'
                        mb={'10px'}
                        rightSection={<IconDownload size={20} />}
                    >
                        Descargar Tabla
                    </Button>
                </CsvDownloader>
                <Table
                    style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead>
                        <tr>
                            <th>TURNO</th>
                            <th>SALA</th>
                            <th>UNIDAD</th>
                            <th>ENFERMERO</th>
                            <th>PACIENTE</th>
                            <th>AV</th>
                            <th># CASO</th>
                            <th>ID CUL.</th>
                            <th>TIPO</th>
                            <th># CULTIVO</th>
                            <th>RESULTADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cultivosData.map((cultivo, index) => (
                            <tr key={index}>
                                <td>{cultivo.casos.cat_turnos.nombre_turno}</td>
                                <td>{cultivo.casos.cat_salas.nombre_sala}</td>
                                <td>{cultivo.casos.cat_unidades.nombre_unidad}</td>
                                <td>{cultivo.casos.cat_enfermeros.nombre_enfermero}</td>

                                <td>{cultivo.casos.cat_pacientes.nombre_paciente}</td>
                                <td>{cultivo.casos.cat_tiposav.clasificacion_tipoav}</td>

                                <td>{cultivo.casos.numero_caso}</td>
                                <td>{cultivo.id_cultivo}</td>
                                <td>{cultivo.cat_tiposcultivos.nombre_tipocultivo}</td>
                                <td>{cultivo.numero_cultivo}</td>
                                <td>{cultivo.cat_resultados_cultivos.nombre_resultado_cultivo}</td>
                                <td>
                                    <Badge
                                        color={
                                            cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'NEGATIVO'
                                                ? 'green'
                                                : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'POSITIVO'
                                                    ? 'red'
                                                    : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'EN PROCESO'
                                                        ? 'orange'
                                                        : 'gray' // Si no coincide con ninguna clasificación
                                        }
                                    >
                                        {cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo || 'Sin clasificación'}
                                    </Badge>                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
        </>
    );
};

export default Tabla2;
