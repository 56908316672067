import React, { useEffect, useState } from 'react';
import { Fieldset, Stack, TextInput, Group, Button, NativeSelect, MultiSelect, Tabs, rem, Text, Divider, Modal, Checkbox, List, Accordion, Badge } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import CultivosForm from './CultivosForm';
import PacienteForm from './PacienteForm';
import { IconUserFilled, IconPlus, IconClipboardText, IconMicroscope, IconSearch, IconUser, IconInfoCircle } from '@tabler/icons-react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";




function CasosForm({ formMode, setFormMode, setShowForm, transactionData, getData, user, puedeEliminarNota, puedeModificarNota, puedeRegistrarNota, puedeModificarTratamiento, puedeEliminarTratamiento, puedeRegistrarTratamiento, puedeModificarCultivo, puedeEliminarCultivo, puedeRegistrarCultivo }) {
    const dispatch = useDispatch();

    const [casoInfo, setCasoInfo] = useState({
        id_caso: "",
        numero_caso: "",
        fecha_caso_inicio: null,
        fecha_caso_fin: null,
        id_paciente: "",
        id_enfermero: "",
        nombre_enfermero: "",
        id_etapa_caso: "",
        id_unidad: "",
        id_tipoav: "",
        id_procedencia: "",
        id_sala: "",
        fecha_hemodialisis_previa: null,
        id_turno: "",
        fiebre: false,
        temperatura: '',
        fecha_inicio_fiebre: null, // Inicializa como cadena vacía
        id_uso: "",
        fecha_inicio_sintomas: "",
        id_maquina: "",
        id_conclusion: "",
    });

    // Para iconos de las Tabs
    const iconStyle = { width: rem(25), height: rem(25) };
    const iconBuscador = <IconSearch style={{ width: rem(16), height: rem(16) }} />;



    // Este useEffect es porque en CatalogosPoliza ya tomo el valor de ids de todos los catalogos, entonces
    // este codigo evita que lo ponga en "" y me marque un warning de que se cambia un valor definido a un ""
    useEffect(() => {
        if (!casoInfo.id_caso) {
            setCasoInfo({
                id_caso: "",
                numero_caso: "",
                fecha_caso_inicio: null,
                fecha_caso_fin: null,
                id_paciente: "",
                id_enfermero: "",
                nombre_enfermero: "",
                sintomas: [],
                id_etapa_caso: "",
                id_unidad: "",
                id_tipoav: "",
                id_procedencia: "",
                id_sala: "",
                fecha_hemodialisis_previa: null,
                id_turno: "",
                fiebre: false,
                temperatura: '',
                fecha_inicio_fiebre: null, // Inicializa como cadena vacía
                id_uso: "",
                fecha_inicio_sintomas: "",
                id_maquina: "",
                id_conclusion: "",

            });
        }
    }, [casoInfo.id_caso]);

    const [sintomas, setSintomas] = useState([]);
    const [selectedSintomas, setSelectedSintomas] = useState([]);

    const [signos, setSignos] = useState([]);
    const [selectedSignos, setSelectedSignos] = useState([]);

    const [parches, setParches] = useState([]);
    const [selectedParches, setSelectedParches] = useState([]);

    const [maquinas, setMaquinas] = useState([]);
    const [selectedMaquina, setSelectedMaquina] = useState("");

    const [pacientes, setPacientes] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState("");
    const [pacienteFilter, setPacienteFilter] = useState("");
    const [nombrePaciente, setNombrePaciente] = useState(""); //para mostrar en Tab cultivos
    const [showPacienteForm, setShowPacienteForm] = useState(false); // para agregar un paciente nuevo

    const [enfermeros, setEnfermeros] = useState([]);
    const [selectedEnfermero, setSelectedEnfermero] = useState("");
    const [enfermeroFilter, setEnfermeroFilter] = useState("");
    const [nombreEnfermero, setNombreEnfermero] = useState(""); //para mostrar en Tab cultivos

    const [etapas, setEtapas] = useState([]);
    const [selectedEtapa, setSelectedEtapa] = useState("");

    const [unidad, setUnidades] = useState([]);
    const [selectedUnidad, setSelectedUnidad] = useState("")


    const [casoGuardado, setCasoGuardado] = useState(false);
    const [activeTab, setActiveTab] = useState('caso');
    const [tieneCultivos, setTieneCultivos] = useState(false);

    const [pacienteDetails, setPacienteDetails] = useState({
        nombre_paciente: "",
        fecha_nacimiento: "",

    });

    const [tiposav, setTiposav] = useState([]);
    const [selectedTipoav, setSelectedTipoav] = useState("");
    const [nombreTipoav, setNombreTipoav] = useState(""); //para mostrar en Tab cultivos


    const [procedencias, setProcedencias] = useState([]);
    const [selectedProcedencia, setSelectedProcedencia] = useState("");

    const [salas, setSalas] = useState([]);
    const [selectedSala, setSelectedSala] = useState("");

    const [turnos, setTurnos] = useState([]);
    const [selectedTurno, setSelectedTurno] = useState("");

    const [usos, setUsos] = useState([]);
    const [selectedUso, setSelectedUso] = useState("");

    const [secreciones, setSecreciones] = useState([]);
    const [selectedSecreciones, setSelectedSecreciones] = useState([]);

    const [conclusiones, setConclusiones] = useState([]);
    const [selectedConclusion, setSelectedConclusion] = useState("");


    useEffect(() => {
        async function fetchUltimoIdCaso() {
            const { data, error } = await supabase
                .from('casos')
                .select('id_caso')
                .order('id_caso', { ascending: false })
                .limit(1);

            if (error) {
                console.error('Error al obtener el último id_caso:', error.message);
            } else if (data.length > 0) {
                const nuevoNumeroCaso = data[0].id_caso + 1;
                setCasoInfo(prevUser => ({
                    ...prevUser,
                    numero_caso: nuevoNumeroCaso
                }));
            }
        }

        if (formMode === 'add') {
            fetchUltimoIdCaso();
        }
    }, [formMode]);


    useEffect(() => {
        if (formMode === 'edit' && transactionData.id_caso) {
            setCasoGuardado(true);
            verificarCultivos(transactionData.id_caso);
        }
    }, [formMode, transactionData]);

    const verificarCultivos = async (id_caso) => {
        try {
            const { data, error } = await supabase
                .from('cultivos')
                .select('id_cultivo')
                .eq('id_caso', id_caso)
                .limit(1);

            if (error) throw error;

            setTieneCultivos(data.length > 0);
        } catch (error) {
            console.error('Error al verificar cultivos:', error.message);
        }
    };

    const fetchPacientes = async () => {
        try {
            const { data: pacientes } = await supabase
                .from('cat_pacientes')
                .select('id_paciente, nombre_paciente')
                .order('nombre_paciente', { ascending: true });

            setPacientes(pacientes || []);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };


    useEffect(() => {
        async function fetchSintomas() {
            try {
                const { data: sintomas } = await supabase
                    .from('cat_sintomas_intradialisis')
                    .select('id_sintoma_intradialisis, nombre_sintoma_intradialisis')
                    .order('nombre_sintoma_intradialisis', { ascending: true });

                setSintomas(sintomas || []);
                //console.log('Sintomas cargados:', sintomas);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchSignos() {
            try {
                const { data: signos } = await supabase
                    .from('cat_signos')
                    .select('id_signo, nombre_signo')
                    .order('nombre_signo', { ascending: true });

                setSignos(signos || []);
                //console.log('signos cargados:', signos);

            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchParches() {
            try {
                const { data: parches } = await supabase
                    .from('cat_parches')
                    .select('id_parche, nombre_parche')
                    .order('nombre_parche', { ascending: true });

                setParches(parches || []);
                //console.log('parches cargados:', parches);

            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchEnfermeros() {
            try {
                const { data: enfermeros } = await supabase
                    .from('cat_enfermeros')
                    .select('id_enfermero, nombre_enfermero')
                    .order('nombre_enfermero', { ascending: true });

                setEnfermeros(enfermeros || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchEtapas() {
            try {
                const { data: etapas } = await supabase
                    .from('cat_etapas_casos')
                    .select('id_etapa_caso, nombre_etapa_caso')
                    .order('nombre_etapa_caso', { ascending: true });

                setEtapas(etapas || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchUnidades() {
            try {
                const { data: unidades } = await supabase
                    .from('cat_unidades')
                    .select('id_unidad, nombre_unidad')
                    .order('nombre_unidad', { ascending: true });

                setUnidades(unidades || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchTiposav() {
            try {
                const { data: tiposav } = await supabase
                    .from('cat_tiposav')
                    .select('id_tipoav, nombre_tipoav')
                    .order('nombre_tipoav', { ascending: true });

                setTiposav(tiposav || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchProcedencias() {
            try {
                const { data: procedencias } = await supabase
                    .from('cat_procedencias')
                    .select('id_procedencia, nombre_procedencia')
                    .order('nombre_procedencia', { ascending: true });

                setProcedencias(procedencias || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchSalas() {
            try {
                const { data: salas } = await supabase
                    .from('cat_salas')
                    .select('id_sala, nombre_sala')
                    .order('nombre_sala', { ascending: true });

                setSalas(salas || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchTurnos() {
            try {
                const { data: turnos } = await supabase
                    .from('cat_turnos')
                    .select('id_turno, nombre_turno')
                    .order('nombre_turno', { ascending: true });

                setTurnos(turnos || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchUsos() {
            try {
                const { data: usos } = await supabase
                    .from('cat_usos')
                    .select('id_uso, nombre_uso')
                    .order('id_uso', { ascending: true });

                setUsos(usos || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchSecreciones() {
            try {
                const { data: secreciones } = await supabase
                    .from('cat_secreciones')
                    .select('id_secrecion, nombre_secrecion')
                    .order('id_secrecion', { ascending: true });

                setSecreciones(secreciones || []);
                console.log('secreciones...:', secreciones);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchMaquinas() {
            try {
                const { data: maquinas } = await supabase
                    .from('cat_maquinas')
                    .select('id_maquina, nombre_maquina')
                    .order('nombre_maquina', { ascending: true });

                setMaquinas(maquinas || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchConclusiones() {
            try {
                const { data: conclusiones } = await supabase
                    .from('cat_conclusiones')
                    .select('id_conclusion, nombre_conclusion')
                    .order('nombre_conclusion', { ascending: true });

                setConclusiones(conclusiones || []);
                //console.log('Enfer cargados:', pacientes);

                //console.log('Tipos de contratantes:', tipos);
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        fetchSintomas();
        fetchSignos();
        fetchParches();
        fetchPacientes();
        fetchEnfermeros();
        fetchEtapas();
        fetchUnidades();
        fetchTiposav();
        fetchProcedencias();
        fetchSalas();
        fetchTurnos();
        fetchUsos();
        fetchSecreciones();
        fetchMaquinas();
        fetchConclusiones();

    }, []);


    /*
        useEffect(() => {
            console.log('selectedPaciente:', selectedPaciente);
            console.log('selectedEnfermero:', selectedEnfermero);
        }, [selectedPaciente, selectedEnfermero]);
    
        useEffect(() => {
            console.log('pacientes:', pacientes);
            console.log('enfermeros:', enfermeros);
        }, [pacientes, enfermeros]);
    */


    useEffect(() => {
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            console.log("transactionData:", transactionData); // Añadir esto para ver los datos

            setCasoInfo(prevUser => ({
                ...prevUser,
                id_caso: transactionData.id_caso || "",
                numero_caso: transactionData.numero_caso || "",
                fecha_caso_inicio: transactionData.fecha_caso_inicio || null,
                fecha_caso_fin: transactionData.fecha_caso_fin || null,
                fecha_hemodialisis_previa: transactionData.fecha_hemodialisis_previa || null,
                fiebre: transactionData.fiebre || false, // Valor de fiebre
                temperatura: transactionData.temperatura || '', // Valor de temperatura
                fecha_inicio_fiebre: transactionData.fecha_inicio_fiebre || null,
                fecha_inicio_sintomas: transactionData.fecha_inicio_sintomas || ""


            }));


            // Manejar los síntomas seleccionados
            if (transactionData.casos_sintomas && Array.isArray(transactionData.casos_sintomas)) {
                const selectedSintomasIds = transactionData.casos_sintomas.map(sintoma =>
                    sintoma.id_sintoma_intradialisis.toString()
                );
                setSelectedSintomas(selectedSintomasIds);
            } else {
                setSelectedSintomas([]);
            }

            // Manejar los signos seleccionados
            if (transactionData.casos_signos && Array.isArray(transactionData.casos_signos)) {
                const selectedSignosIds = transactionData.casos_signos.map(signo =>
                    signo.id_signo.toString()
                );
                setSelectedSignos(selectedSignosIds);
            } else {
                setSelectedSignos([]);
            }

            // Manejar las condiciones de parches
            if (transactionData.casos_parches && Array.isArray(transactionData.casos_parches)) {
                const selectedParchesIds = transactionData.casos_parches.map(parche =>
                    parche.id_parche.toString()
                );
                setSelectedParches(selectedParchesIds);
            } else {
                setSelectedParches([]);
            }

            // Manejar las secreciones
            if (transactionData.casos_secreciones && Array.isArray(transactionData.casos_secreciones)) {
                const selectedSecrecionesIds = transactionData.casos_secreciones.map(secrecion =>
                    secrecion.id_secrecion.toString()
                );
                setSelectedSecreciones(selectedSecrecionesIds);
            } else {
                setSelectedSecreciones([]);
            }

            // Manejar los otros ids
            setSelectedPaciente(transactionData.id_paciente || "");
            setSelectedEnfermero(transactionData.id_enfermero || "");
            setSelectedEtapa(transactionData.id_etapa_caso || "");
            setSelectedUnidad(transactionData.id_unidad || "");
            setSelectedTipoav(transactionData.id_tipoav || "");
            setSelectedProcedencia(transactionData.id_procedencia || "");
            setSelectedSala(transactionData.id_sala || "");
            setSelectedTurno(transactionData.id_turno || "");
            setSelectedUso(transactionData.id_uso || "");
            setSelectedMaquina(transactionData.id_maquina || "");
            setSelectedConclusion(transactionData.id_conclusion);
        }
    }, [formMode, transactionData]);

    // Para guardar el nombre del Paciente y mostrarlo en Tab de Cultivos
    useEffect(() => {
        if (selectedPaciente) {
            const paciente = pacientes.find(p => p.id_paciente === selectedPaciente);
            if (paciente) {
                setNombrePaciente(paciente.nombre_paciente);
            }
        }
    }, [selectedPaciente, pacientes]);

    // Para guardar el nombre del Enfermero y mostrarlo en Tab de Cultivos
    useEffect(() => {
        if (selectedEnfermero) {
            const enfermero = enfermeros.find(p => p.id_enfermero === selectedEnfermero);
            if (enfermero) {
                setNombreEnfermero(enfermero.nombre_enfermero);
            }
        }
    }, [selectedEnfermero, enfermeros]);

    // Para guardar el nombre del Tipo acceso vascular y mostrarlo en Tab de Cultivos
    useEffect(() => {
        if (selectedTipoav) {
            const tipoav = tiposav.find(p => p.id_tipoav === selectedTipoav);
            if (tipoav) {
                setNombreTipoav(tipoav.nombre_tipoav);
            }
        }
    }, [selectedTipoav, tiposav]);



    useEffect(() => {
        async function fetchFilteredPacientes() {
            const { data: filteredPacientes } = await supabase
                .from('cat_pacientes')
                .select('id_paciente, nombre_paciente')
                .ilike('nombre_paciente', `%${pacienteFilter}%`)
                .order('nombre_paciente', { ascending: true });
            setPacientes(filteredPacientes || []);
        }

        if (pacienteFilter) {
            fetchFilteredPacientes();
        } else {
            async function fetchAllPacientes() {
                const { data: pacientes } = await supabase.from('cat_pacientes').select('id_paciente, nombre_paciente').order('nombre_paciente', { ascending: true });
                setPacientes(pacientes || []);
            }
            fetchAllPacientes();
        }
    }, [pacienteFilter]);

    const handlePacienteFilterChange = (event) => {
        setPacienteFilter(event.target.value);
    };

    useEffect(() => {
        async function fetchFilteredEnfermeros() {
            const { data: filteredEnfermeros } = await supabase
                .from('cat_enfermeros')
                .select('id_enfermero, nombre_enfermero')
                .ilike('nombre_enfermero', `%${enfermeroFilter}%`)
                .order('nombre_enfermero', { ascending: true });
            setEnfermeros(filteredEnfermeros || []);
        }

        if (enfermeroFilter) {
            fetchFilteredEnfermeros();
        } else {
            async function fetchAllEnfermeros() {
                const { data: enfermeros } = await supabase.from('cat_enfermeros').select('id_enfermero, nombre_enfermero').order('nombre_enfermero', { ascending: true });
                setEnfermeros(enfermeros || []);
            }
            fetchAllEnfermeros();
        }
    }, [enfermeroFilter]);

    const handleEnfermeroFilterChange = (event) => {
        setEnfermeroFilter(event.target.value);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setCasoInfo((prevFormData) => {
            let updatedValue = value;

            // Manejo especial para campos de fecha
            if (name === 'fecha_caso_inicio' || name === 'fecha_caso_fin' || name === 'fecha_hemodialisis_previa' || name === 'fecha_inicio_sintomas') {
                updatedValue = value || null; // Si el valor está vacío, establecerlo como null
            }

            let updatedFormData = {
                ...prevFormData,
                [name]: updatedValue,
            };

            // Recalcular la etapa si cambian las fechas
            if (name === 'fecha_caso_inicio' || name === 'fecha_caso_fin') {
                const { id_etapa_caso } = calculateEtapa(updatedFormData);
                setSelectedEtapa(id_etapa_caso);
            }

            // Si `fecha_caso_fin` se establece en null, también establecer `selectedConclusion` a null
            if (name === 'fecha_caso_fin' && updatedValue === null) {
                setSelectedConclusion(null);
            }


            return updatedFormData;
        });
    };



    const handleChangeMultiSelect = (value) => {
        setSelectedSintomas(value);
    };

    const handleChangeMultiSelect2 = (value) => {
        setSelectedSignos(value);
    };

    const handleChangeMultiSelect3 = (value) => {
        setSelectedParches(value);
    };

    const handleChangeMultiSelect4 = (value) => {
        setSelectedSecreciones(value);
    };


    const handlePacienteChange = (event) => {
        setSelectedPaciente(event.target.value);
    };

    const handleEnfermeroChange = (event) => {
        setSelectedEnfermero(event.target.value);
    };

    const handleEtapaChange = (event) => {
        setSelectedEtapa(event.target.value);
    };

    const handleUnidadChange = (event) => {
        setSelectedUnidad(event.target.value);
    };

    const handleTipoavChange = (event) => {
        setSelectedTipoav(event.target.value);
    };

    const handleProcedenciaChange = (event) => {
        setSelectedProcedencia(event.target.value);
    };

    const handleSalaChange = (event) => {
        setSelectedSala(event.target.value);
    };

    const handleTurnoChange = (event) => {
        setSelectedTurno(event.target.value);
    };

    const handleMaquinaChange = (event) => {
        setSelectedMaquina(event.target.value);
    };

    const handleUsoChange = (event) => {
        setSelectedUso(event.target.value);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Deshabilitar el botón al hacer submit en cualquier modo
        setIsSubmitting(true);



        try {
            dispatch(ShowLoading());

            let casoId;

            if (formMode === 'add') {
                const newCaso = {
                    numero_caso: casoInfo.numero_caso,
                    fecha_caso_inicio: casoInfo.fecha_caso_inicio,
                    fecha_caso_fin: casoInfo.fecha_caso_fin || null,
                    id_enfermero: selectedEnfermero,
                    id_paciente: selectedPaciente,
                    id_etapa_caso: selectedEtapa,
                    id_unidad: selectedUnidad,
                    id_tipoav: selectedTipoav,
                    id_procedencia: selectedProcedencia,
                    id_sala: selectedSala,
                    fecha_hemodialisis_previa: casoInfo.fecha_hemodialisis_previa || null,
                    id_turno: selectedTurno,
                    fiebre: casoInfo.fiebre,
                    temperatura: casoInfo.fiebre ? parseFloat(casoInfo.temperatura) : null, // Si fiebre está marcado, guarda la temperatura, de lo contrario null
                    fecha_inicio_fiebre: casoInfo.fecha_inicio_fiebre || null,
                    id_uso: selectedUso,
                    fecha_inicio_sintomas: casoInfo.fecha_inicio_sintomas || null,
                    id_maquina: selectedMaquina,
                    id_conclusion: selectedConclusion || null,
                };

                // Imprimir en consola lo que se enviará en el modo "add"
                console.log("Datos enviados en el modo 'add':", newCaso);

                const { data, error } = await supabase.from('casos').insert([newCaso]).select();

                if (error) throw error;
                casoId = data[0].id_caso;
            } else if (formMode === 'edit') {
                const updatedCaso = {
                    numero_caso: casoInfo.numero_caso,
                    fecha_caso_inicio: casoInfo.fecha_caso_inicio,
                    fecha_caso_fin: casoInfo.fecha_caso_fin === '' ? null : casoInfo.fecha_caso_fin,
                    id_enfermero: selectedEnfermero,
                    id_paciente: selectedPaciente,
                    id_etapa_caso: selectedEtapa,
                    id_unidad: selectedUnidad,
                    id_tipoav: selectedTipoav,
                    id_procedencia: selectedProcedencia,
                    id_sala: selectedSala,
                    fecha_hemodialisis_previa: casoInfo.fecha_hemodialisis_previa === '' ? null : casoInfo.fecha_hemodialisis_previa,
                    id_turno: selectedTurno,
                    fiebre: casoInfo.fiebre,
                    temperatura: casoInfo.fiebre ? parseFloat(casoInfo.temperatura) : null, // Si fiebre está marcado, guarda la temperatura, de lo contrario null
                    fecha_inicio_fiebre: casoInfo.fecha_inicio_fiebre || null,
                    id_uso: selectedUso,
                    fecha_inicio_sintomas: casoInfo.fecha_inicio_sintomas || null,
                    id_maquina: selectedMaquina,
                    id_conclusion: selectedConclusion,


                };

                // Imprimir en consola lo que se enviará en el modo "edit"
                console.log("Datos enviados en el modo 'edit':", updatedCaso);

                await supabase.from('casos').update(updatedCaso).eq('id_caso', transactionData.id_caso);

                casoId = transactionData.id_caso;
            }

            // Eliminar y volver a insertar los síntomas asociados
            await supabase.from('casos_sintomas').delete().eq('id_caso', casoId);
            const insertData = selectedSintomas.map((sintomaId) => ({ id_caso: casoId, id_sintoma_intradialisis: parseInt(sintomaId) }));

            // Imprimir los síntomas que se insertarán
            console.log("Síntomas enviados:", insertData);

            await supabase.from('casos_sintomas').insert(insertData);

            // Eliminar y volver a insertar los signos asociados
            await supabase.from('casos_signos').delete().eq('id_caso', casoId);
            const insertData2 = selectedSignos.map((signoId) => ({ id_caso: casoId, id_signo: parseInt(signoId) }));

            // Imprimir los síntomas que se insertarán
            console.log("Signos enviados:", insertData2);

            await supabase.from('casos_signos').insert(insertData2);

            // Eliminar y volver a insertar los parches asociados
            await supabase.from('casos_parches').delete().eq('id_caso', casoId);
            const insertData3 = selectedParches.map((parcheId) => ({ id_caso: casoId, id_parche: parseInt(parcheId) }));

            // Imprimir los parches que se insertarán
            console.log("Parches enviados:", insertData3);
            await supabase.from('casos_parches').insert(insertData3);


            // Eliminar y volver a insertar las secreciones asociadas
            await supabase.from('casos_secreciones').delete().eq('id_caso', casoId);
            const insertData4 = selectedSecreciones.map((secrecionId) => ({ id_caso: casoId, id_secrecion: parseInt(secrecionId) }));
            await supabase.from('casos_secreciones').insert(insertData4);


            showNotification({
                title: formMode === 'add' ? 'Caso agregado' : 'Caso actualizado',
                message: formMode === 'add' ? 'El caso ha sido agregado con éxito' : 'El caso ha sido actualizado con éxito',
                color: 'green',
            });

            setCasoGuardado(true);
            setCasoInfo(prevUser => ({ ...prevUser, id_caso: casoId }));
            verificarCultivos(casoId);
            getData();
            dispatch(HideLoading());

        } catch (error) {
            console.error('Error al agregar/actualizar caso:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar caso.',
                color: 'red',
            });
        } finally {
            // Rehabilitar el botón al terminar
            //setIsSubmitting(false);
        }


    };




    // Para obtener la fecha de nacimiento del paciente Seleccionado
    useEffect(() => {
        const fetchPacienteDetails = async () => {
            if (selectedPaciente) {
                try {
                    // Primera consulta para obtener detalles del paciente
                    const { data: pacienteData, error: pacienteError } = await supabase
                        .from('cat_pacientes')
                        .select(`
                        nombre_paciente,
                        fecha_nacimiento
                    `)
                        .eq('id_paciente', selectedPaciente)
                        .single();

                    if (pacienteError) throw pacienteError;

                    // Segunda consulta para obtener el número de casos y las fechas de los casos
                    const { data: casosData, error: casosError } = await supabase
                        .from('casos')
                        .select(`
                        fecha_caso_inicio,
                        fecha_caso_fin,
                        numero_caso
                        
                    `)
                        .eq('id_paciente', selectedPaciente);

                    if (casosError) throw casosError;

                    // Guardar los detalles del paciente y el número de casos en el estado
                    setPacienteDetails({
                        nombre_paciente: pacienteData.nombre_paciente || "",
                        fecha_nacimiento: pacienteData.fecha_nacimiento || "",
                        total_casos: casosData.length, // Número total de casos
                        fechas_casos: casosData.map(caso => ({
                            fecha_inicio: caso.fecha_caso_inicio,
                            fecha_fin: caso.fecha_caso_fin,
                            numero_caso: caso.numero_caso,
                        })) // Lista de fechas de los casos
                    });
                } catch (error) {
                    console.error('Error al obtener los detalles del paciente:', error.message);
                }
            } else {
                setPacienteDetails({
                    nombre_paciente: "",
                    fecha_nacimiento: "",
                    total_casos: 0,
                    fechas_casos: []
                });
            }
        };

        fetchPacienteDetails();
    }, [selectedPaciente]);


    // para que se actualice el id_estado_caso y nombre_estatus en automático
    const calculateEtapa = (data2) => {
        let id_etapa_caso = '';

        if (data2.fecha_caso_fin) {
            // Si hay fecha de fin, el estatus es 'Concluído'
            id_etapa_caso = '2'; // Concluído
        } else if (data2.fecha_caso_inicio) {
            // Si hay fecha de inicio pero no hay fecha de fin, el estatus es en Proceso
            id_etapa_caso = '1'; // En proceso
        } else {
            // Si no hay fecha de inicio, asignar un estatus predeterminado
            id_etapa_caso = ''; // O asignar un valor predeterminado si aplica
        }

        return { id_etapa_caso };
    };

    useEffect(() => {
        if (formMode === 'edit' && transactionData) {
            const { id_etapa_caso } = calculateEtapa(transactionData);

            // Actualiza el estado del formulario
            setSelectedEtapa(id_etapa_caso);

            // Imprimir el id_estatus y otros valores para depuración
            console.log('Selected Estatus ID:', id_etapa_caso);
        } else if (formMode === 'add') {
            // Modo adición no requiere cálculo de estatus
            setSelectedEtapa('');

            // Imprimir el estado en modo adición
            console.log('Form Mode Add: Estatus ID no calculado');
        }
    }, [formMode, transactionData]);

    // para deshabiltiar el botón una vez que se agrega el caso
    const [isSubmitting, setIsSubmitting] = useState(false);

    // para fiebre
    const handleCheckboxChange = (event) => {
        const isChecked = event.currentTarget.checked;
        setCasoInfo((prevUser) => ({
            ...prevUser,
            fiebre: isChecked,
            temperatura: isChecked ? prevUser.temperatura : '', // Si se deschequea, limpiar el campo de temperatura
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCasoInfo((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    // Maneja el cambio en el campo id_conclusion
    const handleConclusionChange = (event) => {
        setSelectedConclusion(event.target.value);
    };





    return (
        <div style={{ backgroundColor: '#F0F0F0' }}>
            <Tabs defaultValue="caso" color="#00497A" >
                <Tabs.List style={{ marginTop: '20px' }}>
                    <Tabs.Tab value="caso" style={{ fontSize: '16px' }} leftSection={<IconClipboardText style={iconStyle} />}>Información del Caso</Tabs.Tab>
                    <Tabs.Tab value="cultivos" style={{ fontSize: '16px' }} leftSection={<IconMicroscope style={iconStyle} />} disabled={!casoGuardado && !tieneCultivos}>Cultivos, Tratamientos y Evolución</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="caso">
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px', paddingBottom: '10px' }}>
                        <form onSubmit={handleSubmit}>
                            <Group
                                style={{
                                    padding: '10px',
                                    backgroundColor: 'white',
                                    border: '1px solid #dbdbdb',
                                    display: 'inline-block', // Ajusta el ancho al contenido
                                }}
                            >
                                <Stack>
                                    <Group justify="space-between" mb={10}>
                                        <Text size="lg" weight={700}>Notificación de Infecciones:</Text>
                                    </Group>

                                    <Divider />
                                    <Group>
                                        <TextInput
                                            disabled
                                            name="id_caso"
                                            label="ID"
                                            onChange={handleChange}
                                            className='caja-chica'
                                            value={casoInfo.id_caso}

                                        />
                                        <TextInput
                                            name="numero_caso"
                                            label="Numero Caso"
                                            className='caja-chica'
                                            required
                                            onChange={handleChange}
                                            value={casoInfo.numero_caso}
                                            placeholder="Número caso"
                                            disabled

                                        />
                                        {/* Mostrar nombre del paciente seleccionado o previamente guardado */}
                                        {selectedPaciente && (
                                            <div>
                                                {/* Ícono de paciente y nombre del paciente */}
                                                <Group align="center" spacing="xs">
                                                    <IconUser size={20} />  {/* Ícono del paciente a la izquierda */}
                                                    <Text size="md" weight={500}>
                                                        {pacienteDetails.nombre_paciente}

                                                    </Text>
                                                    <Accordion>
                                                        <Accordion.Item value="total-casos">
                                                            <Accordion.Control>
                                                                Total de casos: {pacienteDetails.total_casos}
                                                            </Accordion.Control>
                                                            <Accordion.Panel>
                                                                <List withPadding>
                                                                    {pacienteDetails.fechas_casos.map((caso, index) => (
                                                                        <List.Item key={index}>
                                                                            <span style={{ fontSize: '0.875rem' /* equivalente a 'sm' en Mantine */ }}>
                                                                                {index + 1}: <Badge color="blue">Caso #{caso.numero_caso}</Badge>{' '}
                                                                                {moment(caso.fecha_inicio).format('DD-MM-YYYY')} -
                                                                                {caso.fecha_fin ? moment(caso.fecha_fin).format('DD-MM-YYYY') : 'En curso'}
                                                                            </span>
                                                                        </List.Item>
                                                                    ))}
                                                                </List>
                                                            </Accordion.Panel>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Group>

                                                {/* Ícono de información y nombre del tipo de acceso vascular (nombreTipoav) */}
                                                <Group align="center" spacing="xs" mt={5}>  {/* mt={5} para un margen superior pequeño */}
                                                    <IconInfoCircle size={20} />  {/* Ícono de información */}
                                                    <Text size="sm" weight={300}>
                                                        {nombreTipoav}  {/* Aquí se muestra el nombre del tipo de acceso vascular */}
                                                    </Text>
                                                </Group>
                                            </div>
                                        )}
                                    </Group>

                                    <div className="fieldset-container">
                                        <div


                                        >

                                            <Fieldset
                                                legend="INFORMACIÓN DEL PACIENTE"
                                                variant="filled"
                                                radius="md"
                                                mb={'15px'}
                                            >
                                                <Group align="flex-end">
                                                    <TextInput
                                                        label="Buscar paciente"
                                                        value={pacienteFilter}
                                                        onChange={handlePacienteFilterChange}
                                                        className='caja-chica'
                                                        leftSection={iconBuscador}
                                                    //style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible
                                                    />
                                                    <NativeSelect
                                                        name="id_paciente"
                                                        label="Nombre del paciente"
                                                        description="1ER APELLIDO - 2DO APELLIDO - NOMBRES"
                                                        className='caja-grande'
                                                        value={selectedPaciente}
                                                        onChange={handlePacienteChange}
                                                        placeholder=""
                                                        required
                                                        disabled={formMode === 'edit'}  // Deshabilitar si formMode es 'edit'
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible
                                                    >
                                                        <option value="">Seleccione un paciente</option>
                                                        {pacientes.map((paciente) => (
                                                            <option key={paciente.id_paciente} value={String(paciente.id_paciente)}>
                                                                {paciente.nombre_paciente}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <Button
                                                        onClick={() => setShowPacienteForm(true)}
                                                        style={{
                                                            padding: '8px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            gap: '4px',
                                                            height: '36px',
                                                            marginTop: 'auto',
                                                        }}
                                                        disabled={formMode === 'edit'} // Deshabilitar si formMode es 'edit'
                                                    >
                                                        <IconPlus size={18} />
                                                        <IconUserFilled size={18} />
                                                    </Button>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'flex-start',
                                                        //height: '100%'
                                                    }}>
                                                        <Text
                                                            size='sm'
                                                        >
                                                            Fecha nacimiento:
                                                        </Text>
                                                        <Text
                                                            size='sm'
                                                        >
                                                            {moment(pacienteDetails.fecha_nacimiento).isValid()
                                                                ? moment(pacienteDetails.fecha_nacimiento).format('DD-MM-YYYY')
                                                                : " "}
                                                        </Text>
                                                    </div>
                                                </Group>
                                            </Fieldset>
                                            <Fieldset legend="DATOS DEL CASO DE INFECCIÓN" variant="filled" radius="md">
                                                <Group align="flex-end">

                                                    <TextInput
                                                        name="fecha_caso_inicio"
                                                        label="Fecha Inicio Caso"
                                                        className='caja-chica'
                                                        required
                                                        onChange={handleChange}
                                                        value={casoInfo.fecha_caso_inicio || ''}
                                                        type="date"
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    />
                                                    <TextInput
                                                        name="fecha_caso_fin"
                                                        label="Fecha Fin Caso"
                                                        className='caja-chica'
                                                        onChange={handleChange}
                                                        value={casoInfo.fecha_caso_fin || ''}
                                                        type="date"
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    />

                                                    <NativeSelect
                                                        name="id_etapa_caso"
                                                        label="Etapa del caso"
                                                        className='caja-chica'
                                                        value={selectedEtapa || ""}
                                                        disabled
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >

                                                        {formMode === 'add' && (
                                                            <option value="">Seleccione Estatus</option>
                                                        )}
                                                        {etapas.map((estatus) => (
                                                            <option key={estatus.id_etapa_caso} value={estatus.id_etapa_caso}>
                                                                {`${estatus.id_etapa_caso} - ${estatus.nombre_etapa_caso}`}
                                                            </option>
                                                        ))}

                                                    </NativeSelect>

                                                    <NativeSelect
                                                        name="id_conclusion"
                                                        label="Conclusión"
                                                        className='caja-chica'
                                                        value={selectedConclusion}
                                                        onChange={handleConclusionChange}
                                                        placeholder=""
                                                        disabled={selectedEtapa !== '2'}
                                                        required={selectedEtapa === '2'}
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione conclusión</option>
                                                        {conclusiones.map((u) => (
                                                            <option key={u.id_conclusion} value={String(u.id_conclusion)}>
                                                                {u.nombre_conclusion}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </Group>
                                                <Group align="flex-end">


                                                    <NativeSelect
                                                        name="id_unidad"
                                                        label="Unidad"
                                                        className='caja-chica'
                                                        value={selectedUnidad}
                                                        onChange={handleUnidadChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Unidad</option>
                                                        {unidad.map((u) => (
                                                            <option key={u.id_unidad} value={String(u.id_unidad)}>
                                                                {u.nombre_unidad}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <NativeSelect
                                                        name="id_sala"
                                                        label="Sala"
                                                        className='caja-chica'
                                                        value={selectedSala}
                                                        onChange={handleSalaChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Sala</option>
                                                        {salas.map((u) => (
                                                            <option key={u.id_sala} value={String(u.id_sala)}>
                                                                {u.nombre_sala}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <NativeSelect
                                                        name="id_procedencia"
                                                        label="Procedencia"
                                                        className='caja-chica'
                                                        value={selectedProcedencia}
                                                        onChange={handleProcedenciaChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Procedencia</option>
                                                        {procedencias.map((u) => (
                                                            <option key={u.id_procedencia} value={String(u.id_procedencia)}>
                                                                {u.nombre_procedencia}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <TextInput
                                                        name="fecha_hemodialisis_previa"
                                                        label="F. Hemodiálisis Previa"
                                                        className='caja-chica'
                                                        onChange={handleChange}
                                                        value={casoInfo.fecha_hemodialisis_previa || ''}
                                                        type="date"
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    />
                                                    <NativeSelect
                                                        name="id_turno"
                                                        label="Turno"
                                                        className='caja-chica'
                                                        value={selectedTurno}
                                                        onChange={handleTurnoChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Turno</option>
                                                        {turnos.map((u) => (
                                                            <option key={u.id_turno} value={String(u.id_turno)}>
                                                                {u.nombre_turno}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <NativeSelect
                                                        name="id_maquina"
                                                        label="Máquina"
                                                        className='caja-chica'
                                                        value={selectedMaquina}
                                                        onChange={handleMaquinaChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Maquina</option>
                                                        {maquinas.map((u) => (
                                                            <option key={u.id_maquina} value={String(u.id_maquina)}>
                                                                {u.nombre_maquina}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                    <TextInput
                                                        label="Buscar enfermero"
                                                        value={enfermeroFilter}
                                                        onChange={handleEnfermeroFilterChange}
                                                        className='caja-chica'
                                                        leftSection={iconBuscador}
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible


                                                    />


                                                    <NativeSelect
                                                        name="id_enfermero"
                                                        label="Enfermero"
                                                        className='caja-chica'
                                                        value={selectedEnfermero}
                                                        onChange={handleEnfermeroChange}
                                                        placeholder=""
                                                        required
                                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                    >
                                                        <option value="">Seleccione Enfermero</option>
                                                        {enfermeros.map((ii) => (
                                                            <option key={ii.id_enfermero} value={ii.id_enfermero}>
                                                                {ii.nombre_enfermero}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>

                                                </Group>
                                            </Fieldset>
                                        </div>

                                        <div className="fieldset-container">
                                            <div

                                            >

                                                <Fieldset legend="TIPO DE ACCESO VASCULAR" variant="filled" radius="md">

                                                    <Group align="flex-end">
                                                        <NativeSelect
                                                            name="id_tipoav"
                                                            label="Acceso vascular"
                                                            className='caja-extragrande'
                                                            value={selectedTipoav}
                                                            onChange={handleTipoavChange}
                                                            placeholder=""
                                                            required
                                                            disabled={formMode === 'edit'}  // Deshabilitar si formMode es 'edit'
                                                            style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        >
                                                            <option value="">Seleccione Tipo av</option>
                                                            {tiposav.map((ii) => (
                                                                <option key={ii.id_tipoav} value={ii.id_tipoav}>
                                                                    {ii.nombre_tipoav}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </Group>
                                                </Fieldset>



                                                <Fieldset legend="SÍNTOMAS, PARCHE, FILTRO Y SECRECIÓN" variant="filled" radius="md" mt={'15px'}>
                                                    <Group align="flex-end">

                                                        <MultiSelect
                                                            label="Selecciona sintomas intradiálisis"
                                                            placeholder="Seleccione uno o más síntomas"
                                                            data={sintomas.map(sintoma => ({
                                                                value: sintoma.id_sintoma_intradialisis.toString(),
                                                                label: sintoma.nombre_sintoma_intradialisis || 'Síntoma sin nombre'
                                                            }))}
                                                            value={selectedSintomas}
                                                            onChange={handleChangeMultiSelect}
                                                            className='caja-sintomas'
                                                            searchable
                                                            comboboxProps={{ shadow: 'md', transitionProps: { transition: 'pop', duration: 200 } }}
                                                            hidePickedOptions
                                                        //style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                        <TextInput
                                                            name="fecha_inicio_sintomas"
                                                            label="Fecha Inicio Síntomas"
                                                            description="Llenar solo si aplica"
                                                            className='caja-chica'
                                                            onChange={handleChange}
                                                            value={casoInfo.fecha_inicio_sintomas || ''}
                                                            type="date"
                                                            style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible


                                                        />
                                                        <Checkbox
                                                            label="¿Tiene fiebre?"
                                                            labelPosition="left"
                                                            checked={casoInfo.fiebre}
                                                            onChange={(event) =>
                                                                setCasoInfo(prevUser => ({
                                                                    ...prevUser,
                                                                    fiebre: event.currentTarget.checked,
                                                                    temperatura: event.currentTarget.checked ? casoInfo.temperatura : '', // Borra la temperatura si se deschequea
                                                                    fecha_inicio_fiebre: event.currentTarget.checked ? casoInfo.fecha_inicio_fiebre : null, // Borra fecha inicio fiebre si se deschekea
                                                                }))
                                                            }
                                                        />

                                                        <TextInput
                                                            label="Temperatura (°C)"
                                                            type="number"
                                                            className="caja-chica"
                                                            value={casoInfo.temperatura === null || casoInfo.temperatura === undefined ? '' : casoInfo.temperatura.toString()}
                                                            disabled={!casoInfo.fiebre} // Se deshabilita si no hay fiebre
                                                            onChange={(event) => {
                                                                const value = event.currentTarget.value;
                                                                setCasoInfo((prevUser) => ({
                                                                    ...prevUser,
                                                                    temperatura: value === '' ? null : Number(value)
                                                                }));
                                                            }}
                                                            step="0.1" // Permite incrementos de 0.1
                                                            min="35" // Temperatura mínima razonable
                                                            max="42" // Temperatura máxima razonable
                                                            style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                        <TextInput
                                                            name="fecha_inicio_fiebre"
                                                            label="Fecha Inicio Fiebre"
                                                            className='caja-chica'
                                                            onChange={handleChange}
                                                            value={casoInfo.fecha_inicio_fiebre || ''}
                                                            type="date"
                                                            disabled={!casoInfo.fiebre} // Se deshabilita si no hay fiebre
                                                            required={casoInfo.fiebre} // Solo se requiere si fiebre es true
                                                            style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                        <MultiSelect
                                                            label="Él / la paciente tiene signos de:"
                                                            placeholder="Seleccione uno o más signos"
                                                            data={signos.map(signo => ({
                                                                value: signo.id_signo.toString(),
                                                                label: signo.nombre_signo || 'Signo sin nombre'
                                                            }))}
                                                            value={selectedSignos}
                                                            onChange={handleChangeMultiSelect2}
                                                            className='caja-sintomas'
                                                            searchable
                                                            comboboxProps={{ shadow: 'md', transitionProps: { transition: 'pop', duration: 200 } }}
                                                            hidePickedOptions
                                                        //style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                        <MultiSelect
                                                            label="Condiciones del parche y sitio de inserción"
                                                            placeholder="Seleccione uno o más estados"
                                                            data={parches.map(parche => ({
                                                                value: parche.id_parche.toString(),
                                                                label: parche.nombre_parche || 'Estado de Parche sin nombre'
                                                            }))}
                                                            value={selectedParches}
                                                            onChange={handleChangeMultiSelect3}
                                                            className='caja-sintomas'
                                                            searchable
                                                            comboboxProps={{ shadow: 'md', transitionProps: { transition: 'pop', duration: 200 } }}
                                                            hidePickedOptions
                                                        //style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                        <NativeSelect
                                                            name="id_uso"
                                                            label="# de uso de Filtro"
                                                            className='caja-chica'
                                                            value={selectedUso}
                                                            onChange={handleUsoChange}
                                                            placeholder=""
                                                            required
                                                            style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        >
                                                            <option value="">Seleccione</option>
                                                            {usos.map((u) => (
                                                                <option key={u.id_uso} value={String(u.id_uso)}>
                                                                    {u.nombre_uso}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                        <MultiSelect
                                                            label="Secreción"
                                                            placeholder="Seleccione uno o más tipos"
                                                            data={secreciones.map(secrecion => ({
                                                                value: secrecion.id_secrecion.toString(),
                                                                label: secrecion.nombre_secrecion || 'Secreción sin nombre'
                                                            }))}
                                                            value={selectedSecreciones}
                                                            onChange={handleChangeMultiSelect4}
                                                            className='caja-grande'
                                                            searchable
                                                            comboboxProps={{ shadow: 'md', transitionProps: { transition: 'pop', duration: 200 } }}
                                                            hidePickedOptions
                                                        //style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                                        />
                                                    </Group>
                                                </Fieldset>
                                            </div>

                                        </div>

                                    </div>







                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            mt={15}
                                            className='boton-verde'
                                            type="submit"
                                            disabled={isSubmitting} // Deshabilita en ambos modos cuando se está enviando

                                        >
                                            {formMode === 'add' ? "+ Agregar Caso" : "Editar Caso"}
                                        </Button>
                                    </div>
                                </Stack>
                            </Group>

                        </form>
                    </div>
                </Tabs.Panel >


                <Tabs.Panel value="cultivos">

                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px', padding: '10px', background: 'white', border: '1px solid #dbdbdb' }}>
                        <Group
                            pt={'5px'}
                            pb={'5px'}

                        >
                            <Text size='90%'>
                                Paciente:
                            </Text>
                            <Text
                                size='90%'
                                component="span"
                                style={{
                                    backgroundColor: '#EEEEEE',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                    //border: '1px solid #939393',
                                }}
                            >
                                {nombrePaciente}
                            </Text>
                            <Text size='90%'>
                                Enfermero:
                            </Text>
                            <Text
                                size='90%'
                                component="span"
                                style={{
                                    backgroundColor: '#EEEEEE',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                    //border: '1px solid #939393',
                                }}
                            >
                                {nombreEnfermero}
                            </Text>
                        </Group>

                    </div>
                    {(casoGuardado || tieneCultivos) && (
                        <CultivosForm
                            formMode={formMode}
                            transactionData={transactionData}
                            casoId={casoInfo.id_caso || transactionData.id_caso}
                            getData={getData} // Pasa la función getData aquí
                            user={user}
                            // Pasar los permisos para las notas
                            puedeEliminarNota={puedeEliminarNota}
                            puedeModificarNota={puedeModificarNota}
                            puedeRegistrarNota={puedeRegistrarNota}
                            puedeEliminarTratamiento={puedeEliminarTratamiento}
                            puedeModificarTratamiento={puedeModificarTratamiento}
                            puedeRegistrarTratamiento={puedeRegistrarTratamiento}
                            puedeEliminarCultivo={puedeEliminarCultivo}
                            puedeModificarCultivo={puedeModificarCultivo}
                            puedeRegistrarCultivo={puedeRegistrarCultivo}
                        />
                    )}
                </Tabs.Panel>

            </Tabs >
            <Modal
                opened={showPacienteForm}
                onClose={() => setShowPacienteForm(false)}
                title="Agregar Nuevo Paciente"
                size={'auto'}
                styles={{
                    header: {
                        backgroundColor: '#00497A', // Cambia este color al que prefieras
                        color: 'white' // Cambia el color del texto si es necesario
                    },
                    //body: {
                    //    backgroundColor: '#f0f0f0',
                    //},
                    close: {
                        color: '#ffffff',
                        backgroundColor: 'transparent',
                    },
                    //inner: {
                    //    backgroundColor: '#f0f0f0',
                    //},
                }}
            >
                <PacienteForm
                    formMode="add"
                    setShowForm={setShowPacienteForm}
                    getData={fetchPacientes}
                />
            </Modal>

        </div >

    );
}

export default CasosForm;