import React, { useState } from 'react';
import { Table, Button, Badge } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { IconDownload } from '@tabler/icons-react';

const Tabla12 = ({ data, filters }) => {

    // Función para obtener el total por columna
    const getTotalByColumn = (column) => {
        return data.reduce((acc, item) => acc + (item[column] || 0), 0);
    };

    if (!data || data.length === 0) {
        return (
            <div>
                <Badge color='grey'>Sin información</Badge>
            </div>
        );
    }

    const csvHeaders = [
        'ID CULTIVO',
        'NUMERO CULTIVO',
        'NUMERO CASO',
        'ANTIBIOGRAMA',
        'TIPO CULTIVO',
        'FECHA TOMA',
        'RESULTADO CULTIVO',
        'PACIENTE',
        'TIPO AV',
        'UNIDAD',
        'SALA',
        'PROCEDENCIA',
        'TURNO',
        'MAQUINA',
        'ENFERMERO',
        'USO'
      ];

    // Función para eliminar comas en los textos
    const sanitizeCSV = (text) => {
        if (typeof text === 'string') {
            // Elimina comas y barras "|", pero conserva los saltos de línea
            return `"${text.replace(/[|,]/g, '')}"`; // Encierra en comillas dobles
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData = data.map((data) => [
        data.id_cultivo,
        sanitizeCSV(data.numero_cultivo),
        sanitizeCSV(data.numero_caso),
        sanitizeCSV(data.antibiograma_cultivo),
        sanitizeCSV(data.nombre_tipocultivo),
        sanitizeCSV(data.fecha_toma_cultivo),
        sanitizeCSV(data.nombre_resultado_cultivo),
        sanitizeCSV(data.nombre_paciente),
        sanitizeCSV(data.nombre_tipoav),
        sanitizeCSV(data.nombre_unidad),
        sanitizeCSV(data.nombre_sala),
        sanitizeCSV(data.nombre_procedencia),
        sanitizeCSV(data.nombre_turno),
        sanitizeCSV(data.nombre_maquina),
        sanitizeCSV(data.nombre_enfermero),
        sanitizeCSV(data.nombre_uso),
    ]);

    return (
        <>
            <CsvDownloader
                filename={'Tabla12.csv'}
                separator=","
                wrapColumnChar=""
                datas={csvData}
                columns={csvHeaders}
            >
                <Button
                    variant="outline"
                    color='green'
                    mb={'10px'}
                    rightSection={<IconDownload size={20} />}
                >
                    Descargar Tabla
                </Button>
            </CsvDownloader>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>ID CULTIVO</th>
                        <th>NUMERO CULTIVO</th>
                        <th>NUMERO CASO</th>
                        <th>ANTIBIOGRAMA</th>
                        <th>TIPO CULTIVO</th>
                        <th>FECHA TOMA</th>
                        <th>RESULTADO CULTIVO</th>
                        <th>PACIENTE</th>
                        <th>TIPO AV</th>
                        <th>UNIDAD</th>
                        <th>SALA</th>
                        <th>PROCEDENCIA</th>
                        <th>TURNO</th>
                        <th>MAQUINA</th>
                        <th>ENFERMERO</th>
                        <th>USO</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={`${item.id_cultivo}`}>
                            <td>{item.id_cultivo}</td>
                            <td>{item.numero_cultivo}</td>
                            <td>{item.numero_caso}</td>
                            <td>{item.antibiograma_cultivo}</td>
                            <td>{item.nombre_tipocultivo}</td>
                            <td>{item.fecha_toma_cultivo}</td>
                            <td>{item.nombre_resultado_cultivo}</td>
                            <td>{item.nombre_paciente}</td>
                            <td>{item.nombre_tipoav}</td>
                            <td>{item.nombre_unidad}</td>
                            <td>{item.nombre_sala}</td>
                            <td>{item.nombre_procedencia}</td>
                            <td>{item.nombre_turno}</td>
                            <td>{item.nombre_maquina}</td>
                            <td>{item.nombre_enfermero}</td>
                            <td>{item.nombre_uso}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>


        </>
    );
};

export default Tabla12;
