import React, { useState, useEffect } from 'react';
import { Select, Autocomplete, MultiSelect } from '@mantine/core';

import { DatePickerInput } from '@mantine/dates';
import { supabase } from "../supabaseClient";


function Tabla1Filtro({ setFilters, filters, onEnterPress }) {
    const [unidades, setUnidades] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [enfermeros, setEnfermeros] = useState([]);
    const [etapas, setEtapas] = useState([]);

    const [accesos, setAccesos] = useState([]);
    const [clasificacionesResultados, setClasificacionesResultados] = useState([]);
    const [usos, setUsos] = useState([]);

    const [maquinas, setMaquinas] = useState([]);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onEnterPress(); // Llama a la función getData pasada como prop
        }
    };

    useEffect(() => {
        const fetchUnidades = async () => {
            try {
                const { data: unidadesData, error: errorData } = await supabase
                    .from('cat_unidades')
                    .select('nombre_unidad');

                if (errorData) {
                    throw errorData;
                }

                const unidadesOptions = unidadesData.map((unidad) => ({
                    label: unidad.nombre_unidad,
                    value: unidad.nombre_unidad
                }));

                setUnidades(unidadesOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchPacientes = async () => {
            try {
                const { data: pacientesData, error: errorData } = await supabase
                    .from('cat_pacientes')
                    .select('nombre_paciente');

                if (errorData) {
                    throw errorData;
                }

                const pacientesOptions = pacientesData.map((paciente) => ({
                    label: paciente.nombre_paciente,
                    value: paciente.nombre_paciente
                }));

                setPacientes(pacientesOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchEnfermeros = async () => {
            try {
                const { data: enfermerosData, error: errorData } = await supabase
                    .from('cat_enfermeros')
                    .select('nombre_enfermero');

                if (errorData) {
                    throw errorData;
                }

                const enfermerosOptions = enfermerosData.map((enfermero) => ({
                    label: enfermero.nombre_enfermero,
                    value: enfermero.nombre_enfermero
                }));

                setEnfermeros(enfermerosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchEtapas = async () => {
            try {
                const { data: etapasData, error: errorData } = await supabase
                    .from('cat_etapas_casos')
                    .select('nombre_etapa_caso');

                if (errorData) {
                    throw errorData;
                }

                const etapasOptions = etapasData.map((etapas) => ({
                    label: etapas.nombre_etapa_caso,
                    value: etapas.nombre_etapa_caso
                }));

                setEtapas(etapasOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };


        const fetchAccesos = async () => {
            try {
                const { data: accesosData, error: errorData } = await supabase
                    .from('cat_tiposav')
                    .select('clasificacion_tipoav');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueAccesos = [...new Set(accesosData.map(acceso => acceso.clasificacion_tipoav))];

                // Mapear los valores únicos a las opciones para el estado
                const accesosOptions = uniqueAccesos.map((acceso) => ({
                    label: acceso, // Usa clasificacion_tipoav como label
                    value: acceso  // Usa clasificacion_tipoav como value
                }));

                // Establecer los accesos únicos
                setAccesos(accesosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchClasificacionesResultados = async () => {
            try {
                const { data: clasificacionesRespuestasData, error: errorData } = await supabase
                    .from('cat_resultados_cultivos')
                    .select('clasificacion_resultado_cultivo');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueClasificacionesResultados = [...new Set(clasificacionesRespuestasData.map(clasificacionresultado => clasificacionresultado.clasificacion_resultado_cultivo))];

                // Mapear los valores únicos a las opciones para el estado
                const clasificacionesResultadosOptions = uniqueClasificacionesResultados.map((clasificacionresultado) => ({
                    label: clasificacionresultado, // Usa clasificacion_resultado_cultivo como label
                    value: clasificacionresultado  // Usa clasificacion_resultado_cultivo como value
                }));

                // Establecer los accesos únicos
                setClasificacionesResultados(clasificacionesResultadosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchUsos = async () => {
            try {
                const { data: usosData, error: errorData } = await supabase
                    .from('cat_usos')
                    .select('clasificacion_uso');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueUsos = [...new Set(usosData.map(uso => uso.clasificacion_uso))];

                // Mapear los valores únicos a las opciones para el estado
                const usosOptions = uniqueUsos.map((uso) => ({
                    label: uso, // Usa clasificacion_tipoav como label
                    value: uso  // Usa clasificacion_tipoav como value
                }));

                // Establecer los accesos únicos
                setUsos(usosOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };

        const fetchMaquinas = async () => {
            try {
                const { data: maquinaData, error: errorData } = await supabase
                    .from('cat_maquinas')
                    .select('nombre_maquina');

                if (errorData) {
                    throw errorData;
                }

                // Obtener valores únicos utilizando un Set
                const uniqueMaquinas = [...new Set(maquinaData.map(maquina => maquina.nombre_maquina))];

                // Mapear los valores únicos a las opciones para el estado
                const maquinasOptions = uniqueMaquinas.map((maquina) => ({
                    label: maquina, // Usa clasificacion_tipoav como label
                    value: maquina  // Usa clasificacion_tipoav como value
                }));

                // Establecer los accesos únicos
                setMaquinas(maquinasOptions);
            } catch (error) {
                console.error('Error al obtener datos:', error.message);
            }
        };


        fetchUnidades();
        fetchPacientes();
        fetchEnfermeros();
        fetchEtapas();
        fetchAccesos();
        fetchClasificacionesResultados();
        fetchUsos();
        fetchMaquinas();

    }, []);

    const handleDateChange = (field, date) => {
        setFilters({ ...filters, [field]: date });
    };

    return (
        <div>

            <div onKeyDown={handleKeyDown} className='Filtro'>
                <Select
                    label="Unidad"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={unidades}
                    value={filters.unidades}
                    onChange={(value) => setFilters({ ...filters, nombre_unidad: value })}
                    name="nombre_unidad"
                />
                <Select
                    label="Etapa"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={etapas}
                    value={filters.etapas}
                    onChange={(value) => setFilters({ ...filters, nombre_etapa_caso: value })}
                    name="nombre_etapa_caso"
                />
                <Select
                    label="Enfermero"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={enfermeros}
                    value={filters.enfermeros}
                    onChange={(value) => setFilters({ ...filters, nombre_enfermero: value })}
                    name="nombre_enfermero"
                />
                <Autocomplete
                    label="Paciente"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={pacientes}
                    value={filters.pacientes}
                    onChange={(value) => setFilters({ ...filters, nombre_paciente: value })}
                    name="nombre_paciente"
                />
                <Select
                    label="Uso de Filtro"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={usos}
                    value={filters.usos}
                    onChange={(value) => setFilters({ ...filters, clasificacion_uso: value })}
                    name="clasificacion_uso"
                />
            </div>
            <div onKeyDown={handleKeyDown} className='Filtro'>
                <Select
                    label="Tipo acceso"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={accesos}
                    value={filters.accesos}
                    onChange={(value) => setFilters({ ...filters, clasificacion_tipoav: value })}
                    name="clasificacion_tipoav"
                />

                <DatePickerInput
                    label="F. inicial"
                    className='caja-150'

                    value={filters.fechaInicial}
                    onChange={(date) => handleDateChange('fechaInicial', date)}
                    clearable
                    locale="es"
                    valueFormat="DD/MM/YYYY"

                />
                <DatePickerInput
                    label="F. final"
                    className='caja-150'
                    value={filters.fechaFinal}
                    onChange={(date) => handleDateChange('fechaFinal', date)}
                    clearable
                    locale="es"
                    valueFormat="DD/MM/YYYY"
                />

                <MultiSelect
                    label="Clasificación Resultado Cultivo"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={clasificacionesResultados}
                    value={filters.clasificacionesResultados}
                    onChange={(value) => setFilters({ ...filters, clasificacion_resultado_cultivo: value })}
                    name="clasificacion_resultado_cultivo"
                    hidePickedOptions
                />
                <Select
                    label="Máquina"
                    placeholder="Seleccione"
                    className='caja-150'
                    data={maquinas}
                    value={filters.maquinas}
                    onChange={(value) => setFilters({ ...filters, nombre_maquina: value })}
                    name="nombre_maquina"
                />


            </div>

        </div>

    );
}

export default Tabla1Filtro;