import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Tabla1Filtro from '../components/Tabla1Filtro';
import Tabla1 from '../components/Tabla1';
import { Loader, Text, Button, Group, Accordion, Card, Badge } from '@mantine/core';
import { useDispatch } from 'react-redux';
import moment from "moment";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';

const CatalogoReporteTabla1 = () => {
    //const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual,
        fechaFinal: new Date(),
        nombre_unidad: "",
        nombre_paciente: "",
        nombre_enfermero: "",
        nombre_etapa_caso: "",
        clasificacion_tipoav: "",
        clasificacion_resultado_cultivo: [],
        clasificacion_uso:"",
        nombre_maquina:"",
    });

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]); // Nuevo estado para guardar los datos con la nueva fecha


    // Función para obtener los datos de la vista "Tabla 1" desde Supabase
    const fetchCasos = async () => {
        setLoading(true);
        try {
            const { data: responseData, error } = await supabase
                .rpc('obtener_casos_filtrados', {
                    fecha_inicio: moment(filters.fechaInicial).format('YYYY-MM-DD'),
                    fecha_fin: moment(filters.fechaFinal).format('YYYY-MM-DD'),
                    nombre_enfermero_param: filters.nombre_enfermero || null,
                    nombre_unidad_param: filters.nombre_unidad || null,
                    nombre_paciente_param: filters.nombre_paciente || null,
                    nombre_etapa_caso_param: filters.nombre_etapa_caso || null,
                    clasificacion_tipoav_param: filters.clasificacion_tipoav || null,
                    // Si el arreglo está vacío, envía null para que no filtre por ese campo
                    clasificacion_resultado_cultivo_param: filters.clasificacion_resultado_cultivo.length > 0
                        ? filters.clasificacion_resultado_cultivo
                        : null,
                    clasificacion_uso_param: filters.clasificacion_uso || null,
                    nombre_maquina_param: filters.nombre_maquina || null,
                });

            if (error) {
                console.log(error);
                throw new Error('Error al obtener datos: ' + error.message);
            }

            console.log('Datos obtenidos:', responseData);
            return responseData;

        } catch (error) {
            console.error(error.message);
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red",
            });
            return [];
        } finally {
            setLoading(false);
        }
    };



    const getData = async () => {
        try {
            //dispatch(ShowLoading());
            // Llamamos a fetchCasos y guardamos la respuesta
            const cargarInformacion = await fetchCasos();
            // Actualizamos el estado con los datos obtenidos
            setData(cargarInformacion || []); // Si no hay datos, usamos un array vacío
            // Ahora llamar a la función para intercambiar las fechas y obtener data2
            await getDataWithDateRangeSwap();
            setLoading(false); // Desactivamos la visualización de los datos
            //dispatch(HideLoading());
        }
        catch (error) {
            console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            //dispatch(HideLoading());
        }
    };



    const fetchCasos2 = async (nuevosFiltros) => {
        setLoading(true);
        if (!nuevosFiltros) {
            throw new Error('nuevosFiltros es undefined');
        }
        try {
            const { data: responseData, error } = await supabase
                .rpc('obtener_casos_filtrados', {
                    fecha_inicio: moment(nuevosFiltros.fechaInicial).format('YYYY-MM-DD'),
                    fecha_fin: moment(nuevosFiltros.fechaFinal).format('YYYY-MM-DD'),
                    nombre_enfermero_param: nuevosFiltros.nombre_enfermero || null,
                    nombre_unidad_param: nuevosFiltros.nombre_unidad || null,
                    nombre_paciente_param: nuevosFiltros.nombre_paciente || null,
                    nombre_etapa_caso_param: nuevosFiltros.nombre_etapa_caso || null,
                    clasificacion_tipoav_param: nuevosFiltros.clasificacion_tipoav || null,
                    clasificacion_resultado_cultivo_param: nuevosFiltros.clasificacion_resultado_cultivo.length > 0
                        ? nuevosFiltros.clasificacion_resultado_cultivo
                        : null,
                    clasificacion_uso_param: nuevosFiltros.clasificacion_uso || null,
                    nombre_maquina_param: filters.nombre_maquina || null,

                });

            if (error) {
                console.log(error);
                throw new Error('Error al obtener datos: ' + error.message);
            }

            console.log('Datos obtenidos:', responseData);
            return responseData;

        } catch (error) {
            console.error(error.message);
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red",
            });
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getDataWithDateRangeSwap = async () => {
        setLoading(true);
        try {
            if (!filters) {
                throw new Error('filters es undefined');
            }

            const diffTime = filters.fechaFinal - filters.fechaInicial;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            let nuevaFechaFinal = new Date(filters.fechaInicial.getTime() - 1000); // La fecha final es la fecha inicial menos 1 segundo
            let nuevaFechaInicial;

            // Si son la misma fecha, establecer nuevaFechaInicial a un día antes y nuevaFechaFinal un día antes a 23:59:59
            if (diffDays === 0) {
                nuevaFechaInicial = new Date(filters.fechaInicial);
                nuevaFechaInicial.setDate(nuevaFechaInicial.getDate() - 1); // Un día antes
                nuevaFechaFinal = new Date(filters.fechaInicial);
                nuevaFechaFinal.setDate(nuevaFechaFinal.getDate() - 1);
                nuevaFechaFinal.setHours(23, 59, 59, 999); // Final del mismo día
            } else {
                nuevaFechaInicial = new Date(filters.fechaInicial.getTime() - diffDays * (1000 * 60 * 60 * 24));
            }

            console.log("Días de diferencia:", diffDays);
            console.log("Periodo anterior:", nuevaFechaInicial, nuevaFechaFinal);

            const nuevosFiltros = {
                ...filters,
                fechaInicial: nuevaFechaInicial,
                fechaFinal: nuevaFechaFinal
            };

            const cargarInformacion = await fetchCasos2(nuevosFiltros);
            setData2(cargarInformacion || []);

        } catch (error) {
            console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Tabla1Filtro
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                        />
                        <Group justify='flex-start'>
                            <Button
                                variant="gradient"
                                gradient={{ from: 'blue', to: '#00497A', deg: 90 }}
                                leftSection={<IconSearch size={20} />}
                                onClick={getData}
                            >
                                Generar Tabla
                            </Button>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Accordion defaultValue="tabla">
                <Accordion.Item value="tabla">
                    <Accordion.Control className="AccordionControl"
                    >
                        Total Cultivos por Turno & Sala
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>

                            <Card className='CardTablas' style={{ flex: '1 1 30%', background: 'white' }}>

                                <div style={{ overflowX: "auto" }}>
                                    {loading ? (
                                        <>
                                            <Loader size={70} type="dots" />
                                            <p style={{ marginTop: "1px", fontSize: "16px", color: "#555" }}>Obteniendo datos...</p>
                                        </>
                                    ) : (
                                        <Tabla1 data={data} data2={data2} filters={filters} />
                                    )}
                                </div>
                            </Card>
                        </div>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>


        </div>
    );
};

export default CatalogoReporteTabla1;
