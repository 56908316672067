import React, { useState } from 'react';
import { Table, Button, Group, Text } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { IconDownload } from '@tabler/icons-react';

const TablaNotas = ({ data, filters }) => {



    const csvHeaders = [
        'ID CULTIVO',
        'NUMERO CULTIVO',
        'NUMERO CASO',
        'ANTIBIOGRAMA',
        'TIPO CULTIVO',
        'FECHA TOMA',
        'RESULTADO CULTIVO',
        'PACIENTE',
        'TIPO AV',
        'UNIDAD',
        'SALA',
        'PROCEDENCIA',
        'TURNO',
        'MAQUINA',
        'ENFERMERO',
        'USO'
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV = (text) => {
        if (typeof text === 'string') {
            // Elimina comas y barras "|", pero conserva los saltos de línea
            return `"${text.replace(/[|,]/g, '')}"`; // Encierra en comillas dobles
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData = data.map((data) => [
        data.id_cultivo,
        sanitizeCSV(data.numero_cultivo),
        sanitizeCSV(data.numero_caso),
        sanitizeCSV(data.antibiograma_cultivo),
        sanitizeCSV(data.nombre_tipocultivo),
        sanitizeCSV(data.fecha_toma_cultivo),
        sanitizeCSV(data.nombre_resultado_cultivo),
        sanitizeCSV(data.nombre_paciente),
        sanitizeCSV(data.nombre_tipoav),
        sanitizeCSV(data.nombre_unidad),
        sanitizeCSV(data.nombre_sala),
        sanitizeCSV(data.nombre_procedencia),
        sanitizeCSV(data.nombre_turno),
        sanitizeCSV(data.nombre_maquina),
        sanitizeCSV(data.nombre_enfermero),
        sanitizeCSV(data.nombre_uso),
    ]);

    return (
        <>
            <CsvDownloader
                filename={'TablaNotas.csv'}
                separator=","
                wrapColumnChar=""
                datas={csvData}
                columns={csvHeaders}
            >
                <Button
                    variant="outline"
                    color='green'
                    mb={'10px'}
                    rightSection={<IconDownload size={20} />}
                >
                    Descargar Tabla
                </Button>
            </CsvDownloader>
            <Table
                style={{ textAlign: 'left', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>FECHA NOTA</th>
                        <th>DATOS DEL CASO</th>
                        <th>DATOS DE NOTA</th>
                        <th>NOTA</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={`${item.id_nota}`}>
                            <td >
                                <div
                                    style={{
                                        width: '165px',
                                        textAlign: 'left',
                                        padding: '5px'
                                    }}>
                                    <Text size="sm"><strong>Id nota: </strong>{item.id_nota}</Text>
                                    <Text size="sm"><strong>Fecha nota: </strong>{item.fecha_nota}</Text>
                                </div>
                            </td>
                            <td >
                                <div
                                    style={{
                                        width: '260px',
                                        textAlign: 'left',
                                        padding: '5px'
                                    }}>
                                    <Text size="sm"><strong>Id Caso: </strong>{item.casos.id_caso}</Text>
                                    <Text size="sm"><strong>Paciente: </strong>{item.casos.cat_pacientes.nombre_paciente}</Text>
                                    <Text size="sm"><strong>Unidad: </strong>{item.casos.cat_unidades.nombre_unidad}</Text>
                                    <Text size="sm"><strong>Sala: </strong>{item.casos.cat_salas.nombre_sala}</Text>
                                    <Text size="sm"><strong>Turno: </strong>{item.casos.cat_turnos.nombre_turno}</Text>
                                    <Text size="sm"><strong>Procedencia: </strong>{item.casos.cat_procedencias.nombre_procedencia}</Text>
                                    <Text size="sm"><strong>Etapa del caso: </strong>{item.casos.cat_etapas_casos.nombre_etapa_caso}</Text>
                                    <Text size="sm"><strong>Tipo acceso: </strong>{item.casos.cat_tiposav.clasificacion_tipoav}</Text>
                                    <Text size="sm"><strong>Uso de filtro: </strong>{item.casos.cat_usos.clasificacion_uso}</Text>

                                    

                                    

                                </div>
                            </td>
                            <td >
                                <div
                                    style={{
                                        width: '280px',
                                        textAlign: 'left',
                                        padding: '5px'
                                    }}>
                                    <Text size="sm"><strong>Manejo: </strong>{item.cat_manejos.nombre_manejo}</Text>

                                    <Text size="sm"><strong>Evolución: </strong>{item.cat_evoluciones.nombre_evolucion}</Text>
                                    <Text size="sm"><strong>¿Cambio catéter?: </strong>{item.cat_cambios.nombre_cambio}</Text>
                                    <Text size="sm"><strong>Nota creada por: </strong>{item.usuario_creador}</Text>

                                </div>
                            </td>

                            <td>
                                <div
                                    style={{
                                        width: '320px',
                                        textAlign: 'left',
                                        paddingLeft: '5px'
                                    }}>
                                    <Text size="sm">{item.nombre_nota}</Text>

                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>


        </>
    );
};

export default TablaNotas;
