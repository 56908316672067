import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Group, Button } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";


function UsosForm({
    formMode,
    setFormMode,
    setShowForm,
    transactionData,
    getData,
}) {
    const dispatch = useDispatch();

    //valores iniciales
    const [user, setUser] = useState({
        nombre_uso: "",
        id_uso: "", // Inicializar id_uso como una cadena vacía
        clasificacion_uso:""

    })


    useEffect(() => {
        //console.log("Transaction data:", transactionData); // Agregar este console.log
        if (formMode === 'edit' && Object.keys(transactionData).length !== 0) {
            setUser({
                nombre_uso: transactionData.nombre_uso,
                id_uso: transactionData.id_uso,
                clasificacion_uso: transactionData.clasificacion_uso

            });
        }
    }, [formMode, transactionData]);



    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleUpdateUso = async () => {
        try {
            // Primero, obtenemos el nombre_uso actual del uso que se está editando
            const { data: usoActual, error: usoError } = await supabase
                .from('cat_usos')
                .select('nombre_uso', 'clasificacion_uso')
                .eq('id_uso', user.id_uso)
                .single();

            if (usoError) {
                throw usoError;
            }

            // Verificamos si el nombre_uso se ha modificado
            if (user.nombre_uso !== usoActual.nombre_uso) {
                // Si el nombre_uso ha cambiado, verificamos si ya existe en otro registro
                const { data, error } = await supabase
                    .from('cat_usos')
                    .select('nombre_uso')
                    .eq('nombre_uso', user.nombre_uso)
                    .limit(1);

                if (error) {
                    throw error;
                }

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre del uso ya existe.',
                        color: 'red',
                    });
                    dispatch(HideLoading()); // Ocultamos el indicador de carga
                    return; // Salimos de la función sin realizar la actualización y sin cerrar el modal
                }
            }

            // Procedemos con la actualización del uso
            const { error: updateError } = await supabase
                .from('cat_usos')
                .update(user)
                .eq('id_uso', user.id_uso);

            if (updateError) {
                throw updateError;
            }

            // Notificación de éxito
            showNotification({
                title: 'Uso actualizado',
                message: 'El uso se ha actualizado correctamente.',
                color: 'green',
            });

            setShowForm(false); // Cerrar el modal solo si la actualización es exitosa
            getData();

        } catch (error) {
            console.error('Error al actualizar el uso:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al actualizar el uso.',
                color: 'red',
            });
        } finally {
            dispatch(HideLoading());
        }

    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(ShowLoading());
            if (formMode === 'add') {

                const { data, error } = await supabase
                    .from('cat_usos')
                    .select('nombre_uso')
                    .eq('nombre_uso', user.nombre_uso)
                    .limit(1); // Limitamos a un solo resultado

                if (data.length > 0) {
                    showNotification({
                        title: 'Error',
                        message: 'El nombre de uso ya existe. Intente otro nombre',
                        color: 'red',
                    });
                    return dispatch(HideLoading());

                } else {
                    await supabase
                        .from('cat_usos')
                        .insert({
                            nombre_uso: user.nombre_uso,
                            clasificacion_uso: user.clasificacion_uso
                        });
                    showNotification({
                        title: 'Uso agregado',
                        message: 'El Uso ha sido agregado con éxito',
                        color: 'green',
                    });

                    dispatch(HideLoading());
                    setShowForm(false);
                    getData();

                }


            } else if (formMode === 'edit') {
                await handleUpdateUso();
            }
            //setShowForm(false);
            //getData();
        } catch (error) {
            //console.log(error)
            //console.error('Error al agregar/actualizar Uso:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la Uso.',
                color: 'red',
            });
        }
    };




    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <form action=""
                onSubmit={handleSubmit}>
                <Group >
                    <Stack>
                        <TextInput
                            disabled
                            name="id_uso"
                            label="ID"
                            onChange={handleChange}
                            style={{ width: '300px' }}
                            value={user.id_uso}

                        />

                        <TextInput
                            name="nombre_uso"
                            label="Nombre Uso"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.nombre_uso} // Asignar el valor del estado al campo
                            placeholder='Nombre Uso'
                        />
                        <TextInput
                            name="clasificacion_uso"
                            label="Clasificación Uso"
                            style={{ width: '300px' }}
                            required
                            onChange={handleChange}
                            value={user.clasificacion_uso} // Asignar el valor del estado al campo
                            placeholder='Clasificación Uso'
                        />
                    </Stack>
                </Group>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button mt={15} color="#005187" type="submit">
                        {formMode === 'add' ? "+ Agregar Uso" : "Editar Uso"}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default UsosForm;