import React, { useState, useEffect } from 'react';
import { Card, Badge, Timeline, TextInput, Button, Group, Stack, Table, Divider, Textarea, Text, Select, Box, Autocomplete, NativeSelect, Modal, Flex } from '@mantine/core';
import { IconArrowRight, IconDownload } from '@tabler/icons-react';
import { supabase } from '../supabaseClient';
import { showNotification } from "@mantine/notifications";
import CsvDownloader from 'react-csv-downloader';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');




function CultivosForm({ formMode, transactionData, casoId, getData, user, permisos, puedeModificarNota, puedeEliminarNota, puedeRegistrarNota, puedeModificarTratamiento, puedeEliminarTratamiento, puedeRegistrarTratamiento, puedeModificarCultivo, puedeEliminarCultivo, puedeRegistrarCultivo }) {
    const dispatch = useDispatch();

    const icon = <IconArrowRight size={20} />;

    const [cultivo, setCultivo] = useState({
        id_cultivo: '',
        fecha_toma_cultivo: '',
        numero_cultivo: '',
        antibiograma_cultivo: '',
        //nombre_resultado_cultivo: '',
        id_tipocultivo: '',
        id_resultado_cultivo: '',
    });

    const [cultivos, setCultivos] = useState([]);
    const [editMode, setEditMode] = useState(false);
    //const [antibioticoActual, setAntibioticoActual] = useState('');
    const [antibioticoActual, setAntibioticoActual] = useState('');

    //const [antibiogramaActual, setAntibiogramaActual] = useState('');
    const [antibiogramaActual, setAntibiogramaActual] = useState('');


    const [resultadosCultivos, setResultadosCultivos] = useState([]);
    const [tiposCultivos, setTiposCultivos] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);


    //const antibioticos = ['Ampicilina', 'Amoxicilina', 'Ciprofloxacino', 'Eritromicina', 'Gentamicina', 'Linezolid', 'Vancomicina', 'Trimetoprim/Sulfametoxazol'];
    //const sensibilidades = ['Sensible', 'Resistente', 'Indeterminado'];

    const [antibioticos, setAntibioticos] = useState([]);
    const [sensibilidades, setSensibilidades] = useState([]);

    // Nuevos estados para tratamientos y notas
    const [tratamientos, setTratamientos] = useState([]);
    const [tratamiento, setTratamiento] = useState({
        id_tratamiento: '',
        nombre_tratamiento: '',
        fecha_inicio_tratamiento: '',
        fecha_fin_tratamiento: null,
        usuario_creador_tratamiento: '',

    });

    const handleInputChange = (field, value) => {
        setTratamiento(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const [notas, setNotas] = useState([]);
    const [nota, setNota] = useState({
        id_nota: '',
        fecha_nota: '',
        nombre_nota: '',
        usuario_creador: '',
        id_manejo: '',
        id_evolucion: '',
        id_cambio: '',
    });

    const handleInputChangeNota = (field, value) => {
        setNota(prev => ({
            ...prev,
            [field]: value
        }));
    };
    const [modalTratamientoOpen, setModalTratamientoOpen] = useState(false);
    const [modalNotaOpen, setModalNotaOpen] = useState(false);
    const [editModeTratamiento, setEditModeTratamiento] = useState(false);
    const [editModeNota, setEditModeNota] = useState(false);

    // nuevas variables para id_manejo, id_evolucion y id_cambio
    const [manejos, setManejos] = useState([]);
    const [selectedManejo, setSelectedManejo] = useState("");

    const [evoluciones, setEvoluciones] = useState([]);
    const [selectedEvolucion, setSelectedEvolucion] = useState("");

    const [cambios, setCambios] = useState([]);
    const [selectedCambio, setSelectedCambio] = useState("");

    // useEffect para popular el select de manejos, evoluciones y cambios       

    useEffect(() => {
        async function fetchManejos() {
            try {
                const { data: manejos } = await supabase
                    .from('cat_manejos')
                    .select('id_manejo, nombre_manejo')
                    .order('nombre_manejo', { ascending: true });

                setManejos(manejos || []);
                console.log('Manejos cargados:', manejos);

            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchEvoluciones() {
            try {
                const { data: evoluciones } = await supabase
                    .from('cat_evoluciones')
                    .select('id_evolucion, nombre_evolucion')
                    .order('nombre_evolucion', { ascending: true });

                setEvoluciones(evoluciones || []);
                console.log('evoluciones:', evoluciones);

            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        async function fetchCambios() {
            try {
                const { data: cambios } = await supabase
                    .from('cat_cambios')
                    .select('id_cambio, nombre_cambio')
                    .order('nombre_cambio', { ascending: true });

                setCambios(cambios || []);
                console.log('cambios cargados:', cambios);

            } catch (error) {
                console.error('Error:', error.message);
            }
        }

        fetchManejos();
        fetchEvoluciones();
        fetchCambios();
    }, []);


    // Para manejar el cambio en id_manejo, id_evolucion y id_cambio en notas
    const handleManejoChange = (event) => {
        setSelectedManejo(event.target.value);
    };

    const handleEvolucionChange = (event) => {
        setSelectedEvolucion(event.target.value);
    };

    const handleCambioNotasChange = (event) => {
        setSelectedCambio(event.target.value);
    };

    useEffect(() => {
        if (editModeNota && nota.id_nota) {
            // Asignar valores de nota a los estados de select cuando se abre el modal en modo edición
            setSelectedManejo(nota.id_manejo || "");
            setSelectedEvolucion(nota.id_evolucion || "");
            setSelectedCambio(nota.id_cambio || "");
        }
    }, [editModeNota, nota]);



    // Función para quitar las comas
    const replaceCommas = (text) => {
        return text ? text.replace(/,/g, '') : '';
    };

    // Modificar datos CSV antes de exportar para las Notas
    const csvHeaders = [
        { id: 'id', displayName: 'ID' },
        { id: 'nombre_nota', displayName: 'Nota' },
        { id: 'fecha_nota', displayName: 'Fecha' }
    ];

    const csvData = notas.map((notaItem) => ({
        id: notaItem.id_nota,
        nombre_nota: replaceCommas(notaItem.nombre_nota), // Reemplazar comas
        fecha_nota: moment(notaItem.fecha_nota).format('DD/MM/YYYY'),
    }));

    // Modificar datos CSV antes de exportar para los Tratamientos
    const csvHeadersTratamientos = [
        { id: 'id', displayName: 'ID' },
        { id: 'nombre_tratamiento', displayName: 'Tratamiento' },
        { id: 'fecha_inicio_tratamiento', displayName: 'Fecha inicio' },
        { id: 'fecha_fin_tratamiento', displayName: 'Fecha fin' }

    ];

    const csvDataTratamiento = tratamientos.map((tratamientoItem) => ({
        id: tratamientoItem.id_tratamiento,
        nombre_tratamiento: replaceCommas(tratamientoItem.nombre_tratamiento), // Reemplazar comas
        fecha_inicio_tratamiento: moment(tratamientoItem.fecha_inicio_tratamiento).format('DD/MM/YYYY'),
        fecha_fin_tratamiento: moment(tratamientoItem.fecha_fin_tratamiento).format('DD/MM/YYYY'),

    }));


    useEffect(() => {
        fetchCultivos();
        fetchResultadosCultivos();
        fetchTiposCultivos();
        fetchTratamientos();
        fetchAntibioticos();
        fetchSensibilidades();
        fetchNotas();
    }, [casoId]);


    const fetchCultivos = async () => {
        try {
            const { data, error } = await supabase
                .from('cultivos')
                .select('*')
                .eq('id_caso', casoId)
                .order('id_cultivo', { ascending: true });

            if (error) throw error;
            setCultivos(data);
        } catch (error) {
            console.error('Error al obtener cultivos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener los cultivos.',
                color: 'red',
            });
        }
    };

    /*
    const fetchResultadosCultivos = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_resultados_cultivos')
                .select('*')
                .order('nombre_resultado_cultivo', { ascending: true });


            if (error) throw error;
            setResultadosCultivos(data.map(item => item.nombre_resultado_cultivo)); // Map to get array of names
        } catch (error) {
            console.error('Error al obtener resultados de cultivos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener las resultados de cultivos.',
                color: 'red',
            });
        }
    };
    */

    const fetchResultadosCultivos = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_resultados_cultivos')
                .select('*')
                .order('nombre_resultado_cultivo', { ascending: true });

            if (error) throw error;
            setResultadosCultivos(data);
        } catch (error) {
            console.error('Error al obtener resultados de cultivos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener los resultados de cultivos.',
                color: 'red',
            });
        }
    };

    const fetchTiposCultivos = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_tiposcultivos')
                .select('*')
                .order('id_tipocultivo', { ascending: true });

            if (error) throw error;
            setTiposCultivos(data);
        } catch (error) {
            console.error('Error al obtener tipos de cultivos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener los tipos de cultivos.',
                color: 'red',
            });
        }
    };

    const fetchTratamientos = async () => {
        try {
            const { data, error } = await supabase
                .from('tratamientos')
                .select('*')
                .eq('id_caso', casoId)
                .order('id_tratamiento', { ascending: false });

            if (error) throw error;
            setTratamientos(data);
        } catch (error) {
            console.error('Error al obtener tratamientos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener los tratamientos.',
                color: 'red',
            });
        }
    };

    // Consulta para obtener las notas y sus datos que aparecen en vista de tarjeta
    const fetchNotas = async () => {
        try {
            const { data, error } = await supabase
                .from('notas')
                .select(`
                    id_nota,
                    fecha_nota,
                    nombre_nota,
                    usuario_creador,
                    id_manejo,
                    cat_manejos(
                        id_manejo,
                        nombre_manejo
                        ),
                    id_cambio,
                    cat_cambios(
                        id_cambio,
                        nombre_cambio
                        ),
                    id_evolucion,
                    cat_evoluciones(
                        id_evolucion,
                        nombre_evolucion
                        )
                    

                    `)
                .eq('id_caso', casoId)
                .order('fecha_nota', { ascending: false });

            if (error) throw error;
            setNotas(data);
        } catch (error) {
            console.error('Error al obtener notas...:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener las notas del tratamiento.',
                color: 'red',
            });
        }
    };

    const fetchAntibioticos = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_antibioticos')
                .select('id_antibiotico, nombre_antibiotico')
                .order('nombre_antibiotico', { ascending: true });

            if (error) throw error;

            // Asegurarse de que cada opción tenga 'value' y 'label'
            const formattedData = data.map(ab => ({
                value: ab.nombre_antibiotico.toString(), // Convertir a string si es necesario
                label: ab.nombre_antibiotico
            }));

            setAntibioticos(formattedData);
        } catch (error) {
            console.error('Error al obtener antibióticos:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener los antibióticos.',
                color: 'red',
            });
        }
    };

    const fetchSensibilidades = async () => {
        try {
            const { data, error } = await supabase
                .from('cat_sensibilidades')
                .select('id_sensibilidad, nombre_sensibilidad')
                .order('nombre_sensibilidad', { ascending: false });

            if (error) throw error;

            // Asegurarse de que cada opción tenga 'value' y 'label'
            const formattedData = data.map(ab => ({
                value: ab.nombre_sensibilidad.toString(), // Convertir a string si es necesario
                label: ab.nombre_sensibilidad
            }));

            setSensibilidades(formattedData);
        } catch (error) {
            console.error('Error al obtener las sensibilidades:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al obtener las sensibilidades.',
                color: 'red',
            });
        }
    };

    const handleSubmitTratamiento = async (event) => {
        event.preventDefault();
        try {
            dispatch(ShowLoading());
            if (editModeTratamiento) {
                const { error } = await supabase
                    .from('tratamientos')
                    .update({
                        nombre_tratamiento: tratamiento.nombre_tratamiento,
                        fecha_inicio_tratamiento: tratamiento.fecha_inicio_tratamiento,
                        fecha_fin_tratamiento: tratamiento.fecha_fin_tratamiento || null,
                    })
                    .eq('id_tratamiento', tratamiento.id_tratamiento);

                if (error) throw error;

                showNotification({
                    title: 'Tratamiento actualizado',
                    message: 'El tratamiento ha sido actualizado con éxito',
                    color: 'green',
                });
            } else {
                const { error } = await supabase
                    .from('tratamientos')
                    .insert([{
                        id_caso: casoId,
                        nombre_tratamiento: tratamiento.nombre_tratamiento,
                        fecha_inicio_tratamiento: tratamiento.fecha_inicio_tratamiento,
                        fecha_fin_tratamiento: tratamiento.fecha_fin_tratamiento || null,
                        usuario_creador_tratamiento: user.username
                    }]);

                if (error) throw error;

                showNotification({
                    title: 'Tratamiento agregado',
                    message: 'El tratamiento ha sido agregado con éxito',
                    color: 'green',
                });
            }

            setTratamiento({ id_tratamiento: '', nombre_tratamiento: '', fecha_inicio_tratamiento: '', fecha_fin_tratamiento: '', usuario_creador: '' });
            setEditModeTratamiento(false);
            setModalTratamientoOpen(false);
            fetchTratamientos();
            getData(); // para que se actualice los CasosCard cuando modifico el tratamiento
            dispatch(HideLoading());

        } catch (error) {
            console.error('Error al agregar/actualizar tratamiento:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el tratamiento.',
                color: 'red',
            });
        }
    };

    const handleSubmitNota = async (event) => {
        event.preventDefault();
        try {
            dispatch(ShowLoading());

            if (editModeNota) {
                const { error } = await supabase
                    .from('notas')
                    .update({
                        fecha_nota: nota.fecha_nota,
                        nombre_nota: nota.nombre_nota || '',
                        id_manejo: selectedManejo,
                        id_evolucion: selectedEvolucion,
                        id_cambio: selectedCambio,
                    })
                    .eq('id_nota', nota.id_nota);
                console.log("Datos enviados en el modo 'edit' de notas Evolucion:", selectedManejo);

                console.log("Datos enviados en el modo 'edit' de notas Manejo:", selectedManejo);

                if (error) throw error;

                showNotification({
                    title: 'Nota actualizada',
                    message: 'La nota ha sido actualizada con éxito',
                    color: 'green',
                });
            } else {
                console.log("User:", user);

                const { error } = await supabase
                    .from('notas')
                    .insert([{
                        id_caso: casoId,
                        fecha_nota: nota.fecha_nota,
                        nombre_nota: nota.nombre_nota,
                        usuario_creador: user.username,
                        id_manejo: selectedManejo,
                        id_evolucion: selectedEvolucion,
                        id_cambio: selectedCambio,
                    }]);

                if (error) throw error;

                showNotification({
                    title: 'Nota agregada',
                    message: 'La nota ha sido agregada con éxito',
                    color: 'green',
                });
            }

            setNota({ id_nota: '', fecha_nota: '', nombre_nota: '', usuario_creador: '', id_cambio: '', id_manejo: '', id_evolucion: '' });  // Resetear usuario_creador
            setSelectedManejo("");
            setSelectedEvolucion("");
            setSelectedCambio("");
            setEditModeNota(false);
            setModalNotaOpen(false);
            fetchNotas();
            getData(); // para que se actualice los CasosCard cuando modifico la nota
            dispatch(HideLoading());

        } catch (error) {
            console.error('Error al agregar/actualizar nota:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar la nota.',
                color: 'red',
            });
        }
    };

    const handleDeleteTratamiento = async (id_tratamiento) => {
        try {

            // Proceder con la eliminación del tratamiento
            const { error } = await supabase
                .from('tratamientos')
                .delete()
                .eq('id_tratamiento', id_tratamiento);

            if (error) throw error;

            showNotification({
                title: 'Tratamiento eliminado',
                message: 'El tratamiento ha sido eliminado con éxito',
                color: 'green',
            });

            fetchTratamientos();
            getData(); // para que se actualice CasosCard cuando eliminamos un tratamiento
        } catch (error) {
            console.error('Error al eliminar tratamiento:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al eliminar el tratamiento.',
                color: 'red',
            });
        }
    };


    const handleDeleteNota = async (id_nota) => {
        try {
            const { error } = await supabase
                .from('notas')
                .delete()
                .eq('id_nota', id_nota);

            if (error) throw error;

            showNotification({
                title: 'Nota eliminada',
                message: 'La nota ha sido eliminada con éxito',
                color: 'green',
            });

            fetchNotas();
            getData(); // para que se actualice CasosCard cuando eliminamos una nota

        } catch (error) {
            console.error('Error al eliminar nota:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al eliminar la nota.',
                color: 'red',
            });
        }
    };



    //const handleChange = (event) => {
    //    const { name, value } = event.target;
    //    setCultivo(prev => ({ ...prev, [name]: value }));
    //};

    const handleChange = (event) => {
        const { name, value } = event.currentTarget; // Cambia event.target por event.currentTarget
        setCultivo(prev => ({
            ...prev,
            [name]: value || ''  // Asegúrate de que el valor sea una cadena vacía si es null o undefined
        }));
    };


    const handleAddAntibiotico = () => {
        if (antibioticoActual && antibiogramaActual) {
            const nuevoAntibiograma = `${cultivo.antibiograma_cultivo}\n${antibioticoActual}: ${antibiogramaActual} |`.trim();
            setCultivo(prev => ({ ...prev, antibiograma_cultivo: nuevoAntibiograma.trim() }));

            // Limpiar los campos de antibiótico y antibiograma
            setAntibioticoActual('');
            setAntibiogramaActual('');
        }
    };


    const renderAntibiograma = (antibiograma) => {
        if (!antibiograma) return null; // Manejar casos en los que antibiograma sea undefined o vacío

        return antibiograma.split('\n').map((linea, index) => {
            const [antibiotico, resistencia] = linea.split(':');
            if (resistencia) {
                const [resultado, _] = resistencia.trim().split('|');
                const color = resultado.trim() === 'Sensible' ? 'lightgreen' : 'transparent';
                return (
                    <Box key={index} style={{ backgroundColor: color, display: 'inline-block', width: '100%' }}>
                        {antibiotico}: {resistencia}
                    </Box>
                );
            }
            return <Box key={index}>{linea}</Box>;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            dispatch(ShowLoading());
            if (editMode) {
                const { error } = await supabase
                    .from('cultivos')
                    .update({
                        numero_cultivo: cultivo.numero_cultivo,
                        antibiograma_cultivo: cultivo.antibiograma_cultivo,
                        //nombre_resultado_cultivo: cultivo.nombre_resultado_cultivo,
                        id_resultado_cultivo: cultivo.id_resultado_cultivo,
                        id_tipocultivo: cultivo.id_tipocultivo,
                        fecha_toma_cultivo: cultivo.fecha_toma_cultivo
                    })
                    .eq('id_cultivo', cultivo.id_cultivo);

                if (error) throw error;

                showNotification({
                    title: 'Cultivo actualizado',
                    message: 'El cultivo ha sido actualizado con éxito',
                    color: 'green',
                });
            } else {
                const { error } = await supabase
                    .from('cultivos')
                    .insert([{
                        id_caso: casoId,
                        numero_cultivo: cultivo.numero_cultivo,
                        antibiograma_cultivo: cultivo.antibiograma_cultivo,
                        //nombre_resultado_cultivo: cultivo.nombre_resultado_cultivo,
                        id_resultado_cultivo: cultivo.id_resultado_cultivo,
                        id_tipocultivo: cultivo.id_tipocultivo,
                        fecha_toma_cultivo: cultivo.fecha_toma_cultivo

                    }]);

                if (error) throw error;

                showNotification({
                    title: 'Cultivo agregado',
                    message: 'El cultivo ha sido agregado con éxito',
                    color: 'green',
                });
            }

            setCultivo({ id_cultivo: '', numero_cultivo: '', antibiograma_cultivo: '', id_resultado_cultivo: '', id_tipocultivo: '', });
            setEditMode(false);
            setModalOpen(false);
            fetchCultivos();
            getData();
            dispatch(HideLoading());

        } catch (error) {
            console.error('Error al agregar/actualizar cultivo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al agregar/actualizar el cultivo.',
                color: 'red',
            });
        }
    };

    const handleEdit = (cultivoToEdit) => {
        setCultivo(cultivoToEdit);
        setEditMode(true);
        setModalOpen(true);
    };

    const handleDelete = async (id_cultivo) => {
        try {
            const { error } = await supabase
                .from('cultivos')
                .delete()
                .eq('id_cultivo', id_cultivo);

            if (error) throw error;

            showNotification({
                title: 'Cultivo eliminado',
                message: 'El cultivo ha sido eliminado con éxito',
                color: 'green',
            });

            fetchCultivos();
            getData();

        } catch (error) {
            console.error('Error al eliminar cultivo:', error.message);
            showNotification({
                title: 'Error',
                message: 'Hubo un error al eliminar el cultivo.',
                color: 'red',
            });
        }
    };



    return (

        <div
            className="responsive-flex-container"
        >
            <div
                className="responsive-div"

                style={{

                    padding: '10px',
                    backgroundColor: 'white',
                    marginTop: '10px',
                    border: '1px solid #dbdbdb',
                    display: 'inline-block', // Ajusta el ancho al contenido
                }}

            >
                <Group justify="space-between" mb={10}>
                    <Text size="lg" weight={700}>Cultivos:</Text>

                    {puedeRegistrarCultivo && (
                        <Button color='#00497A' onClick={() => { setModalOpen(true); setEditMode(false); setCultivo({ id_cultivo: '', numero_cultivo: '', antibiograma_cultivo: '', id_resultado_cultivo: '', id_tipocultivo: '' }); }}>
                            + Nuevo Cultivo
                        </Button>
                    )}
                </Group>

                <Modal
                    opened={modalOpen}
                    size={'60%'}
                    onClose={() => { setModalOpen(false); setEditMode(false); }}
                    title={editMode ? "Editar Cultivo" : "Agregar Nuevo Cultivo"}
                >
                    <form onSubmit={handleSubmit}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px'
                                }}
                            >
                                <TextInput
                                    name="fecha_toma_cultivo"
                                    type='date'
                                    label="Fecha toma muestra"
                                    //value={cultivo.fecha_toma_cultivo}
                                    value={cultivo.fecha_toma_cultivo || ''} // Si es null muestra cadena vacia
                                    onChange={handleChange}
                                    required
                                    style={{ flexGrow: 1 }}

                                />
                                <NativeSelect
                                    label="Tipo de Cultivo"
                                    name="id_tipocultivo"
                                    value={cultivo.id_tipocultivo}
                                    onChange={(event) => setCultivo(prev => ({ ...prev, id_tipocultivo: event.currentTarget.value }))}
                                    data={[
                                        { value: '', label: 'Seleccione un tipo de cultivo' },
                                        ...tiposCultivos.map(tipo => ({
                                            value: tipo.id_tipocultivo.toString(),
                                            label: tipo.nombre_tipocultivo
                                        }))
                                    ]}
                                    required
                                    style={{ flexGrow: 1 }}

                                />
                                <TextInput
                                    name="numero_cultivo"
                                    label="Número de Cultivo"
                                    value={cultivo.numero_cultivo}
                                    onChange={handleChange}
                                    required
                                    style={{ flexGrow: 1 }}

                                />
                            </div>

                            <NativeSelect
                                label="Resultado de Cultivo"
                                name="id_resultado_cultivo"
                                value={cultivo.id_resultado_cultivo}
                                onChange={(event) => setCultivo(prev => ({ ...prev, id_resultado_cultivo: event.currentTarget.value }))}
                                data={[
                                    { value: '', label: 'Seleccione resultado' },
                                    ...resultadosCultivos.map(resultado => ({
                                        value: resultado.id_resultado_cultivo.toString(),
                                        label: resultado.nombre_resultado_cultivo
                                    }))
                                ]}
                                required
                                style={{ flexGrow: 1 }}

                            />


                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '10px',
                                    width: '100%'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'block',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: '10px',
                                        //width: '50%', // Añadir esta línea para que el div ocupe el 100% del ancho
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            gap: '10px',
                                        }}
                                    >
                                        <Select
                                            label="Antibiótico"
                                            data={antibioticos}
                                            value={antibioticoActual}
                                            onChange={(value) => setAntibioticoActual(value)}
                                            searchable
                                            clearable
                                            width="200px"
                                        />
                                        <Select
                                            label="Sensibilidad"
                                            data={sensibilidades}
                                            value={antibiogramaActual}
                                            onChange={(value) => setAntibiogramaActual(value)}
                                            clearable
                                            width="200px"
                                        />
                                    </div>

                                    <div

                                    >


                                        <Button
                                            onClick={handleAddAntibiotico}
                                            fullWidth
                                            rightSection={icon}
                                            style={{ width: '100%', marginTop: '10px' }}
                                        >
                                            Agregar Antibiótico
                                        </Button>
                                    </div>
                                </div>

                                <div
                                    style={{

                                        width: '100%', // Añadir esta línea para que el div ocupe el 100% del ancho
                                    }}
                                >
                                    <Textarea
                                        name="antibiograma_cultivo"
                                        label="Antibiograma del Cultivo"
                                        value={cultivo.antibiograma_cultivo}
                                        onChange={handleChange}
                                        minRows={7}
                                        autosize
                                        width='200px'
                                    />

                                </div>
                            </div>


                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px'
                                }}
                            >
                                <Button type="submit">{editMode ? "Actualizar Cultivo" : "Agregar Cultivo"}</Button>

                            </div>
                        </div>
                    </form>
                </Modal>

                <div
                    style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: 'white',
                        marginTop: '10px',
                        //border: '1px solid #dbdbdb',
                    }}
                >
                    {cultivos.length > 0 && (
                        <div className="responsive-cards-container">
                            {cultivos.map((cultivoItem) => (
                                <Card
                                    key={cultivoItem.id_cultivo}
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    withBorder
                                    style={{ marginBottom: '10px', borderColor: '#d1d1d1', maxWidth: '100%', backgroundColor: '#f9f9f9' }}
                                >
                                    <Group position="apart" style={{ marginBottom: 10 }}>
                                        <Text size='xs'>ID Cultivo: {cultivoItem.id_cultivo}</Text>
                                        <Text size="sm">F. toma: {moment(cultivoItem.fecha_toma_cultivo).format('DD/MM/YYYY')}</Text>
                                        <Text size="xs">
                                            <strong>TIPO CULTIVO:</strong> {tiposCultivos.find(tipo => tipo.id_tipocultivo === cultivoItem.id_tipocultivo)?.nombre_tipocultivo || 'N/A'}
                                        </Text>
                                        <Text size="xs">
                                            <strong># CULTIVO:</strong> {cultivoItem.numero_cultivo}
                                        </Text>
                                    </Group>

                                    <Divider my="md" />
                                    <Text size="sm">
                                        <strong>RESULTADO:</strong> {resultadosCultivos.find(resultado => resultado.id_resultado_cultivo === cultivoItem.id_resultado_cultivo)?.nombre_resultado_cultivo || 'N/A'}
                                    </Text>
                                    <Divider my="md" />

                                    <Text>
                                        <strong>Antibiograma:</strong>
                                    </Text>
                                    <Text size='sm' component="pre" style={{ whiteSpace: 'pre-wrap', fontSize: '15px' }}>
                                        {renderAntibiograma(cultivoItem.antibiograma_cultivo)}
                                    </Text>

                                    <Group justify="center" spacing={5} mt="md">
                                        {puedeModificarCultivo && (

                                            <i
                                                className="ri-pencil-line"
                                                onClick={() => handleEdit(cultivoItem)}
                                            ></i>
                                        )}
                                        {puedeEliminarCultivo && (

                                            <i
                                                className="ri-delete-bin-line"
                                                onClick={() => {
                                                    if (window.confirm('¿Estás seguro de eliminar este Cultivo?')) {
                                                        handleDelete(cultivoItem.id_cultivo);
                                                    }
                                                }}
                                            ></i>
                                        )}
                                    </Group>
                                </Card>
                            ))}
                        </div>

                    )}
                </div>

            </div>
            <div
                className="responsive-div2"
                style={{

                    display: 'flex',
                    flexDirection: 'column',      // Organiza los elementos en una columna
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <div
                    className="responsive-div2"

                    style={{
                        padding: '10px',
                        backgroundColor: 'white',
                        marginTop: '10px',
                        border: '1px solid #dbdbdb',
                        display: 'inline-block', // Ajusta el ancho al contenido
                    }}

                >
                    <Group justify="space-between" mb={10}>
                        <Text size="lg" weight={700}>Tratamientos:</Text>
                        <Group>
                            <CsvDownloader
                                filename="tratamientos"
                                extension=".csv"
                                separator=","
                                wrapColumnChar='"'
                                columns={csvHeadersTratamientos}
                                datas={csvDataTratamiento}
                                text="Descargar Tratamientos"
                            >
                                <Button leftSection={<IconDownload size={14} />} variant="outline" color="#00497A">
                                    Exportar
                                </Button>
                            </CsvDownloader>
                            {puedeRegistrarTratamiento && (
                                <Button color="#00497A" onClick={() => { setModalTratamientoOpen(true); setEditModeTratamiento(false); setTratamiento({ id_tratamiento: '', nombre_tratamiento: '', fecha_inicio_tratamiento: '', fecha_fin_tratamiento: null }); }}>
                                    + Nuevo Tratamiento
                                </Button>
                            )}
                        </Group>

                    </Group>

                    <Modal
                        opened={modalTratamientoOpen}
                        onClose={() => { setModalTratamientoOpen(false); setEditModeTratamiento(false); }}
                        title={editModeTratamiento ? "Editar Tratamiento" : "Agregar Nuevo Tratamiento"}
                    >
                        <form onSubmit={handleSubmitTratamiento}>
                            <Stack>
                                <Textarea
                                    label="Nombre del Tratamiento"
                                    value={tratamiento.nombre_tratamiento}
                                    onChange={(event) => handleInputChange('nombre_tratamiento', event.currentTarget.value)}
                                    required
                                    styles={{
                                        input: {
                                            backgroundColor: '#FCF3C2' // Color amarillo
                                        }
                                    }}
                                    autosize
                                    minRows={4}
                                />

                                <TextInput
                                    label="Fecha de Inicio del Tratamiento"
                                    required
                                    type="date"
                                    value={tratamiento.fecha_inicio_tratamiento}
                                    onChange={(event) => handleInputChange('fecha_inicio_tratamiento', event.currentTarget.value)}
                                />
                                <TextInput
                                    label="Fecha de Fin del Tratamiento"
                                    type="date"
                                    //value={tratamiento.fecha_fin_tratamiento}
                                    value={tratamiento.fecha_fin_tratamiento || ''} // Si es null, muestra cadena vacía
                                    onChange={(event) => handleInputChange('fecha_fin_tratamiento', event.currentTarget.value)}
                                />
                                <Button type="submit">{editModeTratamiento ? "Actualizar Tratamiento" : "Agregar Tratamiento"}</Button>
                            </Stack>
                        </form>
                    </Modal>
                    <div
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: 'white',
                            marginTop: '10px',
                            //border: '1px solid #dbdbdb',
                        }}
                    >
                        <div className="responsive-cards-container">
                            {tratamientos.map((tratamientoItem) => (
                                <Card
                                    key={tratamientoItem.id_tratamiento}
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    withBorder
                                    style={{
                                        marginBottom: '10px', borderColor: '#d1d1d1', maxWidth: '100%', backgroundColor: 'white' // Color de fondo
                                    }}
                                >

                                    <Group position="apart" style={{ marginBottom: 5 }}>

                                        <Badge color='#00497A' variant="outline">
                                            <Text size="xs" weight={500}>ID tratamiento: {tratamientoItem.id_tratamiento}</Text>
                                        </Badge>
                                        <Badge variant="gradient"
                                            gradient={{ from: '#002F4F', to: '#00497A', deg: 90 }}>
                                            <Text size="sm">{moment(tratamientoItem.fecha_inicio_tratamiento).format('DD/MM/YYYY')} -
                                                {tratamientoItem.fecha_fin_tratamiento ? moment(tratamientoItem.fecha_fin_tratamiento).format('DD/MM/YYYY') : 'En proceso'}
                                            </Text>
                                        </Badge>

                                    </Group>
                                    <Group mb={'5px'}>
                                        <Badge variant="outline" color='#00497A'
                                            style={{ marginTop: '10px' }}>
                                            Creado por: {tratamientoItem.usuario_creador_tratamiento} {/* Mostrar quién creó la nota */}
                                        </Badge>
                                    </Group>
                                    <Text size="sm" className="card-content" style={{ whiteSpace: 'pre-line' }}>
                                        {tratamientoItem.nombre_tratamiento}
                                    </Text>

                                    <Group justify="center" spacing="sm" style={{ marginTop: 10 }}>
                                        {puedeModificarTratamiento && (
                                            <i
                                                key={`edit-${tratamientoItem.id_tratamiento}`}
                                                className="ri-pencil-line"
                                                onClick={() => { setTratamiento(tratamientoItem); setEditModeTratamiento(true); setModalTratamientoOpen(true); }}
                                            ></i>
                                        )}
                                        {puedeEliminarTratamiento && (
                                            <i
                                                key={`delete-${tratamientoItem.id_tratamiento}`}
                                                className="ri-delete-bin-line"
                                                onClick={() => {
                                                    if (window.confirm('¿Estás seguro de eliminar este Tratamiento?')) {
                                                        handleDeleteTratamiento(tratamientoItem.id_tratamiento);
                                                    }
                                                }}
                                            ></i>
                                        )}


                                    </Group>
                                </Card>
                            ))}
                        </div>

                    </div>

                </div>

                <div
                    className="responsive-div2"

                    style={{
                        padding: '10px',
                        backgroundColor: 'white',
                        marginTop: '10px',
                        border: '1px solid #dbdbdb',
                        display: 'inline-block', // Ajusta el ancho al contenido
                    }}

                >
                    <Group justify="space-between" mb={10}>
                        <Text size="lg" weight={700}>Notas Médicas:</Text>
                        <Group>
                            <CsvDownloader
                                filename="notas"
                                extension=".csv"
                                separator=","
                                wrapColumnChar='"'
                                columns={csvHeaders}
                                datas={csvData}
                                text="Descargar Notas"
                            >
                                <Button leftSection={<IconDownload size={14} />} variant="outline" color="#00497A">
                                    Exportar
                                </Button>
                            </CsvDownloader>
                            {puedeRegistrarNota && (

                                <Button
                                    color='#00497A'
                                    onClick={() => {
                                        setModalNotaOpen(true);
                                        setEditModeNota(false);
                                        setNota({ id_nota: '', nombre_nota: '', fecha_nota: '', id_cambio: '', id_manejo: '', id_evolucion: '' });
                                        setSelectedManejo("");
                                        setSelectedEvolucion("");
                                        setSelectedCambio("");
                                    }}>
                                    + Nueva Nota Médica
                                </Button>
                            )}
                        </Group>

                    </Group>

                    <Modal
                        opened={modalNotaOpen}
                        onClose={() => { setModalNotaOpen(false); setEditModeNota(false); }}
                        title={editModeNota ? "Editar Nota" : "Agregar Nueva Nota"}
                    >
                        <form onSubmit={handleSubmitNota}>
                            <Stack>
                                
                                <div
                                    style={{
                                        display: 'flex',
                                        gap:'10px',

                                    }}
                                >
                                    <TextInput
                                        label="Fecha Nota"
                                        className='caja-150'
                                        required
                                        type="date"
                                        value={nota.fecha_nota}
                                        onChange={(event) => handleInputChangeNota('fecha_nota', event.currentTarget.value)}
                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                    />
                                    <NativeSelect
                                        name="id_manejo"
                                        label="Manejo..."
                                        className='caja-150'
                                        value={selectedManejo}
                                        onChange={handleManejoChange}
                                        placeholder=""
                                        required
                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                    >
                                        <option value="">Seleccione manejo</option>
                                        {manejos.map((u) => (
                                            <option key={u.id_manejo} value={String(u.id_manejo)}>
                                                {u.nombre_manejo}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap:'10px',

                                    }}
                                >
                                    <NativeSelect
                                        name="id_evolucion"
                                        label="Evolución"
                                        className='caja-150'
                                        value={selectedEvolucion}
                                        onChange={handleEvolucionChange}
                                        placeholder=""
                                        required
                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                    >
                                        <option value="">Seleccione evolución</option>
                                        {evoluciones.map((u) => (
                                            <option key={u.id_evolucion} value={String(u.id_evolucion)}>
                                                {u.nombre_evolucion}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                    <NativeSelect
                                        name="id_cambio"
                                        label="¿Cambio de catéter?"
                                        className='caja-150'
                                        value={selectedCambio}
                                        onChange={handleCambioNotasChange}
                                        placeholder=""
                                        required
                                        style={{ flexGrow: 1 }} // Permite que ocupe todo el ancho disponible

                                    >
                                        <option value="">¿Cambio de catéter?</option>
                                        {cambios.map((u) => (
                                            <option key={u.id_cambio} value={String(u.id_cambio)}>
                                                {u.nombre_cambio}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </div>
                                <Textarea
                                    label="Nombre Nota"
                                    value={nota.nombre_nota}
                                    onChange={(event) => handleInputChangeNota('nombre_nota', event.currentTarget.value)}
                                    required
                                    styles={{
                                        input: {
                                            backgroundColor: '#FCF3C2' // Color amarillo
                                        }
                                    }}
                                    autosize
                                    minRows={4}
                                />

                                <Button type="submit">{editModeNota ? "Actualizar Nota" : "Agregar Nota"}</Button>
                            </Stack>
                        </form>
                    </Modal>
                    <div
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: 'white',
                            marginTop: '10px',
                            //border: '1px solid #dbdbdb',
                        }}
                    >


                        <div className="responsive-cards-container">
                            {notas.map((notaItem) => {
                                //console.log('notaItem:', notaItem); // Verificar el contenido de cada nota
                                return (

                                    <Card
                                        key={notaItem.id_nota}
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        style={{
                                            marginBottom: '10px', borderColor: '#d1d1d1', maxWidth: '100%', backgroundColor: '#F5F3B8' // Color de fondo
                                        }}
                                    >

                                        <Group position="apart" style={{ marginBottom: 5 }}>

                                            <Badge color='#00497A' variant="outline">
                                                <Text size="xs" weight={500}>ID nota: {notaItem.id_nota}</Text>
                                            </Badge>
                                            <Badge variant="gradient"
                                                gradient={{ from: '#002F4F', to: '#00497A', deg: 90 }}>
                                                <Text size="sm">{moment(notaItem.fecha_nota).format('DD/MM/YYYY')}</Text>
                                            </Badge>

                                        </Group>
                                        <Group mb={'5px'}>
                                            <Badge variant="outline" color='#00497A'
                                                style={{ marginTop: '10px' }}>
                                                Creado por: {notaItem.usuario_creador} {/* Mostrar quién creó la nota */}
                                            </Badge>
                                            <Badge variant="outline" color='#00497A'
                                                style={{ marginTop: '10px' }}>
                                                Manejo: {notaItem.cat_manejos?.nombre_manejo || 'No especificado'}
                                            </Badge>
                                            <Badge variant="outline" color='#00497A'
                                                style={{ marginTop: '1px' }}>
                                                Evolución: {notaItem.cat_evoluciones?.nombre_evolucion || 'No especificado'}
                                            </Badge>
                                            <Badge variant="outline" color='#00497A'
                                                style={{ marginTop: '1px' }}>
                                                ¿Cambio de catéter?: {notaItem.cat_cambios?.nombre_cambio || 'No especificado'}
                                            </Badge>

                                        </Group>
                                        <Text size="sm" className="card-content" style={{ whiteSpace: 'pre-line' }}>
                                            {notaItem.nombre_nota}
                                        </Text>

                                        <Group justify="center" spacing="sm" style={{ marginTop: 10 }}>

                                            {puedeModificarNota && (
                                                <i
                                                    key={`edit-${notaItem.id_nota}`}
                                                    className="ri-pencil-line"
                                                    onClick={() => {
                                                        setNota(notaItem);
                                                        setEditModeNota(true);
                                                        setModalNotaOpen(true);
                                                    }}
                                                ></i>
                                            )}
                                            {puedeEliminarNota && (
                                                <i
                                                    key={`delete-${notaItem.id_tratamiento}`}
                                                    className="ri-delete-bin-line"
                                                    onClick={() => {
                                                        if (window.confirm('¿Estás seguro de eliminar esta Nota?')) {
                                                            handleDeleteNota(notaItem.id_nota);
                                                        }
                                                    }}
                                                ></i>
                                            )}


                                        </Group>
                                    </Card>
                                );
                            })}
                        </div>
                    </div>



                </div>
            </div>



        </div>


    );
}

export default CultivosForm;