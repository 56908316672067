import React, { useState } from 'react';
import { Card, Text, Button, Group, Pagination, Accordion, Badge, Divider, rem, List } from '@mantine/core';
import CsvDownloader from 'react-csv-downloader';
import { supabase } from "../supabaseClient";
import { showNotification } from "@mantine/notifications";
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import { useDispatch } from 'react-redux';
import { IconBuilding, IconCalendarDue, IconNurse, IconClock, IconArmchair, IconFilter, IconTag, IconFridge } from '@tabler/icons-react';
import moment from 'moment';



function CasosCard({ casos, setSelectedTransaction, setFormMode, setShowForm, getData, filtroCaso, user, permisos }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100;
    const dispatch = useDispatch();

    const casosFiltrados = filtroCaso
        ? casos.filter(caso => caso.numero_caso.toLowerCase().includes(filtroCaso.toLowerCase()))
        : casos;

    const paginatedTransactions = casosFiltrados.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const deleteTransaction = async (id) => {
        try {
            //console.log("Iniciando eliminación del caso con ID:", id);
            dispatch(ShowLoading());

            // Verifica si el caso tiene cultivos, tratamientos o notas relacionadas
            const { data: cultivos, error: errorCultivos } = await supabase
                .from('cultivos')
                .select('id_cultivo')
                .eq('id_caso', id);

            const { data: tratamientos, error: errorTratamientos } = await supabase
                .from('tratamientos')
                .select('id_tratamiento')
                .eq('id_caso', id);

            const { data: notas, error: errorNotas } = await supabase
                .from('notas')
                .select('id_nota')
                .eq('id_caso', id);

            // Verifica si hubo errores en las consultas
            if (errorCultivos || errorTratamientos || errorNotas) {
                throw new Error('Error al verificar dependencias');
            }

            //console.log("Cultivos:", cultivos, "Tratamientos:", tratamientos, "Notas:", notas);

            // Verifica las longitudes
            if (cultivos.length > 0 || tratamientos.length > 0 || notas.length > 0) {
                let mensaje = 'No se puede eliminar el Caso porque tiene asociado: ';
                if (cultivos.length > 0) mensaje += `\n- ${cultivos.length} cultivo(s)`;
                if (tratamientos.length > 0) mensaje += `\n- ${tratamientos.length} tratamiento(s)`;
                if (notas.length > 0) mensaje += `\n- ${notas.length} nota(s)`;

                showNotification({
                    title: 'Error',
                    message: mensaje,
                    color: 'red'
                });
                return; // Se detiene la ejecución si hay dependencias
            }

            // Proceder a eliminar el caso
            //console.log("Eliminando el caso con ID:", id);
            const { error } = await supabase
                .from('casos')
                .delete()
                .eq('id_caso', id);

            // Maneja el error de eliminación
            if (error) {
                if (error.code === '23503') { // Código de error de clave foránea
                    throw new Error('No se puede eliminar el caso porque aún tiene elementos asociados.');
                } else {
                    throw new Error('No se pudo borrar el Caso: ' + error.message);
                }
            }

            // Mensaje de éxito si la eliminación fue exitosa
            showNotification({
                title: 'Caso borrado',
                color: 'green'
            });

            getData(); // Refresca los datos
        } catch (error) {
            console.error('Error al eliminar el caso:', error.message);
            showNotification({
                title: 'Error',
                message: error.message || 'No se pudo borrar el Caso',
                color: 'red'
            });
        } finally {
            dispatch(HideLoading());
        }
    };


    const getCards = paginatedTransactions.map((caso) => (

        <Card
            key={caso.id_caso}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{ borderColor: '#d1d1d1', position: 'relative' }}
        >
            <div style={{ paddingBottom: '45px' }}> {/* Añade espacio en la parte inferior */}


                <Group justify='space-between' mb={'5px'}>
                    <Badge color='#00497A' variant="outline">
                        Caso # {caso.numero_caso}
                    </Badge>

                    {(() => {
                        switch (caso.cat_etapas_casos.nombre_etapa_caso) {
                            case 'EN PROCESO':
                                return <Badge
                                    variant="gradient"
                                    gradient={{ from: 'yellow', to: 'orange', deg: 90 }}                                >
                                    EN PROCESO
                                </Badge>;
                            case 'CONCLUÍDO':
                                return <Badge
                                    variant="gradient"
                                    gradient={{ from: 'teal', to: 'cyan', deg: 90 }}                            >
                                    CONCLUÍDO
                                </Badge>;
                            // Add more cases here for future statuses
                            // case 'Nuevo Status':
                            //     return <IconNuevo size={24} color="newColor" />;
                            default:
                                return <Badge> SIN DEFINIR </Badge>;
                        }

                    })()}
                    {caso.cat_conclusiones && caso.cat_conclusiones.nombre_conclusion && (
                        <Badge color='#00497A' variant="">
                            TIPO: {caso.cat_conclusiones.nombre_conclusion}
                        </Badge>
                    )}


                </Group>
                <Group align="flex-end" spacing="xs">

                    <Text size="md" weight={300}>
                        Pac. {caso.cat_pacientes.nombre_paciente}
                    </Text>
                </Group>

                <Group justify="flex-start" gap="xs" grow>
                    <Group>
                        <IconBuilding size={23} />
                        <Text size="sm" color="dimmed">
                            {caso.cat_unidades.nombre_unidad}
                        </Text>
                    </Group>
                    <Group>
                        <IconClock size={23} />
                        <Text size="sm" color="dimmed">
                            {caso.cat_turnos.nombre_turno}
                        </Text>
                    </Group>

                </Group>
                <Group justify="flex-start" gap="xs" grow>
                    <Group>
                        <IconArmchair size={23} />
                        <Text size="sm" color="dimmed">
                            {caso.cat_salas.nombre_sala}
                        </Text>
                    </Group>
                    <Group>
                        <IconFridge size={23} />
                        <Text size="sm" color="dimmed">
                            {caso.cat_maquinas.nombre_maquina}
                        </Text>
                    </Group>

                </Group>
                <Group align="flex-end" spacing="xs">
                    <IconCalendarDue size={23} />
                    <Text size="sm" color="dimmed">
                        Ini.: {caso.fecha_caso_inicio
                            ? moment(caso.fecha_caso_inicio).format('DD/MM/YYYY')
                            : '-'}
                    </Text>
                    <Text size="sm" color="dimmed">
                        Fin: {caso.fecha_caso_fin
                            ? moment(caso.fecha_caso_fin).format('DD/MM/YYYY')
                            : '-'}
                    </Text>

                </Group>
                <Group align="flex-end" spacing="xs">
                    <IconNurse size={23} />
                    <Text size="sm" color="dimmed">
                        {caso.cat_enfermeros.nombre_enfermero}
                    </Text>


                </Group>
                <Group align="flex-end" spacing="xs">
                    <Text size="xs" color="dimmed">
                        {caso.cat_tiposav.nombre_tipoav}
                    </Text>
                </Group>
                <Group justify="flex-end" spacing="xs" mt={'5px'} grow>
                    <Badge
                        leftSection={<IconFilter style={{ width: rem(12), height: rem(12) }} />}
                        color={caso.cat_usos.clasificacion_uso === 'REUSO' ? 'violet' : 'yellow'}
                        radius="xs"
                    >
                        {caso.cat_usos.clasificacion_uso}
                    </Badge>
                    <Badge
                        color='#00497A'
                        radius="xs"
                        variant='outline'
                    >
                        Uso: {caso.cat_usos.nombre_uso}
                    </Badge>
                    <Badge
                        leftSection={<IconTag style={{ width: rem(12), height: rem(12) }} />}
                        color='#00497A'
                        radius="xs"
                        variant='outline'
                    >
                        {caso.cat_procedencias.nombre_procedencia}
                    </Badge>
                </Group>
                <Divider my="md" />

                <Group style={{ width: '100%' }}>

                    <Accordion style={{ width: '100%' }} defaultValue="">
                        <Accordion.Item value="cultivos">
                            <Accordion.Control className="">
                                <Group justify="space-between" style={{ alignItems: 'flex-start' }}>
                                    <Text weight={500} size="sm">Cultivos:</Text>

                                    {/* Mostrar cuántos cultivos tiene el caso */}
                                    {caso.cultivos.length > 0 ? (
                                        <Badge color="blue" variant="light" mb={'-15px'}>
                                            {caso.cultivos.length} cultivo{caso.cultivos.length > 1 ? 's' : ''}
                                        </Badge>
                                    ) : (
                                        <Badge color="red" variant="light" mb={'-5px'}>
                                            Sin cultivos
                                        </Badge>
                                    )}
                                </Group>
                            </Accordion.Control>
                            <Accordion.Panel className="AccordionPanel">
                                {caso.cultivos.length > 0 && (
                                    <div style={{ marginTop: '1px' }}>
                                        <Text weight={500} size="sm" style={{ marginBottom: '10px' }}>Resultados de cultivos:</Text>

                                        {caso.cultivos.map((cultivo, index) => (
                                            <div
                                                key={cultivo.id_cultivo}
                                                style={{
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '8px',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' // Alternar colores
                                                }}
                                            >
                                                <Group spacing="xs">
                                                    <Text size="sm" weight={500}>ID:</Text>
                                                    <Text size="sm">{cultivo.id_cultivo}</Text>
                                                </Group>

                                                <Group spacing="xs" mt={5}>
                                                    <Text size="sm" weight={500}>Resultado:</Text>
                                                    <Text size="sm" color="blue">{cultivo.cat_resultados_cultivos.nombre_resultado_cultivo}</Text>
                                                </Group>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Accordion.Panel>

                        </Accordion.Item>
                    </Accordion>
                </Group>




                <Group style={{ width: '100%' }}>
                    <Accordion style={{ width: '100%' }} defaultValue="">
                        <Accordion.Item value="tratamientos">
                            <Accordion.Control className="">
                                <Group justify="space-between" style={{ alignItems: 'flex-start' }}>
                                    <Text weight={500} size="sm">Tratamientos:</Text>
                                    {/* Mostrar cuántos tratamientos tiene el caso */}
                                    {caso.tratamientos.length > 0 ? (
                                        <Badge size="sm" color="blue" variant="light" mb={'-15px'}>
                                            {caso.tratamientos.length} tratamiento{caso.tratamientos.length > 1 ? 's' : ''}
                                        </Badge>
                                    ) : (
                                        <Badge size="sm" color="red" variant="light" mb={'-5px'}>
                                            Sin tratamientos
                                        </Badge>
                                    )}
                                </Group>

                            </Accordion.Control>
                            <Accordion.Panel className="AccordionPanel">
                                {caso.tratamientos.length > 0 && (
                                    <div style={{ marginTop: '1px' }}>
                                        <Text weight={500} size="sm">Tratamientos:</Text>
                                        {caso.tratamientos.map((tratamiento, index) => (
                                            <div key={tratamiento.id_tratamiento} style={{ marginTop: '10px' }}>
                                                <Group>
                                                    <Text size="sm">ID: {tratamiento.id_tratamiento}</Text>
                                                    <Text size="sm">
                                                        inicio: {tratamiento.fecha_inicio_tratamiento
                                                            ? moment(tratamiento.fecha_inicio_tratamiento).format('DD/MM/YYYY')
                                                            : '-'}
                                                    </Text>
                                                    <Text size="sm">
                                                        fin: {tratamiento.fecha_fin_tratamiento
                                                            ? moment(tratamiento.fecha_fin_tratamiento).format('DD/MM/YYYY')
                                                            : 'En proceso'}
                                                    </Text>
                                                    <Text size="sm" color="blue">Tratamiento: {tratamiento.nombre_tratamiento}</Text>
                                                </Group>

                                                {/* Agregar Divider entre cada tratamiento excepto el último */}
                                                {index < caso.tratamientos.length - 1 && (
                                                    <Divider my="sm" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Accordion.Panel>

                        </Accordion.Item>
                    </Accordion>
                </Group>
                <Group>
                    <Accordion style={{ width: '100%' }} defaultValue="">
                        <Accordion.Item value="notas">
                            <Accordion.Control className="">
                                <Group justify="space-between" style={{ alignItems: 'flex-start' }}>

                                    <Text weight={500} size="sm">Notas:</Text>
                                    {/* Mostrar cuántos notas tiene el caso */}
                                    {caso.notas.length > 0 ? (
                                        <Badge color="blue" variant="light" mb={'-15px'}>
                                            {caso.notas.length} nota{caso.notas.length > 1 ? 's' : ''}
                                        </Badge>
                                    ) : (
                                        <Badge color="red" variant="light" mb={'-5px'}>
                                            Sin notas
                                        </Badge>
                                    )}
                                </Group>

                            </Accordion.Control>
                            <Accordion.Panel className="AccordionPanel">
                                {caso.notas.length > 0 && (
                                    <div style={{ marginTop: '1px' }}>
                                        <Text weight={500} size="sm">Notas Médicas:</Text>
                                        {caso.notas.map((nota, index) => (
                                            <div key={nota.id_nota} style={{ marginTop: '10px' }}>
                                                <Group justify='space-between'>
                                                    <Text size="sm">ID: {nota.id_nota}</Text>
                                                    <Text size="sm">Fecha: {moment(nota.fecha_nota).format('DD/MM/YYYY')} </Text>

                                                    <Text size="sm" color="blue">Nota: {nota.nombre_nota}</Text>
                                                </Group>
                                                <Group>
                                                    <List size="sm" withPadding>
                                                        <List.Item><strong>MANEJO:</strong> {nota.cat_manejos.nombre_manejo}</List.Item>

                                                        <List.Item>
                                                            <strong>EVOLUCIÓN:</strong>{' '}
                                                            {nota.cat_evoluciones.nombre_evolucion === "NO FAVORABLE" ? (
                                                                <Badge color="red">{nota.cat_evoluciones.nombre_evolucion}</Badge>
                                                            ) : (
                                                                <Badge color="green">{nota.cat_evoluciones.nombre_evolucion}</Badge>
                                                            )}
                                                        </List.Item>                                                    
                                                        <List.Item><strong>CATÉTER:</strong> {nota.cat_cambios.nombre_cambio}</List.Item>
                                                    </List>
                                                </Group>

                                                {/* Agregar Divider entre cada nota excepto la última */}
                                                {index < caso.notas.length - 1 && (
                                                    <Divider my="sm" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Accordion.Panel>

                        </Accordion.Item>
                    </Accordion>



                </Group>

            </div>
            <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '20px',
                borderTop: '1px solid #d1d1d1',
                backgroundColor: 'white', //color de la parte de iconos
                //padding: '10px 0',
                minHeight: '50px' // Asegura que haya suficiente espacio para los iconos
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRight: '1px solid #d1d1d1',

                }}>
                    {permisos &&
                        permisos.casos &&
                        permisos.casos.acciones &&
                        permisos.casos.acciones.modificar_caso && (<i
                            className='ri-pencil-line'

                            onClick={() => {
                                setSelectedTransaction(caso);
                                setFormMode("edit");
                                setShowForm(true);
                            }}
                        />
                        )}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedTransaction(caso);
                            setFormMode("edit");
                            setShowForm(true);
                        }}
                        style={{
                            marginLeft: '8px',
                            color: 'black',
                            fontFamily: 'Arimo',
                            textDecoration: 'none', // Opcional: para quitar el subrayado
                            cursor: 'pointer',
                            fontSize: '16px' // Ajusta el tamaño si es necesario
                        }}
                    >
                        Editar
                    </a>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {permisos &&
                        permisos.casos &&
                        permisos.casos.acciones &&
                        permisos.casos.acciones.eliminar_caso && (<i
                            className='ri-delete-bin-line'

                            onClick={() => {
                                if (window.confirm('¿Estás seguro de eliminar este Caso?')) {
                                    deleteTransaction(caso.id_caso);
                                }
                            }}
                        />
                        )}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            if (window.confirm('¿Estás seguro de eliminar este Caso?')) {
                                deleteTransaction(caso.id_caso);
                            }
                        }}
                        style={{
                            marginLeft: '8px',
                            color: 'black',
                            fontFamily: 'Arimo',
                            textDecoration: 'none', // Opcional: para quitar el subrayado
                            cursor: 'pointer',
                            fontSize: '16px' // Ajusta el tamaño si es necesario
                        }}
                    >
                        Eliminar
                    </a>
                </div>
            </div>
        </Card>

    ));

    const csvHeaders = [
        'ID',
        'Nombre caso',
        'Paciente',
        'Fecha de nacimiento',
        'Fecha inicio caso'
    ];

    const csvData = casos.map((caso) => [
        caso.id_caso,
        caso.numero_caso,
        caso.cat_pacientes.nombre_paciente,
        caso.cat_pacientes.fecha_nacimiento,
        caso.fecha_caso_inicio
    ]);

    return (
        <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '20px', marginBottom: '20px', padding: '20px' }}>
                {getCards}
            </div>
            <Group justify="flex-end" mb={10} mt={20}>
                <Pagination
                    total={Math.ceil(casos.length / itemsPerPage)}
                    siblings={1}
                    withEdges
                    value={currentPage}
                    onChange={(newPage) => setCurrentPage(newPage)}
                    color='#00497A'
                />
                <CsvDownloader
                    filename={'casos.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData}
                    columns={csvHeaders}
                >
                    <Button variant="light" color='#00497A'>Descargar Tabla</Button>
                </CsvDownloader>
            </Group>
        </div>
    );
}

export default CasosCard;