import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import Tabla1Filtro from '../components/Tabla1Filtro';
import { Loader, Text, Button, Group, Accordion, Card, Badge } from '@mantine/core';
import moment from "moment";
import { showNotification } from "@mantine/notifications";
import { IconSearch } from '@tabler/icons-react';
import Tabla5 from '../components/Tabla5';
import Tabla6 from '../components/Tabla6';
import Tabla7 from '../components/Tabla7';
import Tabla8 from '../components/Tabla8';

const CatalogoReporteTabla6 = () => {
    //const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        fechaInicial: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Primer día del mes actual,
        fechaFinal: new Date(),
        nombre_unidad: "",
        nombre_paciente: "",
        nombre_enfermero: "",
        nombre_etapa_caso: "",
        clasificacion_tipoav: "",
        clasificacion_resultado_cultivo: [],
        clasificacion_uso: "",
        nombre_maquina: "",
    });

    // Para Tabla 3
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    // Para Tabla 4
    const [data2, setData2] = useState([]);

    // Para Tabla 
    const [data3, setData3] = useState([]);

    // Función para obtener los datos de la vista "Tabla 6" desde Supabase
    const fetchCasos = async () => {
        setLoading(true);
        try {
            const { data: responseData, error } = await supabase
                .rpc('contar_cultivos_por_accesovascular', {
                    fecha_inicio: moment(filters.fechaInicial).format('YYYY-MM-DD'),
                    fecha_fin: moment(filters.fechaFinal).format('YYYY-MM-DD'),
                    nombre_enfermero_param: filters.nombre_enfermero || null,
                    nombre_unidad_param: filters.nombre_unidad || null,
                    nombre_paciente_param: filters.nombre_paciente || null,
                    nombre_etapa_caso_param: filters.nombre_etapa_caso || null,
                    clasificacion_tipoav_param: filters.clasificacion_tipoav || null,
                    // Si el arreglo está vacío, envía null para que no filtre por ese campo
                    clasificacion_resultado_cultivo_param: filters.clasificacion_resultado_cultivo.length > 0
                        ? filters.clasificacion_resultado_cultivo
                        : null,
                    clasificacion_uso_param: filters.clasificacion_uso || null,
                    nombre_maquina_param: filters.nombre_maquina || null,
                });

            if (error) {
                console.log(error);
                throw new Error('Error al obtener datos: ' + error.message);
            }

            console.log('Datos obtenidos:', responseData);
            setLoading(false);
            return responseData;

        } catch (error) {
            console.error(error.message);
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red",
            });
            return [];
        } finally {
            setLoading(false);
        }
    };

    // Función para obtener los datos de la vista "Tabla 7" desde Supabase
    const fetchCasos2 = async () => {
        setLoading(true);
        try {
            const { data: responseData2, error } = await supabase
                .rpc('contar_cultivos_por_accesovascular_por_clasificacion_con_result', {
                    fecha_inicio: moment(filters.fechaInicial).format('YYYY-MM-DD'),
                    fecha_fin: moment(filters.fechaFinal).format('YYYY-MM-DD'),
                    nombre_enfermero_param: filters.nombre_enfermero || null,
                    nombre_unidad_param: filters.nombre_unidad || null,
                    nombre_paciente_param: filters.nombre_paciente || null,
                    nombre_etapa_caso_param: filters.nombre_etapa_caso || null,
                    clasificacion_tipoav_param: filters.clasificacion_tipoav || null,
                    // Si el arreglo está vacío, envía null para que no filtre por ese campo
                    clasificacion_resultado_cultivo_param: filters.clasificacion_resultado_cultivo.length > 0
                        ? filters.clasificacion_resultado_cultivo
                        : null,
                    clasificacion_uso_param: filters.clasificacion_uso || null,
                    nombre_maquina_param: filters.nombre_maquina || null,
                });

            if (error) {
                console.log(error);
                throw new Error('Error al obtener datos2: ' + error.message);
            }

            console.log('Datos obtenidos2:', responseData2);
            return responseData2;

        } catch (error) {
            console.error(error.message);
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red",
            });
            return [];
        } finally {
            setLoading(false);
        }
    };

    // Función para obtener los datos de la vista "Tabla 8" desde Supabase
    const fetchCasos3 = async () => {
        setLoading(true);
        try {
            const { data: responseData3, error } = await supabase
                .rpc('contar_cultivos_por_av_por_clasificacion_con_resultado', {
                    fecha_inicio: moment(filters.fechaInicial).format('YYYY-MM-DD'),
                    fecha_fin: moment(filters.fechaFinal).format('YYYY-MM-DD'),
                    nombre_enfermero_param: filters.nombre_enfermero || null,
                    nombre_unidad_param: filters.nombre_unidad || null,
                    nombre_paciente_param: filters.nombre_paciente || null,
                    nombre_etapa_caso_param: filters.nombre_etapa_caso || null,
                    clasificacion_tipoav_param: filters.clasificacion_tipoav || null,
                    // Si el arreglo está vacío, envía null para que no filtre por ese campo
                    clasificacion_resultado_cultivo_param: filters.clasificacion_resultado_cultivo.length > 0
                        ? filters.clasificacion_resultado_cultivo
                        : null,
                    clasificacion_uso_param: filters.clasificacion_uso || null,
                    nombre_maquina_param: filters.nombre_maquina || null,
                });

            if (error) {
                console.log(error);
                throw new Error('Error al obtener datos2: ' + error.message);
            }

            console.log('Datos obtenidos3:', responseData3);
            return responseData3;

        } catch (error) {
            console.error(error.message);
            showNotification({
                title: "Error obteniendo información",
                message: error.message,
                color: "red",
            });
            return [];
        } finally {
            setLoading(false);
        }
    };

    const getData = async () => {
        try {
            //dispatch(ShowLoading());
            // Llamamos a fetchCasos y guardamos la respuesta
            const cargarInformacion = await fetchCasos();
            const cargarInformacion2 = await fetchCasos2();
            const cargarInformacion3 = await fetchCasos3();
            // Actualizamos el estado con los datos obtenidos
            setData(cargarInformacion || []); // Si no hay datos, usamos un array vacío
            setData2(cargarInformacion2 || []); // Si no hay datos, usamos un array vacío
            setData3(cargarInformacion3 || []); // Si no hay datos, usamos un array vacío


            setLoading(false); // Desactivamos la visualización de los datos
            //dispatch(HideLoading());
        }
        catch (error) {
            console.error(error);
            showNotification({
                title: "Error obteniendo información",
                color: "red"
            });
            //dispatch(HideLoading());
            setLoading(false);
        }
    };


    return (
        <div>
            <Accordion defaultValue="catalogo">
                <Accordion.Item value="catalogo">
                    <Accordion.Control className="AccordionControl"
                    >
                        Filtros de Búsqueda
                    </Accordion.Control>
                    <Accordion.Panel className="AccordionPanel" >
                        <Tabla1Filtro
                            filters={filters}
                            setFilters={setFilters}
                            onEnterPress={getData}
                        />
                        <Group justify='flex-start'>
                            <Button
                                variant="gradient"
                                gradient={{ from: 'blue', to: '#00497A', deg: 90 }}
                                leftSection={<IconSearch size={20} />}
                                onClick={getData}
                            >
                                Generar Tabla
                            </Button>
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
            <div
                style={{
                    padding: '10px',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    gap: '20px',
                    flexWrap: 'wrap',
                    width: '100%'
                }}
            >
                <Accordion
                    defaultValue="tabla"
                    style={{ flex: '2 1 40%', minWidth: '200px', maxWidth: '100%' }}                >
                    <Accordion.Item value="tabla">
                        <Accordion.Control className="AccordionControl">
                            Total Cultivos por Tipo de Acceso Vascular
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel">
                            <Card withBorder style={{ background: 'white', padding: '10px', width: '100%' }}>
                                <div style={{ overflowX: "auto", textAlign: "left" }}>
                                    {loading ? (
                                        <>
                                            <Loader size={70} type="dots" />
                                            <p style={{ marginTop: "5px", fontSize: "16px", color: "#555" }}>Obteniendo datos...</p>
                                        </>
                                    ) : (
                                        <Tabla6 data={data} filters={filters} />
                                    )}
                                </div>
                            </Card>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Accordion
                    defaultValue="tabla2"
                    style={{ flex: '1 1 55%', minWidth: '300px', maxWidth: '100%' }}
                >
                    <Accordion.Item value="tabla2">
                        <Accordion.Control className="AccordionControl">
                            Cultivos por Tipo Acceso Vascular y Tipo de Resultado
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel">
                            <Card withBorder style={{ background: 'white', padding: '10px' }}>
                                <div style={{ overflowX: "auto", textAlign: "left" }}>
                                    {loading ? (
                                        <>
                                            <Loader size={70} type="dots" />
                                            <p style={{ marginTop: "5px", fontSize: "16px", color: "#555" }}>Obteniendo datos...</p>
                                        </>
                                    ) : (
                                        <Tabla7 data={data2} filters={filters} />
                                    )}
                                </div>
                            </Card>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div
                style={{
                    padding: '10px',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    gap: '20px',
                    flexWrap: 'wrap',
                    width: '100%'
                }}
            >
                <Accordion
                    defaultValue="tabla3"
                    style={{ flex: '1 1 100%', minWidth: '300px', maxWidth: '100%' }}
                >
                    <Accordion.Item value="tabla3">
                        <Accordion.Control className="AccordionControl">
                            Cultivos con Resultado
                        </Accordion.Control>
                        <Accordion.Panel className="AccordionPanel">
                            <Card withBorder style={{ background: 'white', padding: '10px' }}>
                                <div style={{ overflowX: "auto", textAlign: "left" }}>
                                    {loading ? (
                                        <>
                                            <Loader size={70} type="dots" />
                                            <p style={{ marginTop: "5px", fontSize: "16px", color: "#555" }}>Obteniendo datos...</p>
                                        </>
                                    ) : (
                                        <Tabla8 data={data3} filters={filters} />
                                    )}
                                </div>
                            </Card>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div >
    );
};

export default CatalogoReporteTabla6;
