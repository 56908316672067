import React, { useState } from 'react';
import { Table, Modal, Button, Badge } from '@mantine/core';
import { supabase } from '../supabaseClient';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from "../redux/alertsSlice";
import CsvDownloader from 'react-csv-downloader';
import { IconDownload, IconCaretUpFilled, IconCaretDownFilled, IconEqual } from '@tabler/icons-react';


const Tabla1 = ({ data, data2, filters }) => {
    //const dispatch = useDispatch();

    const [modalOpened, setModalOpened] = useState(false);
    const [cultivosData, setCultivosData] = useState([]);
    const [selectedTurno, setSelectedTurno] = useState(null);
    const [selectedSala, setSelectedSala] = useState(null);



    // Función para obtener el total por columna
    const getTotalByColumn = (column) => {
        return data.reduce((acc, item) => acc + (item[column] || 0), 0);
    };

    // Función para convertir fecha a formato YYYY-MM-DD
    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0];  // Convierte la fecha y toma solo la parte de la fecha (sin la hora)
    };

    const handleFetchCultivos = async (nombreSala, nombreTurno) => {
        //dispatch(ShowLoading());
        setSelectedTurno(nombreTurno);
        setSelectedSala(nombreSala);
        setModalOpened(true);

        // Inicia la consulta
        let query = supabase
            .from('cultivos')
            .select(`
                id_cultivo,
                fecha_toma_cultivo,
                numero_cultivo,
                id_resultado_cultivo,
                cat_resultados_cultivos!inner(
                    id_resultado_cultivo,
                    nombre_resultado_cultivo,
                    clasificacion_resultado_cultivo
                ),
                id_tipocultivo,
                cat_tiposcultivos!inner (
                    id_tipocultivo,
                    nombre_tipocultivo
                ),
                casos!inner(
                    id_caso,
                    numero_caso,
                    id_turno,
                    cat_turnos!inner (
                        id_turno,
                        nombre_turno
                    ),
                    id_sala,
                    cat_salas!inner (
                        id_sala,
                        nombre_sala
                    ),
                    id_unidad,
                    cat_unidades!inner (
                        id_unidad,
                        nombre_unidad
                    ),
                    id_paciente,
                    cat_pacientes!inner (
                        id_paciente,
                        nombre_paciente
                    ),
                    id_enfermero,
                    cat_enfermeros!inner(
                        id_enfermero,
                        nombre_enfermero
                    ),
                    id_etapa_caso,
                    cat_etapas_casos!inner(
                        id_etapa_caso,
                        nombre_etapa_caso
                    ),
                    id_tipoav,
                    cat_tiposav!inner(
                        id_tipoav,
                        nombre_tipoav,
                        clasificacion_tipoav
                    ),
                    id_uso,
                    cat_usos!inner(
                        id_uso,
                        nombre_uso,
                        clasificacion_uso
                    ),
                    id_maquina,
                    cat_maquinas!inner(
                        id_maquina,
                        nombre_maquina
                        )
                )
            `)
            .eq('casos.cat_salas.nombre_sala', nombreSala)
            .eq('casos.cat_turnos.nombre_turno', nombreTurno);

        // filtro de nombre_unidad
        if (filters.nombre_unidad) {
            query = query.eq('casos.cat_unidades.nombre_unidad', filters.nombre_unidad);
        }
        // Si hay un filtro de nombre_paciente, agregar la condición
        if (filters.nombre_paciente) {
            query = query.eq('casos.cat_pacientes.nombre_paciente', filters.nombre_paciente);
        }

        // filtro de enfermero
        if (filters.nombre_enfermero) {
            query = query.eq('casos.cat_enfermeros.nombre_enfermero', filters.nombre_enfermero);
        }

        //filtro nombre_etapa_caso
        if (filters.nombre_etapa_caso) {
            query = query.eq('casos.cat_etapas_casos.nombre_etapa_caso', filters.nombre_etapa_caso);
        }

        //filtro nombre_tipoav
        if (filters.clasificacion_tipoav) {
            query = query.eq('casos.cat_tiposav.clasificacion_tipoav', filters.clasificacion_tipoav);
        }

        // Filtro por fechas
        if (filters.fechaInicial) {
            query = query.gte('fecha_toma_cultivo', formatDate(filters.fechaInicial));
        }
        if (filters.fechaFinal) {
            query = query.lte('fecha_toma_cultivo', formatDate(filters.fechaFinal));
        }

        // Filtro para clasificacion_resultado_cultivo
        if (filters.clasificacion_resultado_cultivo && filters.clasificacion_resultado_cultivo.length > 0) {
            query = query.in('cat_resultados_cultivos.clasificacion_resultado_cultivo', filters.clasificacion_resultado_cultivo);
        }

        // filtro clasificacion_uso
        if (filters.clasificacion_uso) {
            query = query.eq('casos.cat_usos.clasificacion_uso', filters.clasificacion_uso);
        }

        // filtro nombre_maquina
        if (filters.nombre_maquina) {
            query = query.eq('casos.cat_maquinas.nombre_maquina', filters.nombre_maquina);
        }

        const { data: cultivos, error } = await query;  // Ejecuta la consulta

        if (error) {
            console.error('Error al obtener cultivos:', error);
            //dispatch(HideLoading());
            return;
        }

        console.log('Cultivos obtenidos:', cultivos);
        setCultivosData(cultivos);
        //dispatch(HideLoading());
    };


    const closeModal = () => {
        setModalOpened(false);
        setCultivosData([]);
        setSelectedTurno(null);
        setSelectedSala(null);
    };

    if (!data || data.length === 0) {
        return <div>
            <Badge
                color='grey'
            >
                Sin información
            </Badge>
        </div>;
    }

    const csvHeaders = [
        'TURNO',
        'SALA 1',
        'SALA 2',
        'SALA 3',
        'SALA 4',
        'N/A'
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData = data.map((data) => [
        sanitizeCSV(data.nombre_turno),
        data.sala1_count,
        data.sala2_count,
        data.sala3_count,
        data.sala4_count,
        data.na_count,
    ]);

    // para descargar CSV del Modal
    const csvHeaders2 = [
        'TURNO',
        'SALA',
        'UNIDAD',
        'ENFERMERO',
        'PACIENTE',
        'AV',
        'NO. CASO',
        'ID CULTIVO',
        'TIPO',
        'NO. CULTIVO',
        'RESULTADO',
        'CLASIFICACION',
    ];

    // Función para eliminar comas en los textos
    const sanitizeCSV2 = (text) => {
        if (typeof text === 'string') {
            return text.replace(/,/g, ''); // Elimina todas las comas
        }
        return text; // Si no es string, retorna el valor original
    };

    const csvData2 = cultivosData.map((cultivo) => [
        sanitizeCSV2(cultivo.casos.cat_turnos.nombre_turno),    // Turno
        sanitizeCSV2(cultivo.casos.cat_salas.nombre_sala),      // Sala
        sanitizeCSV2(cultivo.casos.cat_unidades.nombre_unidad), // Unidad
        sanitizeCSV2(cultivo.casos.cat_enfermeros.nombre_enfermero), // Enfermero
        sanitizeCSV2(cultivo.casos.cat_pacientes.nombre_paciente),   // Paciente
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),   // AV (Tipo de AV)
        sanitizeCSV2(cultivo.casos.numero_caso),
        sanitizeCSV2(cultivo.id_cultivo),
        sanitizeCSV2(cultivo.casos.cat_tiposav.nombre_tipoav),
        sanitizeCSV2(cultivo.numero_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.nombre_resultado_cultivo),
        sanitizeCSV2(cultivo.cat_resultados_cultivos.clasificacion_resultado_cultivo),
    ]);

    const calcularTasaVariacion = (countActual, countAnterior) => {
        if (countAnterior === 0) return 'N/A'; // Evitar división entre cero
        const variacion = ((countActual / countAnterior) - 1) * 100;
        return variacion.toFixed(2); // Retornar la tasa en porcentaje
    };

    return (
        <>
            <CsvDownloader
                filename={'tabla1.csv'}
                separator=","
                wrapColumnChar=""
                datas={csvData}
                columns={csvHeaders}
            >
                <Button
                    variant="outline"
                    color='green'
                    mb={'10px'}
                    rightSection={<IconDownload size={20} />}
                >
                    Descargar Tabla
                </Button>
            </CsvDownloader>
            <Table
                style={{ textAlign: 'center', borderCollapse: 'collapse' }}
                verticalSpacing="xl"
                fontSize="sm"
                mb={10}
                striped
                withRowBorders={true}
                className="my-custom-table"
            >
                <thead>
                    <tr>
                        <th>Nombre Turno</th>
                        <th>SALA 1</th>
                        <th>SALA 2</th>
                        <th>SALA 3</th>
                        <th>SALA 4</th>
                        <th>N/A</th>
                        <th>TOTAL POR TURNO</th> {/* Columna para el total por fila */}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        // Calcular el total por fila
                        const totalPorFila = item.sala1_count + item.sala2_count + item.sala3_count + item.sala4_count + item.na_count;
                        const itemAnterior = data2[index]; // Suponiendo que data2 tiene la misma estructura que data

                        return (
                            <tr key={index}>
                                <td>{item.nombre_turno}</td>
                                {/* SALA 1 */}
                                <td style={{ textAlign: 'center' }}>
                                    {item.sala1_count > 0 ? (
                                        <>
                                            <Button
                                                size="compact-sm"
                                                fullWidth
                                                variant="default"
                                                onClick={() => handleFetchCultivos('SALA 1', item.nombre_turno)}
                                            >
                                                {item.sala1_count}
                                            </Button>

                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                {/* Mostrar valor anterior*/}
                                                <span
                                                    style={{ marginBottom: '-20px' }}
                                                >
                                                    En Periodo Anterior: {itemAnterior ? itemAnterior.sala1_count : "N/A"}
                                                </span>
                                                <br />

                                                {/* Cálculo de la diferencia */}
                                                {itemAnterior ? (
                                                    (() => {
                                                        const diferencia = item.sala1_count - itemAnterior.sala1_count;
                                                        return (
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-20px' }}>
                                                                {diferencia > 0 ? (
                                                                    <>
                                                                        <IconCaretUpFilled style={{ color: 'red' }} /> {diferencia}
                                                                    </>
                                                                ) : diferencia < 0 ? (
                                                                    <>
                                                                        <IconCaretDownFilled style={{ color: 'green' }} /> {Math.abs(diferencia)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconEqual style={{ color: 'black' }} />
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>N/A</span>
                                                )}

                                                <br />
                                                {/* Mostrar tasa de variación */}
                                                <span>
                                                    {itemAnterior ? (
                                                        (() => {
                                                            const tasa = calcularTasaVariacion(item.sala1_count, itemAnterior.sala1_count);
                                                            if (tasa < 0) {
                                                                return <span style={{ color: 'green' }}>Disminuyó {Math.abs(tasa)} % </span>;
                                                            } else if (tasa > 0) {
                                                                return <span style={{ color: 'red' }}>Incrementó {tasa} % </span>;
                                                            } else if (tasa === 0) {
                                                                return <span> Sin variación </span>;
                                                            } else {
                                                                return <span>N/A</span>;
                                                            }
                                                        })()
                                                    ) : "N/A"}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <span>{item.sala1_count}</span>
                                    )}
                                </td>

                                {/* SALA 2 */}
                                <td style={{ textAlign: 'center' }}>
                                    {item.sala2_count > 0 ? (
                                        <>
                                            <Button
                                                size="compact-sm"
                                                fullWidth
                                                variant="default"
                                                onClick={() => handleFetchCultivos('SALA 2', item.nombre_turno)}
                                            >
                                                {item.sala2_count}
                                            </Button>

                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                {/* Mostrar valor anterior*/}
                                                <span
                                                    style={{ marginBottom: '-20px' }}
                                                >
                                                    En Periodo Anterior: {itemAnterior ? itemAnterior.sala2_count : "N/A"}
                                                </span>
                                                <br />

                                                {/* Cálculo de la diferencia */}
                                                {itemAnterior ? (
                                                    (() => {
                                                        const diferencia = item.sala2_count - itemAnterior.sala2_count;
                                                        return (
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-20px' }}>
                                                                {diferencia > 0 ? (
                                                                    <>
                                                                        <IconCaretUpFilled style={{ color: 'red' }} /> {diferencia}
                                                                    </>
                                                                ) : diferencia < 0 ? (
                                                                    <>
                                                                        <IconCaretDownFilled style={{ color: 'green' }} /> {Math.abs(diferencia)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconEqual style={{ color: 'black' }} />
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>N/A</span>
                                                )}

                                                <br />
                                                {/* Mostrar tasa de variación */}
                                                <span>
                                                    {itemAnterior ? (
                                                        (() => {
                                                            const tasa = calcularTasaVariacion(item.sala2_count, itemAnterior.sala2_count);
                                                            if (tasa < 0) {
                                                                return <span style={{ color: 'green' }}>Disminuyó {Math.abs(tasa)} % </span>;
                                                            } else if (tasa > 0) {
                                                                return <span style={{ color: 'red' }}>Incrementó {tasa} % </span>;
                                                            } else if (tasa === 0) {
                                                                return <span> Sin variación </span>;
                                                            } else {
                                                                return <span>N/A</span>;
                                                            }
                                                        })()
                                                    ) : "N/A"}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <span>{item.sala2_count}</span>
                                    )}
                                </td>
                                {/* SALA 3 */}
                                <td style={{ textAlign: 'center' }}>
                                    {item.sala3_count > 0 ? (
                                        <>
                                            <Button
                                                size="compact-sm"
                                                fullWidth
                                                variant="default"
                                                onClick={() => handleFetchCultivos('SALA 3', item.nombre_turno)}
                                            >
                                                {item.sala3_count}
                                            </Button>

                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                {/* Mostrar valor anterior*/}
                                                <span
                                                    style={{ marginBottom: '-20px' }}
                                                >
                                                    En Periodo Anterior: {itemAnterior ? itemAnterior.sala3_count : "N/A"}
                                                </span>
                                                <br />

                                                {/* Cálculo de la diferencia */}
                                                {itemAnterior ? (
                                                    (() => {
                                                        const diferencia = item.sala3_count - itemAnterior.sala3_count;
                                                        return (
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-20px' }}>
                                                                {diferencia > 0 ? (
                                                                    <>
                                                                        <IconCaretUpFilled style={{ color: 'red' }} /> {diferencia}
                                                                    </>
                                                                ) : diferencia < 0 ? (
                                                                    <>
                                                                        <IconCaretDownFilled style={{ color: 'green' }} /> {Math.abs(diferencia)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconEqual style={{ color: 'black' }} />
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>N/A</span>
                                                )}

                                                <br />
                                                {/* Mostrar tasa de variación */}
                                                <span>
                                                    {itemAnterior ? (
                                                        (() => {
                                                            const tasa = calcularTasaVariacion(item.sala3_count, itemAnterior.sala3_count);
                                                            if (tasa < 0) {
                                                                return <span style={{ color: 'green' }}>Disminuyó {Math.abs(tasa)} % </span>;
                                                            } else if (tasa > 0) {
                                                                return <span style={{ color: 'red' }}>Incrementó {tasa} % </span>;
                                                            } else if (tasa === 0) {
                                                                return <span> Sin variación </span>;
                                                            } else {
                                                                return <span>N/A</span>;
                                                            }
                                                        })()
                                                    ) : "N/A"}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <span>{item.sala3_count}</span>
                                    )}
                                </td>
                                {/* SALA 4 */}
                                <td style={{ textAlign: 'center' }}>
                                    {item.sala4_count > 0 ? (
                                        <>
                                            <Button
                                                size="compact-sm"
                                                fullWidth
                                                variant="default"
                                                onClick={() => handleFetchCultivos('SALA 4', item.nombre_turno)}
                                            >
                                                {item.sala4_count}
                                            </Button>

                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                {/* Mostrar valor anterior*/}
                                                <span
                                                    style={{ marginBottom: '-20px' }}
                                                >
                                                    En Periodo Anterior: {itemAnterior ? itemAnterior.sala4_count : "N/A"}
                                                </span>
                                                <br />

                                                {/* Cálculo de la diferencia */}
                                                {itemAnterior ? (
                                                    (() => {
                                                        const diferencia = item.sala4_count - itemAnterior.sala4_count;
                                                        return (
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-20px' }}>
                                                                {diferencia > 0 ? (
                                                                    <>
                                                                        <IconCaretUpFilled style={{ color: 'red' }} /> {diferencia}
                                                                    </>
                                                                ) : diferencia < 0 ? (
                                                                    <>
                                                                        <IconCaretDownFilled style={{ color: 'green' }} /> {Math.abs(diferencia)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconEqual style={{ color: 'black' }} />
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>N/A</span>
                                                )}

                                                <br />
                                                {/* Mostrar tasa de variación */}
                                                <span>
                                                    {itemAnterior ? (
                                                        (() => {
                                                            const tasa = calcularTasaVariacion(item.sala4_count, itemAnterior.sala4_count);
                                                            if (tasa < 0) {
                                                                return <span style={{ color: 'green' }}>Disminuyó {Math.abs(tasa)} % </span>;
                                                            } else if (tasa > 0) {
                                                                return <span style={{ color: 'red' }}>Incrementó {tasa} % </span>;
                                                            } else if (tasa === 0) {
                                                                return <span> Sin variación </span>;
                                                            } else {
                                                                return <span>N/A</span>;
                                                            }
                                                        })()
                                                    ) : "N/A"}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <span>{item.sala4_count}</span>
                                    )}
                                </td>

                                {/* N/A */}
                                <td style={{ textAlign: 'center' }}>
                                    {item.na_count > 0 ? (
                                        <>
                                            <Button
                                                size="compact-sm"
                                                fullWidth
                                                variant="default"
                                                onClick={() => handleFetchCultivos('N/A', item.nombre_turno)}
                                            >
                                                {item.na_count}
                                            </Button>

                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                {/* Mostrar valor anterior*/}
                                                <span
                                                    style={{ marginBottom: '-20px' }}
                                                >
                                                    En Periodo Anterior: {itemAnterior ? itemAnterior.na_count : "N/A"}
                                                </span>
                                                <br />

                                                {/* Cálculo de la diferencia */}
                                                {itemAnterior ? (
                                                    (() => {
                                                        const diferencia = item.na_count - itemAnterior.na_count;
                                                        return (
                                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '-20px' }}>
                                                                {diferencia > 0 ? (
                                                                    <>
                                                                        <IconCaretUpFilled style={{ color: 'red' }} /> {diferencia}
                                                                    </>
                                                                ) : diferencia < 0 ? (
                                                                    <>
                                                                        <IconCaretDownFilled style={{ color: 'green' }} /> {Math.abs(diferencia)}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconEqual style={{ color: 'black' }} />
                                                                    </>
                                                                )}
                                                            </span>
                                                        );
                                                    })()
                                                ) : (
                                                    <span>N/A</span>
                                                )}

                                                <br />
                                                {/* Mostrar tasa de variación */}
                                                <span>
                                                    {itemAnterior ? (
                                                        (() => {
                                                            const tasa = calcularTasaVariacion(item.na_count, itemAnterior.na_count);
                                                            if (tasa < 0) {
                                                                return <span style={{ color: 'green' }}>Disminuyó {Math.abs(tasa)} % </span>;
                                                            } else if (tasa > 0) {
                                                                return <span style={{ color: 'red' }}>Incrementó {tasa} % </span>;
                                                            } else if (tasa === 0) {
                                                                return <span> Sin variación </span>;
                                                            } else {
                                                                return <span>N/A</span>;
                                                            }
                                                        })()
                                                    ) : "N/A"}
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <span>{item.na_count}</span>
                                    )}
                                </td>

                                {/* Mostrar el total por fila */}
                                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{totalPorFila}</td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            TOTAL POR SALA
                        </td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{getTotalByColumn('sala1_count')}</td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{getTotalByColumn('sala2_count')}</td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{getTotalByColumn('sala3_count')}</td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{getTotalByColumn('sala4_count')}</td>
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{getTotalByColumn('na_count')}</td>
                        {/* Total general (sumatoria de los totales por columna) */}
                        <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {getTotalByColumn('sala1_count') +
                                getTotalByColumn('sala2_count') +
                                getTotalByColumn('sala3_count') +
                                getTotalByColumn('sala4_count') +
                                getTotalByColumn('na_count')}
                        </td>
                    </tr>
                </tfoot>
            </Table>

            <Modal
                opened={modalOpened}
                onClose={closeModal}
                title={`Cultivos para ${selectedTurno} en ${selectedSala}`}
                //size="95%"
                fullScreen
            //transitionProps={{ transition: 'fade', duration: 600}}

            >
                <CsvDownloader
                    filename={'tabla11.csv'}
                    separator=","
                    wrapColumnChar=""
                    datas={csvData2}
                    columns={csvHeaders2}
                >
                    <Button
                        variant="outline"
                        color='green'
                        mb={'10px'}
                        rightSection={<IconDownload size={20} />}
                    >
                        Descargar Tabla
                    </Button>
                </CsvDownloader>
                <Table
                    style={{ textAlign: 'center', borderCollapse: 'collapse' }}
                    verticalSpacing="xl"
                    fontSize="sm"
                    mb={10}
                    striped
                    withRowBorders={true}
                    className="my-custom-table"
                >
                    <thead>
                        <tr>
                            <th>TURNO</th>
                            <th>SALA</th>
                            <th>UNIDAD</th>
                            <th>ENFERMERO</th>
                            <th>PACIENTE</th>
                            <th>AV</th>
                            <th># CASO</th>
                            <th>ID CUL.</th>
                            <th>TIPO</th>
                            <th># CULTIVO</th>
                            <th>RESULTADO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cultivosData.map((cultivo, index) => (
                            <tr key={index}>
                                <td>{cultivo.casos.cat_turnos.nombre_turno}</td>
                                <td>{cultivo.casos.cat_salas.nombre_sala}</td>
                                <td>{cultivo.casos.cat_unidades.nombre_unidad}</td>
                                <td>{cultivo.casos.cat_enfermeros.nombre_enfermero}</td>

                                <td>{cultivo.casos.cat_pacientes.nombre_paciente}</td>
                                <td>{cultivo.casos.cat_tiposav.clasificacion_tipoav}</td>

                                <td>{cultivo.casos.numero_caso}</td>
                                <td>{cultivo.id_cultivo}</td>
                                <td>{cultivo.cat_tiposcultivos.nombre_tipocultivo}</td>
                                <td>{cultivo.numero_cultivo}</td>
                                <td>{cultivo.cat_resultados_cultivos.nombre_resultado_cultivo}</td>
                                <td>
                                    <Badge
                                        color={
                                            cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'NEGATIVO'
                                                ? 'green'
                                                : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'POSITIVO'
                                                    ? 'red'
                                                    : cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo === 'EN PROCESO'
                                                        ? 'orange'
                                                        : 'gray' // Si no coincide con ninguna clasificación
                                        }
                                    >
                                        {cultivo.cat_resultados_cultivos?.clasificacion_resultado_cultivo || 'Sin clasificación'}
                                    </Badge>                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal>
        </>
    );
};

export default Tabla1;
